import React from 'react';

const LockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.647 4.3235V3.853C7.647 2.555 6.5915 1.5 5.294 1.5C3.9965 1.5 2.941 2.5555 2.941 3.853V4.3235C2.421 4.3235 2 4.745 2 5.2645V8.559C2 9.079 2.421 9.5 2.941 9.5H7.647C8.167 9.5 8.588 9.0785 8.588 8.559V5.2645C8.588 4.7445 8.1665 4.3235 7.647 4.3235ZM3.882 3.853C3.882 3.0745 4.5155 2.441 5.294 2.441C6.0725 2.441 6.706 3.0745 6.706 3.853V4.3235H3.8815L3.882 3.853ZM5.7645 7.147C5.7645 7.27178 5.71493 7.39146 5.62669 7.47969C5.53846 7.56793 5.41879 7.6175 5.294 7.6175C5.16922 7.6175 5.04954 7.56793 4.96131 7.47969C4.87307 7.39146 4.8235 7.27178 4.8235 7.147V6.206C4.8235 6.08122 4.87307 5.96154 4.96131 5.87331C5.04954 5.78507 5.16922 5.7355 5.294 5.7355C5.41879 5.7355 5.53846 5.78507 5.62669 5.87331C5.71493 5.96154 5.7645 6.08122 5.7645 6.206V7.147Z"
        fill="#454F5A"
      />
    </svg>
  );
};

export default LockIcon;
