import React from 'react';

const IdVerifyIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <g clipPath="url(#clip0_29340_21075)">
        <path
          d="M15.8372 15.1195L22 13.75L28.1627 15.1195C28.3293 15.1565 28.4782 15.2492 28.585 15.3823C28.6918 15.5154 28.75 15.6809 28.75 15.8515V23.3417C28.7499 24.0826 28.567 24.8119 28.2174 25.465C27.8678 26.1181 27.3624 26.6749 26.746 27.0858L22 30.25L17.254 27.0858C16.6377 26.6749 16.1323 26.1183 15.7828 25.4653C15.4332 24.8124 15.2502 24.0832 15.25 23.3425V15.8515C15.25 15.6809 15.3082 15.5154 15.415 15.3823C15.5218 15.2492 15.6707 15.1565 15.8372 15.1195V15.1195ZM22 21.25C22.4973 21.25 22.9742 21.0525 23.3258 20.7008C23.6775 20.3492 23.875 19.8723 23.875 19.375C23.875 18.8777 23.6775 18.4008 23.3258 18.0492C22.9742 17.6975 22.4973 17.5 22 17.5C21.5027 17.5 21.0258 17.6975 20.6742 18.0492C20.3225 18.4008 20.125 18.8777 20.125 19.375C20.125 19.8723 20.3225 20.3492 20.6742 20.7008C21.0258 21.0525 21.5027 21.25 22 21.25ZM18.6452 25H25.3547C25.2638 24.1738 24.8712 23.4102 24.2522 22.8555C23.6331 22.3009 22.8312 21.9942 22 21.9942C21.1688 21.9942 20.3669 22.3009 19.7478 22.8555C19.1288 23.4102 18.7362 24.1738 18.6452 25V25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21075">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IdVerifyIcon;
