import React from 'react';

const ProfileSettingIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <g clipPath="url(#clip0_29340_21073)">
        <path
          d="M28 29.5H16V28C16 27.0054 16.3951 26.0516 17.0983 25.3483C17.8016 24.6451 18.7554 24.25 19.75 24.25H24.25C25.2446 24.25 26.1984 24.6451 26.9017 25.3483C27.6049 26.0516 28 27.0054 28 28V29.5ZM22 22.75C21.4091 22.75 20.8239 22.6336 20.2779 22.4075C19.732 22.1813 19.2359 21.8498 18.818 21.432C18.4002 21.0141 18.0687 20.518 17.8425 19.9721C17.6164 19.4261 17.5 18.8409 17.5 18.25C17.5 17.6591 17.6164 17.0739 17.8425 16.5279C18.0687 15.982 18.4002 15.4859 18.818 15.068C19.2359 14.6502 19.732 14.3187 20.2779 14.0925C20.8239 13.8664 21.4091 13.75 22 13.75C23.1935 13.75 24.3381 14.2241 25.182 15.068C26.0259 15.9119 26.5 17.0565 26.5 18.25C26.5 19.4435 26.0259 20.5881 25.182 21.432C24.3381 22.2759 23.1935 22.75 22 22.75V22.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21073">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProfileSettingIcon;
