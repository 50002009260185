const { createTheme } = require('@mui/material');

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xts: 450,
      xsm: 475,
      sm: 600,
      smd: 767,
      md: 900,
      slg: 992,
      mlg: 1024,
      lg: 1200,
      xlg: 1366,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["'Google Sans', Roboto, Arial, sans-serif"].join(','),
  },
});
