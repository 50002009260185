import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from 'src/svgComponents/SearchIcon';
import RefReqRow from './RefReqRow';
import { withTranslation } from 'react-i18next';

const RefRequestsPopup = (props) => {
  const { onClose, open, friendRefRequestData } = props;
  const [searchData, setSerchData] = useState('');
  const handleClose = () => {
    onClose();
  };
  const handleSearch = (e) => {
    setSerchData(e.target.value.trim());
  };
  return (
    <Dialog onClose={handleClose} open={open} className="request_popup_wrapper">
      <DialogTitle className="px-0 pt-0">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.references_request')}
        </span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {friendRefRequestData && friendRefRequestData.length > 0 ? (
        <>
          <Paper className="linking_popup_searchbox">
            <IconButton style={{ marginTop: '-2px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={props.t('comman_label.search')}
              onChange={handleSearch}
            />
          </Paper>
          <List className="linkList mt-3" sx={{ pt: 0 }}>
            {friendRefRequestData
              .filter((user) =>
                user.name.toLowerCase().includes(searchData.toLowerCase())
              )
              .map((user, index) => (
                <RefReqRow
                  key={index}
                  requestId={user.requestId}
                  userId={user.userId}
                  name={user.name}
                  businessName={user.businessName}
                  userName={user.userName}
                  userPic={user.image}
                  mobile={user.mobile}
                  endorsedType={user.endorsedType}
                />
              ))}
          </List>
        </>
      ) : (
        <span className="app_text_20_semibold app_text_transform">
          {props.t('popup_message.no_referance_requests')}
        </span>
      )}
    </Dialog>
  );
};

RefRequestsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(RefRequestsPopup);
