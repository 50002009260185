import { Box, Button, ListItemIcon, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import files from 'src/helpers/helpers';
import EarthIcon from 'src/svgComponents/EarthIcon';
import LockIcon from 'src/svgComponents/LockIcon';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AppTooltip from '../common/AppTooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { withTranslation } from 'react-i18next';
import Rsvp from 'src/svgComponents/Rsvp';
import Intersted from 'src/svgComponents/Intersted';
import { useState } from 'react';
import gelleryPlacholde from '../../assets/images/gallery_placeholder.svg';
import { authStore } from 'src/contexts/AuthProvider';
import { UserRole } from 'src/utils/enum';

const EventFeedItem = ({ onClickFunc, eventData, t }) => {
  const role = authStore((state) => state.role);
  const eventStartDate = new Date(eventData?.startDateTime);
  const startDay = moment(eventStartDate).format('ddd');
  const startDate = moment(eventStartDate).format('DD MMM YYYY');
  const eventTime = moment(eventStartDate, 'hh:mm A').format('hh:mm A');
  const [imageError, setImageError] = useState(false);
  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  return (
    // <ListItemButton onClick={() => onClickFunc(eventData?._id)} className="map_event_feed_listItem">
    <div className="map_event_feed_listItem mb-3">
      <Box
        className="event_feed_card"
        style={{ background: hex2rgba(eventData?.colorCode, 0.4) }}
      >
        <Box className="event_img_container">
          {!imageError && !_.isEmpty(eventData?.image?.[0]) ? (
            <img
              src={files(eventData?.image?.[0], 'attachments')}
              alt="event"
              onError={() => setImageError(true)}
            />
          ) : !imageError && !_.isEmpty(eventData?.video?.[0]) ? (
            <video autoPlay loop muted onError={() => setImageError(true)}>
              <source
                src={files(eventData?.video[0]?.video, 'attachments')}
              ></source>
            </video>
          ) : (
            <img src={gelleryPlacholde} alt="event" />
          )}
        </Box>
        <Box className="">
          <span className="app_text_primary app_text_14_500 text-wrap d-block mb-1">
            {t('create_event_module.event_card_time', {
              startDay: startDay,
              startDate: startDate,
              time: eventTime,
            })}
          </span>
          <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
              {eventData?.visibility === 0 ? (
                <EarthIcon sx={{ height: '20px', width: '20px' }} />
              ) : (
                <LockIcon sx={{ height: '20px', width: '20px' }} />
              )}
            </ListItemIcon>
            <span className="app_text_14_semibold text_limit_200 d-block">
              {eventData?.title ?? ''}
            </span>
          </span>
          {eventData?.physicalAddress && (
            <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
              <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                <FmdGoodIcon sx={{ height: '18px', width: '18px' }} />
              </ListItemIcon>
              <span className="app_text_14_500 text_limit_200">
                {eventData?.physicalAddress}
              </span>
            </span>
          )}
          <Box
            className="mt-1"
            sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          >
            <Box
              className=""
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <AppTooltip title={t('tooltip_text.rsvp')} placement={'bottom'}>
                <Button
                  variant="contained"
                  className={`map_sidebar_card_btn_sm`}
                  style={{ background: eventData?.colorCode }}
                >
                  <Rsvp />
                </Button>
              </AppTooltip>
              <Typography
                component={'span'}
                className="app_text_14_500 "
                sx={{ lineHeight: '1' }}
              >
                {eventData?.rvspUserCount}
              </Typography>
            </Box>
            <Box
              className=""
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <AppTooltip
                title={t('tooltip_text.interested')}
                placement={'bottom'}
              >
                <Button
                  variant="contained"
                  className={`map_sidebar_card_btn_sm`}
                  style={{ background: eventData?.colorCode }}
                >
                  <Intersted />
                </Button>
              </AppTooltip>
              <Typography
                component={'span'}
                className="app_text_14_500 "
                sx={{ lineHeight: '1' }}
              >
                {eventData?.interstedUserCount}
              </Typography>
            </Box>
            {role !== UserRole.GUEST && (
              <AppTooltip
                title={t('tooltip_text.view_more')}
                placement={'bottom'}
              >
                <Button
                  variant="contained"
                  className={`map_sidebar_card_btn_sm`}
                  style={{ background: eventData?.colorCode }}
                  onClick={(e) => onClickFunc(e, eventData?._id)}
                >
                  <VisibilityIcon style={{ color: '#fff' }} />
                </Button>
              </AppTooltip>
            )}
          </Box>
        </Box>
      </Box>
    </div>
    // </ListItemButton>
  );
};

export default withTranslation()(EventFeedItem);
