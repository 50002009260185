import React from 'react';

const EmployeesIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#FF70AD" />
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M28.8992 24.4V24.1C28.8992 23.7818 28.7728 23.4765 28.5477 23.2515C28.3227 23.0265 28.0175 22.9 27.6992 22.9H25.8992C25.581 22.9 25.2757 23.0265 25.0507 23.2515C24.8256 23.4765 24.6992 23.7818 24.6992 24.1V24.4H25.2992V24.1C25.2992 23.9409 25.3624 23.7883 25.475 23.6758C25.5875 23.5632 25.7401 23.5 25.8992 23.5H27.6992C27.8583 23.5 28.011 23.5632 28.1235 23.6758C28.236 23.7883 28.2992 23.9409 28.2992 24.1V24.4H28.8992Z"
          fill="white"
        />
        <path
          d="M22.5148 22.9981C22.7337 22.8494 22.9201 22.6578 23.0628 22.435C23.2055 22.2122 23.3014 21.9628 23.3449 21.7018L23.5042 20.7505C23.5159 20.6805 23.5521 20.6169 23.6062 20.571C23.6604 20.5252 23.729 20.5 23.8 20.5C23.9591 20.5 24.1117 20.4367 24.2243 20.3242C24.3368 20.2117 24.4 20.0591 24.4 19.9C24.4 19.7408 24.3368 19.5882 24.2243 19.4757C24.1117 19.3632 23.9591 19.3 23.8 19.3C23.7204 19.3 23.6441 19.2684 23.5879 19.2121C23.5316 19.1558 23.5 19.0795 23.5 19C23.5175 18.8087 23.4685 18.6173 23.3614 18.458C23.2542 18.2986 23.0954 18.1811 22.9117 18.1252C21.9891 18.0077 21.1001 17.703 20.2993 17.23C20.27 17.2103 20.2355 17.1998 20.2002 17.1998C20.1648 17.1998 20.1303 17.2103 20.101 17.23C19.3 17.7032 18.4109 18.0078 17.488 18.1252C17.3043 18.1811 17.1456 18.2987 17.0385 18.458C16.9314 18.6174 16.8825 18.8088 16.9 19C16.9 19.0795 16.8684 19.1558 16.8121 19.2121C16.7559 19.2684 16.6796 19.3 16.6 19.3C16.4409 19.3 16.2883 19.3632 16.1757 19.4757C16.0632 19.5882 16 19.7408 16 19.9C16 20.0591 16.0632 20.2117 16.1757 20.3242C16.2883 20.4367 16.4409 20.5 16.6 20.5C16.671 20.5 16.7396 20.5252 16.7938 20.571C16.8479 20.6169 16.8841 20.6805 16.8958 20.7505L17.0551 21.7015C17.0985 21.9625 17.1945 22.212 17.3372 22.4349C17.4799 22.6578 17.6663 22.8494 17.8852 22.9981C17.9725 23.0581 18.0652 23.1229 18.1645 23.1925C18.7381 23.5966 19.4518 24.1 20.2 24.1C20.9482 24.1 21.6619 23.5966 22.2355 23.1925C22.3339 23.1229 22.4275 23.0572 22.5148 22.9981Z"
          fill="white"
        />
        <path
          d="M19 24.4039C19 24.7222 19.1264 25.0274 19.3515 25.2525C19.5765 25.4775 19.8817 25.6039 20.2 25.6039C20.5183 25.6039 20.8235 25.4775 21.0485 25.2525C21.2736 25.0274 21.4 24.7222 21.4 24.4039C21.0264 24.589 20.6168 24.69 20.2 24.7C19.7832 24.69 19.3736 24.589 19 24.4039Z"
          fill="white"
        />
        <path
          d="M30.0992 25H23.4992C23.3335 25 23.1992 25.1343 23.1992 25.3V26.8C23.1992 26.9657 23.3335 27.1 23.4992 27.1H30.0992C30.2649 27.1 30.3992 26.9657 30.3992 26.8V25.3C30.3992 25.1343 30.2649 25 30.0992 25Z"
          fill="white"
        />
        <path
          d="M27.0992 28.3C27.1788 28.3 27.2551 28.2683 27.3114 28.2121C27.3676 28.1558 27.3992 28.0795 27.3992 28V27.7H26.1992V28C26.1992 28.0795 26.2308 28.1558 26.2871 28.2121C26.3433 28.2683 26.4197 28.3 26.4992 28.3H27.0992Z"
          fill="white"
        />
        <path
          d="M13.8996 30.4H22.6548C22.6193 30.3039 22.6006 30.2024 22.5996 30.0999V25.2999C22.6009 25.2069 22.6168 25.1147 22.6467 25.0266L21.9534 24.799C21.8633 25.1968 21.6407 25.5522 21.322 25.8068C21.0033 26.0613 20.6075 26.2 20.1996 26.2C19.7917 26.2 19.3959 26.0613 19.0772 25.8068C18.7586 25.5522 18.5359 25.1968 18.4458 24.799L15.8319 25.6569C14.3925 26.1303 13.5996 27.6015 13.5996 29.7999V30.0999C13.5996 30.1795 13.6312 30.2558 13.6875 30.3121C13.7437 30.3683 13.82 30.4 13.8996 30.4Z"
          fill="white"
        />
        <path
          d="M16 17.8V18.8647C16.0998 18.8053 16.2077 18.7608 16.3204 18.7327C16.3498 18.4433 16.4706 18.1708 16.6653 17.9548C16.8601 17.7387 17.1186 17.5903 17.4034 17.5312C18.2428 17.4254 19.0518 17.1498 19.7812 16.7212C19.9064 16.6415 20.0516 16.5992 20.2 16.5992C20.3484 16.5992 20.4936 16.6415 20.6188 16.7212C21.3481 17.1497 22.1571 17.4253 22.9963 17.5312C23.2811 17.5903 23.5397 17.7386 23.7345 17.9547C23.9293 18.1707 24.0502 18.4433 24.0796 18.7327C24.1923 18.7608 24.3002 18.8053 24.4 18.8647V17.8C24.4 16.6861 23.9575 15.6178 23.1698 14.8301C22.3822 14.0425 21.3139 13.6 20.2 13.6C19.0861 13.6 18.0178 14.0425 17.2302 14.8301C16.4425 15.6178 16 16.6861 16 17.8Z"
          fill="white"
        />
        <path
          d="M30.3992 27.6448C30.3031 27.6803 30.2017 27.699 30.0992 27.7H27.9992V28C27.9992 28.2387 27.9044 28.4676 27.7356 28.6364C27.5668 28.8052 27.3379 28.9 27.0992 28.9H26.4992C26.2605 28.9 26.0316 28.8052 25.8628 28.6364C25.694 28.4676 25.5992 28.2387 25.5992 28V27.7H23.4992C23.3968 27.699 23.2953 27.6803 23.1992 27.6448V30.1C23.1992 30.1795 23.2308 30.2558 23.2871 30.3121C23.3433 30.3684 23.4197 30.4 23.4992 30.4H30.0992C30.1788 30.4 30.2551 30.3684 30.3114 30.3121C30.3676 30.2558 30.3992 30.1795 30.3992 30.1V27.6448Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmployeesIcon;
