import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  baseURL: BASE_URL,
});

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', env: 'test' },
  withCredentials: true,
});

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_PATH = '/user';
const BUSINESS_PATH = '/business';
const EMPLOYEE_PATH = '/employee';
const CHAT_PATH = '/chat';
const SOCIAL_MEDIA_PATH = '/socials';
const REPORT_PATH = '/report';
const AUTH_API_URL = {
  login: path(ROOTS_PATH, '/login/old'),
  loginNew: path(ROOTS_PATH, '/login/v2'),
  guestLogin: path(ROOTS_PATH, '/login/guest'),
  register: path(ROOTS_PATH, '/register'),
  signupVerifyOTP: path(ROOTS_PATH, '/otp/verify/signup'),
  resendOTP: path(ROOTS_PATH, '/otp/resend'),
  getOtp: path(ROOTS_PATH, '/otp/get'),
  verifyOtp: path(ROOTS_PATH, '/otp/verify'),
  verifyOtpFirebaseMobile: path(ROOTS_PATH, '/veriyFirebaseMobileOtp'),
  mobileExist: path(ROOTS_PATH, '/exist/mobile'),
  forgotPassword: path(ROOTS_PATH, '/password/forgot'),
  refreshToken: path(ROOTS_PATH, '/web/refreshToken'),
  logout: path(ROOTS_PATH, '/logout'),
};
const INDIVIDUAL_API_URL = {
  profile: path(ROOTS_PATH, '/profile'),
  Checkstatus: path(BUSINESS_PATH, '/check/status'),
  getNotification: path(BASE_URL, '/notification'),
  getNotificationCount: path(BASE_URL, '/notification/count'),
  readNotification: path(BASE_URL, '/notification/read/:id'),
  readAllNotification: path(BASE_URL, '/notification/read/all'),

  otherProfile: path(ROOTS_PATH, '/profile/:id'),
  otherProfileNotParam: path(ROOTS_PATH, '/profile/'),
  profileUpload: path(ROOTS_PATH, '/upload/image'),
  documentUpload: path(ROOTS_PATH, '/upload/file'),
  idVerify: path(ROOTS_PATH, '/profile/document'),
  childIdVerify: path(ROOTS_PATH, '/profile/document/minor'),
  childIdVerifyGetOtp: path(ROOTS_PATH, '/profile/document/otp'),
  childIdVerifyOtp: path(ROOTS_PATH, '/profile/document/verify'),
  perentAcceptRejectReq: path(ROOTS_PATH, '/profile/document/minor/verify/'),
  permission: path(ROOTS_PATH, '/profile/setting'),
  reference: path(ROOTS_PATH, '/profile/reference'),
  homeAddress: path(ROOTS_PATH, '/home/address'),
  selfiVerify: path(ROOTS_PATH, '/verify/selfie'),
  selfiVerifyNew: path(ROOTS_PATH, '/verification/selfie'),
  changeDetailsEMGetOtp: path(ROOTS_PATH, '/change'),
  changeDetailsNameOtp: path(ROOTS_PATH, '/change/name'),
  userEventAddUpdate: path(ROOTS_PATH, '/event'),
  optionalGetOtp: path(ROOTS_PATH, '/verify/optional'),
  optionalVerifyOtp: path(ROOTS_PATH, '/verify/optional/otp'),
  referenceDelete: path(ROOTS_PATH, '/reference/delete'),
  notificationDelete: path(ROOTS_PATH, '/clear/notification'),
  selfiVerifyNewWeb: path(ROOTS_PATH, '/verification/selfie/web'),
  setIntervalApi: path(ROOTS_PATH, '/location/interval'),
  configurableField: path(ROOTS_PATH, '/configurable/field'),
  temAddressNull: path(ROOTS_PATH, '/verificationAddressGet'),
  userUploadPhotos: path(ROOTS_PATH, '/upload/photos'),
  businessUploadPhotos: path(BUSINESS_PATH, '/upload/logo/photos '),
  updatePhotosPermission: path(ROOTS_PATH, '/photos/permission'),
  unlinkPhotos: path(ROOTS_PATH, '/photos'),
  updateBannerForImages: path(ROOTS_PATH, '/banner/profile'),
  userBuisnessDesignation: path(EMPLOYEE_PATH, '/businesses'),
};
const EVENT_API_URL = {
  multipleImageUpload: path(ROOTS_PATH, '/multiple/image/upload'),
  createEvent: path(ROOTS_PATH, '/event'),
  updateEvent: path(ROOTS_PATH, '/event/:eventId'),
  getAllEvent: path(ROOTS_PATH, '/allEvent'),
  getEventStatusFilter: path(ROOTS_PATH, '/event/status/list'),
  getSignleEventDetails: path(ROOTS_PATH, '/event/details/:eventId'),
  getEventIvitationDetails: path(ROOTS_PATH, '/event/invitation/details'),
  getUserEvent: path(ROOTS_PATH, '/event/:userId'),
  cancelEvent: path(ROOTS_PATH, '/event/cancel/'),
  eventInvitation: path(ROOTS_PATH, '/event/invitation'),
  eventInvitationList: path(ROOTS_PATH, '/event/invitation/list'),
  eventFollowerList: path(ROOTS_PATH, '/event/followers/list'),
  eventLinkList: path(ROOTS_PATH, '/event/link/list'),
  eventInviteReqList: path(ROOTS_PATH, '/event/invitation/request/list'),
  eventAccRej: path(ROOTS_PATH, '/event/accepted/:invitionId'),
  join: path(ROOTS_PATH, '/event/join'),
  memberList: path(ROOTS_PATH, '/event/member/list'),
  linkedUsers: path(ROOTS_PATH, '/links'),
  followUsers: path(SOCIAL_MEDIA_PATH, '/user/followers'),
  intrestedToAccept: path(ROOTS_PATH, '/event/interstedToRsvp'),
  randomEvents: path(ROOTS_PATH, '/event/random/list'),
};

const BUSINESS_API_URL = {
  root: BUSINESS_PATH,
  categoryList: path(BUSINESS_PATH, '/category'),
  subCategoryList: path(BUSINESS_PATH, '/category/sub'),
  uploadFile: path(BUSINESS_PATH, '/upload/image'),
  uploadVideo: path(BUSINESS_PATH, '/upload/video'),
  addBusiness: path(BUSINESS_PATH, '/'),
  businessIdVerify: path(BUSINESS_PATH, '/verify/document'),
  businessPermission: path(BUSINESS_PATH, '/setting'),
  businessReference: path(BUSINESS_PATH, '/references'),
  allBusiness: path(BUSINESS_PATH, ''),
  businessProfile: path(BUSINESS_PATH, '/profile'),
  otherProfileNotParam: path(BUSINESS_PATH, '/profile/'),
  businessRequestList: path(BUSINESS_PATH, '/friends/action'),
  businessAddress: path(BUSINESS_PATH, '/address'),
  addEditBusinessAddress: path(BUSINESS_PATH, '/address/v2'),
  businessBulkAddress: path(BUSINESS_PATH, '/mul/address'),
  dempFileXlsx: path(BUSINESS_PATH, '/demoFile'),
  friendRequestBusiness: path(BUSINESS_PATH, '/friends/'),
  businessUnlinkUser: path(BUSINESS_PATH, '/unlink'),

  uploadMulBusinessPhotos: path(BUSINESS_PATH, '/upload/logo/photos'),
  empAvailability: path(EMPLOYEE_PATH, '/unavailiable/request'),
  empLeaveRequestStatus: path(EMPLOYEE_PATH, '/request/approve/'),
  empUnavailability: path(EMPLOYEE_PATH, '/unavailable'),
  updateBannerForImages: path(BUSINESS_PATH, '/banner/profile'),
  unlinkPhotos: path(BUSINESS_PATH, '/photos'),
  changePrimaryAddress: path(BUSINESS_PATH, '/change/primary/address'),
  deleteEmpReq: path(EMPLOYEE_PATH, '/cancle/request/'),
};

const USER_API_URL = {
  linkedRequest: path(ROOTS_PATH, '/links/'),
  friendList: path(ROOTS_PATH, '/friends/action'),
  friendRequest: path(ROOTS_PATH, '/friends/'),
  unlinkUser: path(ROOTS_PATH, '/unlink'),
  referencesRequest: path(ROOTS_PATH, '/references'),
  referencesRequestAll: path(ROOTS_PATH, '/references/all'),
  getLocation: path(ROOTS_PATH, '/location/list'),
  userTracing: path(ROOTS_PATH, '/trace/'),
  traceList: path(ROOTS_PATH, '/trace/request'),
  traceHistoryList: path(ROOTS_PATH, '/trace/history'),
  userActivityList: path(ROOTS_PATH, '/activities/'),
  traceApprove: path(ROOTS_PATH, '/trace/approve/'),
  countryIds: path(ROOTS_PATH, '/profile/ids'),
  searchUserList: path(ROOTS_PATH, '/search'),
  getDirection: path(ROOTS_PATH, '/google/direction'),
  getDistance: path(ROOTS_PATH, '/google/distancematrix'),
  searchPlace: path(ROOTS_PATH, '/google/nearbysearch'),
  accountInactive: path(ROOTS_PATH, '/inactive'),
  businessVerifyGetOtp: path(ROOTS_PATH, '/verify/business/otp'),
  businessVerifyOtp: path(ROOTS_PATH, '/verify/business'),
  serchListOfUser: path(ROOTS_PATH, '/listOfUser'),
  traceDelete: path(ROOTS_PATH, '/trace/delete/'),
  multipalFileUpload: path(ROOTS_PATH, '/multiple/file/upload'),
  deleteAccountRequest: path(ROOTS_PATH, '/delete/request'),
  getReportType: path(REPORT_PATH, '/subject'),
  sendReport: REPORT_PATH,
  locationInterval: path(ROOTS_PATH, '/location/interval'),
  locationLog: path(ROOTS_PATH, '/activities'),
  contactUs: path(ROOTS_PATH, '/contactUs'),
  noAuthContcatUs: path(ROOTS_PATH, '/contactUs/new'),
  traceRequest: path(ROOTS_PATH, '/trace/request/'),
};

const EMPLOYEE_API_URL = {
  root: EMPLOYEE_PATH,
  employeeSuggesonList: path(EMPLOYEE_PATH, '/users'),
  employeeAuthrized: path(EMPLOYEE_PATH, '/'),
  employeePendingAllReq: path(EMPLOYEE_PATH, '/business'),
  employeeAcceptReq: path(EMPLOYEE_PATH, '/accept'),
  empUnavailableeReq: path(EMPLOYEE_PATH, '/available/'),
  empUnavailableReqApprove: path(EMPLOYEE_PATH, '/approve/'),
  empUnavailableeRequest: path(EMPLOYEE_PATH, '/unavailable/'),
  empLeaveRequest: path(EMPLOYEE_PATH, '/unavailable/list'),
  empLeaveDelete: path(EMPLOYEE_PATH, '/cancle/request/'),
  empRequest: path(EMPLOYEE_PATH, '/request/'),
};

const SOCIAL_MEDIA_URL = {
  root: SOCIAL_MEDIA_PATH,
  createPost: path(SOCIAL_MEDIA_PATH, '/post'),
  updatePost: path(SOCIAL_MEDIA_PATH, '/post/'),
  fetchPosts: path(SOCIAL_MEDIA_PATH, '/post'),
  fetchPost: path(SOCIAL_MEDIA_PATH, '/post/list'),
  likePost: path(SOCIAL_MEDIA_PATH, '/post/like'),
  commentPost: path(SOCIAL_MEDIA_PATH, '/post/comment'),
  fetchComments: path(SOCIAL_MEDIA_PATH, '/post/:id/comment'),
  reportPost: path(SOCIAL_MEDIA_PATH, '/post/report'),
  videoImageUpload: path(SOCIAL_MEDIA_PATH, '/multiple/video/upload'),
  deletePost: path(SOCIAL_MEDIA_PATH, '/post/delete/:postId'),
  postLikeDislike: path(SOCIAL_MEDIA_PATH, '/post/like'),
  postGetLikes: path(SOCIAL_MEDIA_PATH, '/post/like/:postId'),
  postComment: path(SOCIAL_MEDIA_PATH, '/post/comment'),
  deletePostComment: path(SOCIAL_MEDIA_PATH, '/post/comment/:cmtId'),
  deletePostRepComment: path(SOCIAL_MEDIA_PATH, '/post/comment/reply/:cmtId'),
  userFollow: path(SOCIAL_MEDIA_PATH, '/user/follow'),
  commentLike: path(SOCIAL_MEDIA_PATH, '/post/comment/like'),
  followersList: path(SOCIAL_MEDIA_PATH, '/user/followers/:userId'),
  businessFollowersList: path(SOCIAL_MEDIA_PATH, '/business/follower/:userId'),
  followingList: path(SOCIAL_MEDIA_PATH, '/user/following/:userId'),
  businessFollowingList: path(SOCIAL_MEDIA_PATH, '/business/following/:userId'),
  postViewCount: path(SOCIAL_MEDIA_PATH, '/view/post'),
  randomUsersFollow: path(ROOTS_PATH, '/random'),
  // randomPost: path(SOCIAL_MEDIA_PATH, '/post/list')
  randomPost: path(SOCIAL_MEDIA_PATH, '/post/random'),
};

const CHAT_API_URL = {
  root: CHAT_PATH,
  uploadImage: path(CHAT_PATH, '/upload/image'),
  uploadFile: path(CHAT_PATH, '/upload/file'),
  uploadVideo: path(CHAT_PATH, '/upload/video'),
  uploadAudio: path(CHAT_PATH, '/upload/audio'),
  linkedUser: path(ROOTS_PATH, '/links'),
  chatCategory: path(ROOTS_PATH, '/chat/category/list'),
  createGroup: path(CHAT_PATH, '/group'),
  updateGroup: path(CHAT_PATH, '/group/:groupId'),
  leaveGroup: path(CHAT_PATH, '/group/:groupId/members'),
  getGroupMembers: path(CHAT_PATH, '/group/:groupId/members'),
  addGroupMembers: path(CHAT_PATH, '/group/:groupId/members/:memberId'),
  deleteGroupMembers: path(CHAT_PATH, '/group/:groupId/members/:memberId'),
  setAdminInGroup: path(CHAT_PATH, '/group/:groupId/admin/:memberId'),
  deleteAdminInGroup: path(CHAT_PATH, '/group/:groupId/admin/:memberId'),
  getbusinessConversationData: path(
    CHAT_PATH,
    '/getBusinessConversation/:chatId'
  ),
};

export {
  axiosPrivate,
  AUTH_API_URL,
  INDIVIDUAL_API_URL,
  BUSINESS_API_URL,
  USER_API_URL,
  EMPLOYEE_API_URL,
  CHAT_API_URL,
  SOCIAL_MEDIA_URL,
  EVENT_API_URL,
};
