import React from 'react';

const LocationIconCommon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31704_26871)">
        <path
          d="M9.182 8.68196L6 11.864L2.818 8.68196C2.18867 8.05262 1.76009 7.2508 1.58646 6.37789C1.41284 5.50497 1.50195 4.60018 1.84255 3.77791C2.18315 2.95565 2.75992 2.25285 3.49994 1.75838C4.23996 1.26392 5.10999 1 6 1C6.89002 1 7.76004 1.26392 8.50006 1.75838C9.24008 2.25285 9.81686 2.95565 10.1575 3.77791C10.4981 4.60018 10.5872 5.50497 10.4135 6.37789C10.2399 7.2508 9.81133 8.05262 9.182 8.68196ZM6 6.49996C6.26522 6.49996 6.51957 6.3946 6.70711 6.20707C6.89464 6.01953 7 5.76518 7 5.49996C7 5.23474 6.89464 4.98039 6.70711 4.79285C6.51957 4.60532 6.26522 4.49996 6 4.49996C5.73479 4.49996 5.48043 4.60532 5.2929 4.79285C5.10536 4.98039 5 5.23474 5 5.49996C5 5.76518 5.10536 6.01953 5.2929 6.20707C5.48043 6.3946 5.73479 6.49996 6 6.49996Z"
          fill="#97A8BE"
        />
      </g>
      <defs>
        <clipPath id="clip0_31704_26871">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIconCommon;
