import React from 'react';

const CreateBusinessIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#FF5E4B" />
      <g clipPath="url(#clip0_29340_21074)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3235 16.7059C23.3235 18.7526 24.9827 20.4118 27.0294 20.4118C29.0761 20.4118 30.7352 18.7526 30.7352 16.7059C30.7352 14.6592 29.0761 13 27.0294 13C24.9827 13 23.3235 14.6592 23.3235 16.7059ZM29.6764 16.7059C29.6764 18.1678 28.4913 19.3529 27.0294 19.3529C25.5674 19.3529 24.3823 18.1678 24.3823 16.7059C24.3823 15.244 25.5674 14.0588 27.0294 14.0588C28.4913 14.0588 29.6764 15.244 29.6764 16.7059ZM17.4999 19.6176C17.3463 19.6176 17.2001 19.6844 17.0996 19.8007C14.5513 22.7459 13.2646 25.0436 13.2646 26.7647C13.2646 29.1038 15.1609 31 17.4999 31H20.6764C23.0155 31 24.9117 29.1038 24.9117 26.7647C24.9117 25.0114 23.6238 22.7115 21.0748 19.7984C20.9743 19.6835 20.8291 19.6176 20.6764 19.6176H17.4999ZM21.7352 13.2647H16.4411C16.0666 13.2647 15.8105 13.643 15.9496 13.9907L17.0084 16.6378C17.0888 16.8388 17.2835 16.9706 17.4999 16.9706H20.6764C20.8929 16.9706 21.0876 16.8388 21.168 16.6378L22.2268 13.9907C22.3659 13.643 22.1098 13.2647 21.7352 13.2647ZM20.6764 17.5H17.4999C17.0614 17.5 16.7058 17.8555 16.7058 18.2941C16.7058 18.7327 17.0614 19.0882 17.4999 19.0882H20.6764C21.115 19.0882 21.4705 18.7327 21.4705 18.2941C21.4705 17.8555 21.115 17.5 20.6764 17.5ZM26.4999 15.6471V16.1765H25.9705C25.6781 16.1765 25.4411 16.4135 25.4411 16.7059C25.4411 16.9983 25.6781 17.2353 25.9705 17.2353H26.4999V17.7647C26.4999 18.0571 26.737 18.2941 27.0294 18.2941C27.3217 18.2941 27.5588 18.0571 27.5588 17.7647V17.2353H28.0882C28.3806 17.2353 28.6176 16.9983 28.6176 16.7059C28.6176 16.4135 28.3806 16.1765 28.0882 16.1765H27.5588V15.6471C27.5588 15.3547 27.3217 15.1176 27.0294 15.1176C26.737 15.1176 26.4999 15.3547 26.4999 15.6471Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21074">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreateBusinessIcon;
