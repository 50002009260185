import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from 'src/svgComponents/SearchIcon';
import MapSearchUserCard from '../home/MapSearchUserCard';
import { USER_API_URL } from 'src/api/axios';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import useStore from 'src/contexts/AuthProvider';
import { withTranslation } from 'react-i18next';

const SerchUserHomePopup = (props) => {
  const { onClose, open, userProfileData } = props;
  const axiosPrivate = useAxiosPrivate();

  // eslint-disable-next-line no-unused-vars
  const [lattitudeData, setLattitudeData] = useState(-26.204444);
  // eslint-disable-next-line no-unused-vars
  const [longitudeData, setLongitudeData] = useState(28.045556);
  // const [lattitudeData, setLattitudeData] = useState(-30.5595);
  // const [longitudeData, setLongitudeData] = useState(22.9375);
  const userData = useStore((state) => state.user);

  const [searchData, setSerchData] = useState('');

  // const geoLocation = () => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //       setLattitudeData(position.coords.latitude);
  //       setLongitudeData(position.coords.longitude);
  //   });
  // };
  // useEffect(() => {
  //   geoLocation();
  // }, []);

  const handleSearch = (e) => {
    setSerchData(e.target.value.trim());
  };

  const { data: userGlobalSerchData, refetch: userGlobalSerchDataList } =
    useQuery(
      ['userGlobalSerchData', lattitudeData, longitudeData],
      async ({ signal }) => {
        return await axiosPrivate
          .get(USER_API_URL.serchListOfUser, {
            signal,
            params: {
              long: Number(longitudeData),
              lat: Number(lattitudeData),
              skipId: userData?._id,
              search: searchData,
            },
          })
          .then((res) => res.data);
      },
      { refetchOnWindowFocus: false }
    );

  const handleClose = async () => {
    onClose();
    setSerchData('');
    await userGlobalSerchDataList();
  };
  return (
    <Dialog onClose={handleClose} open={open} className="request_popup_wrapper">
      <DialogTitle className="px-0 pt-0">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.search_user')}
        </span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Paper
        sx={{
          border: '1px solid #97a8be',
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <IconButton style={{ marginTop: '-2px' }}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={props.t('comman_label.search_user')}
          onChange={handleSearch}
        />
      </Paper>
      {searchData &&
        userGlobalSerchData &&
        userGlobalSerchData
          .filter((employee) => !employee?.locationPermission?.notShared)
          .filter((employee) =>
            employee.fullName.toLowerCase().includes(searchData.toLowerCase())
          )
          .map((user, index) => (
            <MapSearchUserCard
              key={index}
              userId={user.userId}
              fullName={user.fullName}
              userName={user.name}
              trusting={user.trustLevel}
              profilePic={user.profilePic}
              distance={user.distance}
              userStatus={user.userStatus}
              userPermissionsVisibility={user.visibility}
              userPermissionsLocation={user.locationPermission}
              userPermissionsChat={user.chatPermission}
              status={user.status}
              contact={user.contact}
              location={user.location}
              loginUserPermission={userProfileData?.permissions}
              onClose={onClose}
            />
          ))}
    </Dialog>
  );
};

SerchUserHomePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(SerchUserHomePopup);
