import React from 'react';
import { Outlet } from 'react-router';
import MainNavbar from 'src/components/layouts/MainNavbar';
import MainContent from 'src/components/layouts/MainContent';
import DashboardSidebar from 'src/components/layouts/DashboardSidebar';

const MasterLayout = () => {
  return (
    <html lang={localStorage.getItem('i18nextLng')}>
      <div className="master_wrapper">
        <MainNavbar />
        <div className="main_wrapper">
          <DashboardSidebar />
          <MainContent>
            <Outlet />
          </MainContent>
        </div>
      </div>
    </html>
  );
};

export default MasterLayout;
