// Regular expression pattern to match mentions in the format <:mention>
// Captures the text between <: and >
export const mentionPattern = /<:([^>]+)>/g;

// Function to replace mentions in a message with a more readable format
export const replaceMentions = (message) => {
  // If the message exists, replace mentions with '@mention' format
  // Otherwise, return the original message
  return message ? message.replace(mentionPattern, '@$1') : message;
};
