import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from 'src/svgComponents/SearchIcon';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { SOCIAL_MEDIA_URL } from 'src/api/axios';
import { useQuery } from 'react-query';
import { withTranslation } from 'react-i18next';
// import useStore from 'src/contexts/AuthProvider';
import FollowingRow from './FollowingRow';

const FollowingPopup = (props) => {
  const { onClose, open, newLinkId } = props;
  // const loginUserId = useStore((state) => state.user._id);
  const [searchData, setSerchData] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const handleClose = () => {
    onClose();
  };

  const handleSearch = (e) => {
    setSerchData(e.target.value.trim());
  };

  const { data: followingList, refetch: followingListData } = useQuery(
    ['followingList', newLinkId],
    async ({ signal }) => {
      if (newLinkId) {
        return await axiosPrivate
          .get(SOCIAL_MEDIA_URL.followingList.replace(':userId', newLinkId), {
            signal,
          })
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (newLinkId) {
      followingListData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLinkId]);
  return (
    <Dialog onClose={handleClose} open={open} className="linking_popup_wrapper">
      <DialogTitle className="px-0 pt-0">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.following_list')}
        </span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {followingList && followingList.length > 0 ? (
        <>
          <Paper className="linking_popup_searchbox">
            <IconButton style={{ marginTop: '-2px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={props.t('comman_label.search')}
              onChange={handleSearch}
            />
          </Paper>
          <List className="linkList" sx={{ pt: 0 }}>
            {followingList
              .filter((user) =>
                user.fullName.toLowerCase().includes(searchData.toLowerCase())
              )
              .map((user, index) => (
                <FollowingRow
                  key={index}
                  Id={user._id}
                  fullName={user.fullName}
                  userPic={user.userImage}
                  permissions={user.permission}
                  status={user.status}
                  fromUser={user.fromUser}
                  toUser={user.toUser}
                  userName={user.userName}
                  type={user.type}
                  onClose={handleClose}
                />
              ))}
          </List>
        </>
      ) : (
        <span className="app_text_20_semibold app_text_transform">
          {props.t('popup_message.no_following_user')}
        </span>
      )}
    </Dialog>
  );
};

FollowingPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(FollowingPopup);
