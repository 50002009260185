import React from 'react';

const ContactUs = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <g clipPath="url(#clip0_31553_26802)">
        <path
          d="M23.8162 29.3574C23.8162 28.7353 23.3118 28.2309 22.6897 28.2309H21.3103C21.0115 28.2309 20.725 28.3496 20.5138 28.5609C20.3025 28.7721 20.1838 29.0587 20.1838 29.3574C20.1838 29.9795 20.6882 30.4839 21.3103 30.4839H22.6897C23.3118 30.4839 23.8162 29.9795 23.8162 29.3574V29.3574ZM15.3682 26.4527C15.5563 26.4671 15.784 26.4753 16.0126 26.462C16.1344 27.0797 16.4371 27.6527 16.8888 28.1045C17.4885 28.7041 18.3018 29.041 19.1499 29.041H19.5797C19.5608 29.1447 19.551 29.2506 19.551 29.3574C19.551 29.4655 19.5608 29.5712 19.5794 29.6738H19.1499C18.134 29.6738 17.1597 29.2703 16.4413 28.5519C15.8682 27.9788 15.4955 27.2428 15.3682 26.4527V26.4527ZM15.0463 25.7794C14.5467 25.6951 14.0818 25.4576 13.7188 25.0947C13.2586 24.6344 13 24.0101 13 23.3592V21.8636C13 21.2126 13.2586 20.5884 13.7188 20.1281C14.1791 19.6678 14.8034 19.4092 15.4543 19.4092H15.6177C15.8788 16.1131 18.6365 13.5195 22 13.5195C25.3635 13.5195 28.1212 16.1131 28.3823 19.4092H28.5457C29.1966 19.4092 29.8209 19.6678 30.2812 20.1281C30.7414 20.5884 31 21.2126 31 21.8636V23.3592C31 24.0101 30.7414 24.6344 30.2812 25.0947C29.8209 25.555 29.1966 25.8135 28.5457 25.8135H27.8215C27.5211 25.8135 27.2776 25.57 27.2776 25.2697V19.9221C27.2776 17.0074 24.9147 14.6445 22 14.6445C19.0853 14.6445 16.7224 17.0074 16.7224 19.9221V25.2697C16.7224 25.4817 16.601 25.6655 16.4239 25.7552C15.9364 25.9243 15.1803 25.802 15.0463 25.7794V25.7794Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31553_26802">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactUs;
