import React from 'react';

const SocialsIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#404EFF" />
      <g clipPath="url(#clip0_29340_21084)">
        <path
          d="M25 25C26.2428 25 27.25 26.0072 27.25 27.25C27.25 28.4928 26.2428 29.5 25 29.5C23.7572 29.5 22.75 28.4928 22.75 27.25C22.75 26.0072 23.7572 25 25 25ZM17.5 22C19.1575 22 20.5 23.3425 20.5 25C20.5 26.6575 19.1575 28 17.5 28C15.8425 28 14.5 26.6575 14.5 25C14.5 23.3425 15.8425 22 17.5 22ZM23.875 14.5C26.1535 14.5 28 16.3465 28 18.625C28 20.9035 26.1535 22.75 23.875 22.75C21.5965 22.75 19.75 20.9035 19.75 18.625C19.75 16.3465 21.5965 14.5 23.875 14.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21084">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SocialsIcon;
