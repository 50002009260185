import React from 'react';

const ReportProblemIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#00CF73" />
      <g clipPath="url(#clip0_29340_21096)">
        <path
          d="M30.6402 26.1149L24.334 15.1922C23.304 13.3878 20.6993 13.3878 19.6729 15.1922L13.3631 26.1149C12.333 27.9193 13.615 30.161 15.6936 30.161H28.2874C30.366 30.161 31.6702 27.8971 30.6402 26.1149ZM21.9998 27.7378C21.4514 27.7378 20.9957 27.2821 20.9957 26.7337C20.9957 26.1853 21.4514 25.7296 21.9998 25.7296C22.5481 25.7296 23.0039 26.1853 22.9816 26.7596C23.0076 27.2821 22.5259 27.7378 21.9998 27.7378ZM22.9149 21.2464C22.8705 22.0245 22.8223 22.7988 22.7778 23.5769C22.7556 23.8289 22.7556 24.0586 22.7556 24.3068C22.7334 24.7181 22.411 25.0367 21.9998 25.0367C21.5885 25.0367 21.2699 24.7403 21.2439 24.3291C21.1772 23.1175 21.1068 21.9281 21.0401 20.7165C21.0179 20.3979 20.9957 20.0756 20.9697 19.7569C20.9697 19.2308 21.2662 18.7973 21.7478 18.6602C22.2295 18.5453 22.7075 18.775 22.9149 19.2308C22.9853 19.3901 23.0076 19.5494 23.0076 19.7347C22.9853 20.2423 22.9372 20.7462 22.9149 21.2464Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21096">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReportProblemIcon;
