import React from 'react';

const LogoWithText = () => {
  return (
    <svg
      width="129"
      height="30"
      viewBox="0 0 129 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2 25.4969V4.80005H41.5141V12.5816H41.6458C41.8348 12.1639 42.1083 11.7394 42.4661 11.3082C42.8307 10.8703 43.3032 10.5065 43.8839 10.2168C44.4712 9.92036 45.2004 9.77214 46.0713 9.77214C47.2055 9.77214 48.252 10.0686 49.2107 10.6615C50.1694 11.2476 50.9357 12.1335 51.5095 13.3193C52.0834 14.4983 52.3703 15.9771 52.3703 17.7558C52.3703 19.4872 52.0901 20.9492 51.5298 22.1417C50.9762 23.3275 50.22 24.2269 49.2613 24.84C48.3094 25.4463 47.2427 25.7495 46.0612 25.7495C45.224 25.7495 44.5117 25.6114 43.9244 25.3352C43.3438 25.059 42.8678 24.712 42.4965 24.2943C42.1251 23.8698 41.8416 23.442 41.6458 23.0108H41.4534V25.4969H37.2ZM41.423 17.7356C41.423 18.6586 41.5513 19.4637 41.8078 20.1509C42.0644 20.8381 42.4357 21.3737 42.9218 21.7577C43.4079 22.135 43.9986 22.3236 44.694 22.3236C45.3962 22.3236 45.9903 22.1316 46.4764 21.7476C46.9625 21.3568 47.3304 20.8179 47.5802 20.1307C47.8368 19.4367 47.9651 18.6384 47.9651 17.7356C47.9651 16.8395 47.8402 16.0513 47.5904 15.3708C47.3406 14.6903 46.9726 14.1581 46.4865 13.7741C46.0004 13.39 45.4029 13.198 44.694 13.198C43.9919 13.198 43.3978 13.3833 42.9117 13.7538C42.4323 14.1244 42.0644 14.6499 41.8078 15.3304C41.5513 16.0108 41.423 16.8126 41.423 17.7356Z"
        fill="white"
      />
      <path
        d="M62.3379 25.8C60.7378 25.8 59.3605 25.4767 58.206 24.8299C57.0583 24.1764 56.1739 23.2534 55.5528 22.0609C54.9316 20.8616 54.6211 19.4435 54.6211 17.8063C54.6211 16.2096 54.9316 14.8082 55.5528 13.6023C56.1739 12.3963 57.0482 11.4564 58.1757 10.7827C59.3099 10.109 60.6399 9.77214 62.1657 9.77214C63.1919 9.77214 64.1472 9.9372 65.0317 10.2673C65.9229 10.5907 66.6993 11.0792 67.3609 11.7327C68.0293 12.3862 68.5491 13.2081 68.9205 14.1985C69.2918 15.1821 69.4774 16.3342 69.4774 17.6547V18.8371H58.8846L58.8886 16.1692H65.4165C65.4165 15.5493 65.2815 15.0002 65.0114 14.5219C64.7414 14.0436 64.3667 13.6696 63.8873 13.4001C63.4147 13.1239 62.8645 12.9858 62.2366 12.9858C61.5817 12.9858 61.0011 13.1374 60.4948 13.4406C59.9952 13.737 59.6036 14.1379 59.32 14.6432C59.0365 15.1417 58.8913 15.6975 58.8846 16.3106V18.8472C58.8846 19.6153 59.0263 20.2789 59.3099 20.8381C59.6002 21.3973 60.0087 21.8284 60.5353 22.1316C61.0619 22.4348 61.6864 22.5864 62.4088 22.5864C62.8881 22.5864 63.327 22.519 63.7253 22.3843C64.1236 22.2495 64.4646 22.0474 64.7481 21.7779C65.0317 21.5084 65.2477 21.1783 65.3962 20.7875L69.3863 21.0503C69.1838 22.007 68.7686 22.8424 68.1407 23.5565C67.5195 24.264 66.7161 24.8164 65.7304 25.2139C64.7515 25.6047 63.6206 25.8 62.3379 25.8Z"
        fill="white"
      />
      <path
        d="M89.9957 25.4969V9.97425H94.1073V12.7129H94.2896C94.6136 11.8034 95.1537 11.0859 95.9099 10.5604C96.666 10.0349 97.5707 9.77214 98.6239 9.77214C99.6906 9.77214 100.599 10.0383 101.348 10.5705C102.098 11.096 102.597 11.8102 102.847 12.7129H103.009C103.326 11.8236 103.9 11.1128 104.731 10.5806C105.568 10.0416 106.557 9.77214 107.698 9.77214C109.149 9.77214 110.327 10.2336 111.232 11.1566C112.144 12.0729 112.599 13.3732 112.599 15.0575V25.4969H108.295V15.9064C108.295 15.044 108.066 14.3973 107.607 13.9661C107.148 13.5349 106.574 13.3193 105.885 13.3193C105.102 13.3193 104.491 13.5686 104.052 14.0671C103.613 14.559 103.394 15.2091 103.394 16.0176V25.4969H99.2113V15.8154C99.2113 15.0541 98.9919 14.4478 98.553 13.9964C98.121 13.545 97.5505 13.3193 96.8416 13.3193C96.3622 13.3193 95.9301 13.4406 95.5453 13.6831C95.1672 13.9189 94.8668 14.2524 94.644 14.6836C94.4212 15.108 94.3098 15.6066 94.3098 16.1793V25.4969H89.9957Z"
        fill="white"
      />
      <path
        d="M115.926 25.4969V22.93L123.542 13.5214V13.4103H116.189V9.97425H128.737V12.7736L121.587 21.9497V22.0609H129V25.4969H115.926Z"
        fill="white"
      />
      <path
        d="M79.4636 25.8C81.0636 25.8 82.4409 25.4767 83.5954 24.8299C84.7431 24.1764 85.6276 23.2534 86.2487 22.0609C86.8698 20.8616 87.1804 19.4435 87.1804 17.8063C87.1804 16.2096 86.8698 14.8082 86.2487 13.6023C85.6276 12.3963 84.7532 11.4564 83.6258 10.7827C82.4915 10.109 81.1615 9.77214 79.6357 9.77214C78.6095 9.77214 77.6542 9.9372 76.7698 10.2673C75.8786 10.5907 75.1022 11.0792 74.4405 11.7327C73.7722 12.3862 73.2523 13.2081 72.881 14.1985C72.5097 15.1821 72.324 16.3342 72.324 17.6547V18.8371H82.9169L82.9128 16.1692H76.3849C76.3849 15.5493 76.52 15.0002 76.79 14.5219C77.0601 14.0436 77.4348 13.6696 77.9141 13.4001C78.3867 13.1239 78.937 12.9858 79.5648 12.9858C80.2197 12.9858 80.8003 13.1374 81.3067 13.4406C81.8063 13.737 82.1979 14.1379 82.4814 14.6432C82.765 15.1417 82.9101 15.6975 82.9169 16.3106V18.8472C82.9169 19.6153 82.7751 20.2789 82.4915 20.8381C82.2012 21.3973 81.7928 21.8284 81.2662 22.1316C80.7396 22.4348 80.1151 22.5864 79.3927 22.5864C78.9133 22.5864 78.4745 22.519 78.0762 22.3843C77.6778 22.2495 77.3369 22.0474 77.0533 21.7779C76.7698 21.5084 76.5537 21.1783 76.4052 20.7875L72.4151 21.0503C72.6177 22.007 73.0329 22.8424 73.6608 23.5565C74.2819 24.264 75.0853 24.8164 76.071 25.2139C77.0499 25.6047 78.1808 25.8 79.4636 25.8Z"
        fill="#BB86FC"
      />
      <circle cx="15" cy="15" r="15" fill="#F2E9FF" />
      <rect
        x="2.24469"
        y="2.33838"
        width="25.3349"
        height="25.3349"
        rx="12.6675"
        fill="white"
      />
      <rect
        width="5.15696"
        height="2.11786"
        transform="matrix(0.867368 0.497667 -0.474875 0.880053 13.6124 10.5804)"
        fill="#BB86FC"
      />
      <rect
        width="5.49249"
        height="2.12325"
        transform="matrix(0.903801 -0.427954 0.408341 0.91283 12.002 18.3671)"
        fill="#BB86FC"
      />
      <ellipse
        cx="10.9137"
        cy="19.6737"
        rx="3.11812"
        ry="3.11812"
        fill="#BB86FC"
      />
      <circle cx="19.5484" cy="15.7319" r="3.11812" fill="#BB86FC" />
      <path
        d="M14.211 9.08015C14.9909 10.6155 14.3786 12.4924 12.8432 13.2724C11.3079 14.0523 9.43096 13.44 8.651 11.9046C7.87105 10.3693 8.48341 8.49236 10.0187 7.7124C11.5541 6.93244 13.431 7.54481 14.211 9.08015Z"
        fill="#BB86FC"
      />
    </svg>
  );
};

export default LogoWithText;
