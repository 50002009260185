import React from 'react';
import { Avatar, Box, Button, ListItem, ListItemAvatar } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { USER_API_URL } from 'src/api/axios';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import UserAvatar from '../common/UserAvatar';
import files from 'src/helpers/helpers';
import { withTranslation } from 'react-i18next';

const RefReqRow = (props) => {
  const {
    requestId,
    // userId,
    name,
    userPic,
    // mobile,
    userName,
    businessName,
    endorsedType,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const { mutateAsync: referencesRequest } = useMutation(
    async (data) => {
      if (data) {
        return await axiosPrivate.put(
          USER_API_URL.referencesRequest + '/' + requestId
        );
      } else {
        return await axiosPrivate.delete(
          USER_API_URL.referencesRequest + '/' + requestId
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['friendRefReqList']);
        queryClient.invalidateQueries(['userProfileReferences']);
        queryClient.invalidateQueries(['userLinkListRefrences']);
        queryClient.invalidateQueries(['userRequestList']);
        queryClient.invalidateQueries(['referencesRequest']);
      },
    }
  );

  const rejected = async () => {
    await referencesRequest(false);
  };

  const accepted = async () => {
    await referencesRequest(true);
  };

  return (
    <ListItem className="px-0 d-flex align-items-start">
      <ListItemAvatar className="linkList_avatar_container">
        <Avatar>
          <UserAvatar
            diameter={40}
            imgSrc={files(userPic, 'image')}
            alternateSrc={endorsedType === 1 ? userName : businessName}
          />
        </Avatar>
      </ListItemAvatar>
      <Box sx={{ width: '60%' }} className="d-flex flex-column">
        <h4 className="app_text_14_500">{name}</h4>
        {(userName || businessName) && (
          <div className="d-flex">
            <span className="app_text_gray app_text_12">{`@${
              endorsedType === 1 ? userName : businessName
            }`}</span>
          </div>
        )}
      </Box>
      <div className="d-flex flex-nowrap">
        <Button
          className={`linkList_btn app_border_primary app_text_transform app_text_14_semibold app_text_primary app_bg_primary_light`}
          onClick={rejected}
          variant="outlined"
        >
          {props.t('popup_btn.reject')}
        </Button>
        <Button
          className={`linkList_btn app_text_transform app_text_14_semibold text-white app_bg_primary ms-2`}
          onClick={accepted}
          variant="contained"
        >
          {props.t('popup_btn.accept')}
        </Button>
      </div>
    </ListItem>
  );
};

export default withTranslation()(RefReqRow);
