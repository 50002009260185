import React from 'react';
import { Stack } from '@mui/material';
import UserAvatar from '../common/UserAvatar';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useNavigate } from 'react-router';
import { PATH_USER } from 'src/routes/paths';

const UserAvatarWithNameAndID = ({
  userImg,
  businessId,
  businessImage,
  avatarSize,
  userFullName,
  businessOwner,
  businessName,
  userId,
  id,
}) => {
  const navigate = useNavigate();
  const handleNavigationToProfile = () => {
    navigate(PATH_USER.userProfile, { state: { otherUserId: id } });
  };

  return (
    <div
      className={`d-flex ${userId ? 'align-item-start' : 'align-items-center'}`}
    >
      {businessId ? (
        <UserAvatar
          diameter={avatarSize}
          alternateSrc={userFullName.toLowerCase()}
          imgSrc={businessImage}
        />
      ) : (
        <UserAvatar
          diameter={avatarSize}
          alternateSrc={userFullName.toLowerCase()}
          imgSrc={userImg}
        />
      )}
      <Stack
        direction="column"
        sx={{ marginLeft: 1.5 }}
        className="justify-content-center"
        spacing={0}
      >
        <span
          className="app_text_14_semibold app_text_black text-capitalize "
          onClick={handleNavigationToProfile}
        >
          {userFullName.toLowerCase()}
        </span>
        {userId && (
          <span className="app_text_12 app_text_gray text-lowercase">{`@${userId}`}</span>
        )}
        {/* {businessOwner && <span className='app_text_gray app_text_12'><AssignmentIndIcon sx={{ color: "#97A8BE", fontSize: 15 }} /><span className='app_text_gray app_text_14 mx-1'>{businessOwner}</span></span>} */}
        {businessName && (
          <span className="app_text_gray app_text_12">
            <BusinessCenterIcon sx={{ color: '#97A8BE', fontSize: 15 }} />
            <span className="app_text_gray app_text_14 mx-1">
              {businessName}
            </span>
          </span>
        )}
      </Stack>
    </div>
  );
};

export default UserAvatarWithNameAndID;
