import React from 'react';

const NotificationIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#FF7302" />
      <g clipPath="url(#clip0_29340_21073)">
        <path
          d="M28 27.0002L28.3 27.4C28.3418 27.4557 28.3672 27.522 28.3735 27.5913C28.3797 27.6607 28.3666 27.7304 28.3354 27.7927C28.3043 27.855 28.2564 27.9074 28.1971 27.944C28.1379 27.9806 28.0696 28 28 28H16C15.9304 28 15.8621 27.9806 15.8029 27.944C15.7436 27.9074 15.6957 27.855 15.6646 27.7927C15.6334 27.7304 15.6203 27.6607 15.6265 27.5913C15.6328 27.522 15.6582 27.4557 15.7 27.4L16 27.0002V20.5C16 18.9087 16.6321 17.3826 17.7574 16.2574C18.8826 15.1321 20.4087 14.5 22 14.5C23.5913 14.5 25.1174 15.1321 26.2426 16.2574C27.3679 17.3826 28 18.9087 28 20.5V27.0002ZM20.125 28.75H23.875C23.875 29.2473 23.6775 29.7242 23.3258 30.0758C22.9742 30.4275 22.4973 30.625 22 30.625C21.5027 30.625 21.0258 30.4275 20.6742 30.0758C20.3225 29.7242 20.125 29.2473 20.125 28.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21073">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotificationIcon;
