import React from 'react';

const EarthIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31686_26860)">
        <path
          d="M9.15281 2.71836L9.0032 2.76579L8.20648 2.83677L7.98143 3.19615L7.81814 3.14426L7.18407 2.57258L7.09208 2.27528L6.96889 1.95824L6.57037 1.60078L6.10022 1.50879L6.0894 1.72397L6.54999 2.17342L6.77536 2.43889L6.52198 2.5713L6.31572 2.51051L6.00664 2.38159L6.01714 2.13236L5.61162 1.96556L5.47697 2.55157L5.06827 2.6442L5.10869 2.9711L5.64122 3.0736L5.73321 2.55125L6.1728 2.61619L6.37715 2.73587H6.70501L6.92942 3.18532L7.52434 3.78884L7.48073 4.02343L7.00104 3.96232L6.17216 4.38089L5.57533 5.09677L5.49766 5.41381H5.28344L4.88428 5.22982L4.49658 5.41381L4.59303 5.82283L4.76173 5.62835L5.0584 5.61911L5.03771 5.98644L5.28344 6.05838L5.52886 6.33404L5.92961 6.22136L6.38734 6.29361L6.91891 6.43653L7.18438 6.46773L7.63447 6.97861L8.50314 7.4895L7.94132 8.56284L7.34831 8.83849L7.12327 9.45188L6.26511 10.0248L6.17375 10.3552C8.36754 9.82684 9.99951 7.85619 9.99951 5.50007C10.0004 4.50923 9.70559 3.54067 9.15281 2.71836Z"
          fill="#454F5A"
        />
        <path
          d="M5.57513 8.113L5.21099 7.43787L5.54521 6.74141L5.21099 6.64146L4.8357 6.26458L4.00428 6.07805L3.7283 5.50064V5.84346H3.60671L2.89019 4.87198V4.07398L2.36498 3.21995L1.53101 3.3686H0.969199L0.68654 3.18335L1.04718 2.89751L0.687495 2.98059C0.238159 3.74428 0.00121686 4.61424 0.0012207 5.50032C0.0012207 8.26102 2.23925 10.5 5.00027 10.5C5.2129 10.5 5.42139 10.4812 5.62765 10.4564L5.57545 9.85065C5.57545 9.85065 5.80495 8.95111 5.80495 8.92055C5.80463 8.88968 5.57513 8.113 5.57513 8.113ZM1.85887 2.11224L2.74696 1.98842L3.1563 1.76401L3.61689 1.89674L4.35283 1.856L4.60493 1.45971L4.97257 1.52018L5.86543 1.43647L6.11148 1.16527L6.45844 0.93354L6.94927 1.00739L7.12816 0.980332C6.46296 0.664933 5.73613 0.50089 4.99995 0.500003C4.2629 0.499178 3.53484 0.661788 2.86819 0.976126C2.20154 1.29046 1.61288 1.74871 1.14459 2.31787H1.14713L1.85887 2.11224ZM5.21131 0.997202L5.72187 0.716135L6.04973 0.905529L5.57513 1.26681L5.12186 1.31233L4.91782 1.17991L5.21131 0.997202ZM3.69902 1.03826L3.92438 1.13217L4.21945 1.03826L4.3802 1.31678L3.69902 1.49567L3.37148 1.30405C3.37116 1.30405 3.6917 1.09779 3.69902 1.03826Z"
          fill="#454F5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_31686_26860">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EarthIcon;
