import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import { useRef } from 'react';
import UserAvatarWithNameAndRating from '../common/UserAvatarWithNameAndRating';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AppTooltip from '../common/AppTooltip';
import '@lottiefiles/lottie-player';
import TodayIcon from '@mui/icons-material/Today';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DirectionsIcon from '@mui/icons-material/Directions';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { EVENT_API_URL } from 'src/api/axios';
import { useQuery } from 'react-query';
import files from 'src/helpers/helpers';
import moment from 'moment';
import _ from 'lodash';
import imgNot from '../../assets/images/imageNot.png';
import { withTranslation } from 'react-i18next';
import { authStore } from 'src/contexts/AuthProvider';
import Slider from 'react-slick';
import EventInvationListPopup from '../dashboard/EventInvationListPopup';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

const MapEventDetailSidebar = (props) => {
  const { onCloseFunc, eventDataId } = props;
  const mapSidebarEventContentBoxRef = useRef(null);
  const [lottiPlayerLoder, setLottiPlayerLoder] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const [mapSidebarEventHeaderClassName, setMapSidebarEventHeaderClassName] =
    useState('');
  const { _id: profileId } = authStore((state) => state.user);
  const navigate = useNavigate();

  const {
    data: userSingleEventData,
    refetch: userRefetchEventSingleData,
    isLoading: eventLoder,
  } = useQuery(
    ['userEventSingleData', eventDataId],
    async ({ signal }) => {
      if (eventDataId) {
        return await axiosPrivate
          .get(
            EVENT_API_URL.getSignleEventDetails.replace(
              ':eventId',
              eventDataId
            ),
            {
              signal,
            }
          )
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );
  const { data: eventInvationList, refetch: eventInvationListData } = useQuery(
    ['eventInvationList', eventDataId],
    async ({ signal }) => {
      if (eventDataId) {
        return await axiosPrivate
          .get(EVENT_API_URL.eventInvitationList, {
            signal,
            params: { eventId: eventDataId },
          })
          .then((res) => res.data);
      }
    },
    { refetchOnWindowFocus: false }
  );

  const startData = new Date(userSingleEventData?.startDateTime);
  const startDay = moment(startData).format('ddd');
  const startDate = moment(startData).format('DD MMMM YYYY');
  const { eventDates } = userSingleEventData ?? {};
  const startDateTime = moment(eventDates?.startUtc)
    .utc()
    .format('DD MMM h:mm A');
  const endDateTime = moment(eventDates?.endUtc)
    .utc()
    .format('DD MMM h:mm A YYYY');

  const formattedDateRange = `${startDateTime} to ${endDateTime}`;
  useEffect(() => {
    const scrollEvent = () => {
      if (mapSidebarEventContentBoxRef.current.scrollTop > 5) {
        setMapSidebarEventHeaderClassName('scrolling');
      } else {
        setMapSidebarEventHeaderClassName('');
      }
    };
    const scrollConst = mapSidebarEventContentBoxRef?.current;
    scrollConst.addEventListener('scroll', scrollEvent);
    return () => {
      scrollConst.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  useEffect(() => {
    setLottiPlayerLoder(eventLoder);
  }, [eventLoder]);

  const handleEventOnCardClick = (eventIds) => {
    navigate(PATH_DASHBOARD.general.eventdetail, {
      state: { eventId: eventIds },
    });
  };
  return (
    <div
      className="map_event_detail_sidebar_wrapper"
      style={{ height: '100%' }}
    >
      <EventHeader
        className={mapSidebarEventHeaderClassName}
        onCloseFunc={onCloseFunc}
        eventTitle={userSingleEventData?.title}
      />
      <Box
        ref={mapSidebarEventContentBoxRef}
        className="map_sidebar_event_content"
      >
        {eventLoder ? (
          <Box mt="56px">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="https://assets6.lottiefiles.com/packages/lf20_f1dhzsnx.json"
            ></lottie-player>
          </Box>
        ) : (
          <>
            <EventBanner
              bannerImage={userSingleEventData?.image}
              bannerVideo={userSingleEventData?.video}
            />
            <EventTitle
              eventInvationList={eventInvationList}
              eventTitle={userSingleEventData?.title}
              eventVisblity={userSingleEventData?.visibility}
              t={props.t}
            />
            <EventDivider />
            <EventDescription
              eventDescription={userSingleEventData?.description}
              t={props.t}
            />
            <EventDivider />

            <EventSchedule
              formattedDateRange={formattedDateRange}
              startDay={startDay}
              startDate={startDate}
              startTime={startData}
            />

            <EventLocation
              t={props.t}
              eventId={userSingleEventData?._id}
              address={userSingleEventData?.addresses}
            />
            <EventDivider />
            <EventCreatedBy
              profileId={profileId}
              trust={userSingleEventData?.trust}
              userId={userSingleEventData?.userId}
              permissions={userSingleEventData?.permission}
              userImage={userSingleEventData?.userImage}
              userName={userSingleEventData?.userName}
              t={props.t}
            />
            <Button
              className="app_bg_primary_light app_text_primary app_text_14_semibold"
              onClick={() => handleEventOnCardClick(userSingleEventData?._id)}
              sx={{
                width: '100%',
                textTransform: 'lowercase',
                padding: '12px 16px',
              }}
            >
              {props.t('comman_label.view_more')}
            </Button>
          </>
        )}
      </Box>
    </div>
  );
};
export default withTranslation()(MapEventDetailSidebar);

const EventDivider = () => (
  <Divider sx={{ borderBottom: '1px solid rgb(116 116 116)' }} />
);

const EventHeader = ({ className, onCloseFunc, eventTitle }) => {
  return (
    <Box className={`map_event_detail_sidebar_header ${className}`}>
      <h4 className="app_text_16_semibold mx-auto text-center pt-1 w-100 mb-0 mt-1 text_limit_250 app_text_transform title text-white">
        {eventTitle}
      </h4>
      <IconButton
        onClick={() => onCloseFunc()}
        className="close_btn"
        sx={{ padding: '2px !important' }}
      >
        <Close sx={{ color: '#fff' }} />
      </IconButton>
    </Box>
  );
};

const EventBanner = ({ bannerImage, bannerVideo }) => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Box className="map_event_sidebar_banner_image_container">
      <Slider {...settings}>
        {bannerImage?.length > 0 &&
          bannerImage.map((image, i) => (
            <Box className="slide_image_video_wrapper" key={i}>
              <img src={files(image, 'attachments')} alt="event" />
            </Box>
          ))}
        {bannerVideo?.length > 0 &&
          bannerVideo.map((video, i) => (
            <Box className="slide_image_video_wrapper" key={i}>
              <video
                src={files(video?.video, 'attachments')}
                loop
                autoPlay
                controls
              />
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

const EventTitle = ({ eventTitle, eventVisblity, eventInvationList, t }) => {
  const [invationListOpen, setInvationListOpen] = useState(false);
  const invationListClose = () => {
    setInvationListOpen(false);
  };
  return (
    <>
      <Box className="map_event_detail_sidebar_title">
        <h4 className="app_text_18_semibold app_text_black text-wrap app_text-transoform">
          {eventTitle}
        </h4>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <Chip
            className="app_text_14_500 px-2"
            label={`${
              eventVisblity === 0
                ? t('chip_label.public')
                : t('chip_label.private')
            }`}
            sx={{ color: '#fff', backgroundColor: '#6200ee' }}
          />
          {eventInvationList?.length > 0 && (
            <div
              onClick={() => setInvationListOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              <AvatarGroup max={4}>
                {eventInvationList.map((user, index) => (
                  <Avatar
                    sx={{ width: '32px', height: '32px' }}
                    alt={user?.fullName}
                    src={
                      user?.permissions?.visibility?.picture
                        ? files(user?.image, 'image')
                        : ''
                    }
                  />
                ))}
              </AvatarGroup>
            </div>
          )}
        </Box>
      </Box>
      <EventInvationListPopup
        eventTitle={eventTitle}
        eventInvationList={eventInvationList}
        open={invationListOpen}
        onClose={invationListClose}
      />
    </>
  );
};

const EventDescription = ({ eventDescription, t }) => {
  const [readState, setReadState] = useState('more');
  const [showReadMoreBtn, setShowReadMoreBtn] = useState(false);
  const eventDescRef = useRef(null);

  useEffect(() => {
    let descHeight = eventDescRef.current.clientHeight;
    if (descHeight >= 54) {
      setShowReadMoreBtn(true);
    } else {
      setShowReadMoreBtn(false);
    }
  }, []);

  return (
    <Box sx={{ padding: '1rem 12px' }}>
      <h4 className="app_text_14_semibold app_text_black mb-2">
        {t('sidebar_menu_text.about_this_event')}
      </h4>
      <p
        ref={eventDescRef}
        className={`app_text_12_fw500 app_text_gray ${
          readState === 'less' ? '' : 'app_para_lineClamp_3'
        } mb-0`}
      >
        {' '}
        {eventDescription}{' '}
      </p>
      {showReadMoreBtn && (
        <Button
          className="app_blank_btn app_text_14_semibold app_text_transform app_text_primary"
          sx={{ minWidth: 'unset', p: 0, display: 'inline-block' }}
          onClick={() =>
            setReadState((prev) => (prev === 'more' ? 'less' : 'more'))
          }
        >{`read ${readState}`}</Button>
      )}
    </Box>
  );
};

const EventCreatedBy = ({
  userImage,
  permissions,
  trust,
  userId,
  profileId,
  userName,
  t,
}) => {
  return (
    <Box sx={{ padding: '1rem 12px' }}>
      <h4 className="app_text_14_semibold app_text_black mb-3">
        {t('sidebar_menu_text.event_created')}
      </h4>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <UserAvatarWithNameAndRating
          rating={trust}
          avatarSize={50}
          imgSrc={
            userId === profileId || permissions?.visibility?.picture
              ? files(userImage, 'image')
              : ''
          }
          userFullName={userName}
        />
        {/* <AppTooltip title={t('tooltip_text.link_with_creator')}>
                <Button sx={{ height: '24px', width: '24px', minWidth: 'unset', p: 2 }} className='app_bg_primary text-white app_text_transform'><AddLinkIcon style={{ fontSize: 16 }} /></Button>
            </AppTooltip> */}
      </Box>
    </Box>
  );
};

const EventSchedule = ({ formattedDateRange }) => {
  return (
    <Box sx={{ padding: '12px 12px' }}>
      <Stack gap={'8px'} alignItems="center" direction="row">
        <div className="icon_squarebox app_bg_primary_light">
          <TodayIcon style={{ color: '#6200ee' }} />
        </div>
        <Box
          className="d-flex flex-column justify-content-between align-items-start"
          sx={{ wordWrap: 'break-word' }}
        >
          <span className="app_text_16_500 app_text_black">
            {formattedDateRange}
          </span>
          {/* <span className="app_text_14_500 app_text_gray">{`${startDay}, ${moment(
              startTime,
              'hh:mm A'
            ).format('hh:mm A')}`}</span> */}
        </Box>
      </Stack>
    </Box>
  );
};

const EventLocation = ({ address, eventId, t }) => {
  const direction = ({ long, lat }) => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${long},${lat}`
    );
  };

  return (
    <Box sx={{ padding: '12px 12px' }}>
      <List sx={{ padding: '0px' }}>
        {address?.length > 0 &&
          address.map((address, i) => (
            <ListItem sx={{ padding: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  gap: '8px',
                  alignItems: 'start',
                }}
                key={i}
              >
                <div className="icon_squarebox app_bg_primary_light">
                  <FmdGoodIcon style={{ color: '#6200ee' }} />
                </div>
                <div className="d-flex flex-column justify-content-between align-items-start">
                  <p
                    style={{
                      maxWidth: '300px',
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                    className="my-1 app_text_16_500 app_text_black"
                  >
                    {address?.physicalAddress}
                  </p>
                </div>
              </Box>
              <AppTooltip title={t('tooltip_text.direation')}>
                <Button
                  onClick={() =>
                    direction({
                      long: address?.location?.coordinates[1],
                      lat: address?.location?.coordinates[0],
                    })
                  }
                  sx={{
                    height: '50px',
                    width: '50px',
                    minWidth: 'unset',
                    borderRadius: '15px',
                  }}
                  className="app_bg_primary text-white app_text_transform"
                >
                  <DirectionsIcon style={{ fontSize: 16 }} />
                </Button>
              </AppTooltip>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
