import moment from 'moment';
import Chip from '@mui/material/Chip';
import { Suspense } from 'react';
import * as geolib from 'geolib';
import { GroupEvents } from 'src/utils/enum';
import _ from 'lodash';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth';
import { authentication } from 'src/firebase-config';

export default function files(name, type) {
  switch (type) {
    case 'image':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/images/${name}`;

    case 'file':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/files/${name}`;

    case 'audio':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/audio/${name}`;

    case 'video':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/video/${name}`;

    case 'xlsx':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/sampleFile/${name}`;

    case 'attachments':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/attachments/${name}`;

    case 'thumb':
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/attachments/thumb/${name}`;

    default:
      return `${process.env.REACT_APP_MEDIA_URL}/uploads/images/${name}`;
  }
}

const getFileType = (type) => {
  switch (true) {
    case /image\/*/.test(type):
      return 'image';
    case /video\/*/.test(type):
      return 'video';
    case /audio\/*/.test(type):
      return 'audio';
    default:
      return 'file';
  }
};

function decodeGroupMessage(data) {
  if (_.isEmpty(data)) return false;
  try {
    if (typeof data === 'string') data = JSON.parse(data);
    const {
      type,
      actionUserDetail,
      affectedUserId,
      affectedUserDetail,
      message,
    } = data;

    switch (type) {
      case GroupEvents.NORMAL_MESSAGE:
        return { message, type };
      case GroupEvents.CREATE:
        return {
          message: `${actionUserDetail} created ${message || 'group'}`,
          type,
        };
      case GroupEvents.ADD_MEMBER:
        return {
          message: `${actionUserDetail} added ${affectedUserDetail} to this conversation`,
          type,
          affectedUserId,
        };
      case GroupEvents.REMOVE_MEMBER:
        return {
          message: `${actionUserDetail} removed ${affectedUserDetail}`,
          type,
          affectedUserId,
        };
      case GroupEvents.JOIN_MEMBER:
        return {
          message: `${actionUserDetail} joined this conversation`,
          type,
        };
      case GroupEvents.LEAVE_GROUP:
        return {
          message: `${actionUserDetail} left from this conversation`,
          type,
        };
      case GroupEvents.ASSIGN_ADMIN:
        return {
          message: `${actionUserDetail} assigned ${affectedUserDetail} as an admin`,
          type,
        };
      case GroupEvents.REMOVE_ADMIN:
        return {
          message: `${actionUserDetail} removed ${affectedUserDetail} as an admin`,
          type,
        };
      case GroupEvents.UPDATE_GROUP:
        return {
          message: `${actionUserDetail} has updated group info`,
          type,
          updatedGroup:
            typeof message === 'string' ? JSON.parse(message) : message,
        };
      case GroupEvents.INACTIVE_GROUP:
        return {
          message: `group inactive by ${actionUserDetail}`,
          type,
          updatedGroup:
            typeof message === 'string' ? JSON.parse(message) : message,
        };
      case GroupEvents.ACTIVE_GROUP:
        return {
          message: `group active by ${actionUserDetail}`,
          type,
          updatedGroup:
            typeof message === 'string' ? JSON.parse(message) : message,
        };
      case GroupEvents.DELETE_GROUP:
        return {
          message: `${actionUserDetail} delete this group`,
          type,
          updatedGroup:
            typeof message === 'string' ? JSON.parse(message) : message,
        };
      default:
        return { message, type };
    }
  } catch (er) {
    return { message: data, type: GroupEvents.NORMAL_MESSAGE };
  }
}

function endorsedStatus(status) {
  switch (status) {
    case 1:
      return (
        <Chip
          label="Pending"
          sx={{ backgroundColor: '#FFCB45', color: '#fff', width: '100px' }}
        />
      );

    case 2:
      return (
        <Chip
          label="Endorsed"
          sx={{ backgroundColor: '#00CF73', color: '#fff', width: '100px' }}
        />
      );

    case 3:
      return (
        <Chip
          label="Rejected"
          sx={{ backgroundColor: '#FF5367', color: '#fff', width: '100px' }}
        />
      );

    default:
      return <></>;
  }
}

function formatDate(date) {
  return moment(date).format('yyyy-MM-DD HH:mm:ss');
}

const createTimestamp = (timeString) => {
  let today = new Date(timeString);
  return today.toLocaleTimeString('en-IN', { timeStyle: 'short' });
};
const decodePolyline = (str, precision) => {
  var index = 0,
    lat = 0,
    lng = 0,
    coordinates = [],
    shift = 0,
    result = 0,
    byte = null,
    latitude_change,
    longitude_change,
    factor = Math.pow(10, Number.isInteger(precision) ? precision : 5);

  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;
    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;
    coordinates.push({ lat: lat / factor, lng: lng / factor });
  }
  return coordinates;
};

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { pathname } = useLocation();
  // const isDashboard = pathname.includes("/dashboard");

  //TODO: replace loading.. with loader
  return (
    <Suspense fallback={'loading...'}>
      <Component {...props} />
    </Suspense>
  );
};

const calcCrow = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

const toRad = (Value) => {
  return (Value * Math.PI) / 180;
};

const getDistanceInKm = (lat1, lng1, lat2, lng2) => {
  let origins = {};
  let destinations = {};
  if (lat1 && lng1) origins = { latitude: lat1, longitude: lng1 };

  if (lat2 && lng2) destinations = { latitude: lat2, longitude: lng2 };

  if (origins && destinations) {
    const distance = geolib.getDistance(origins, destinations);
    const distanceKm = geolib.convertDistance(distance, 'km');
    const result = parseFloat(parseFloat(distanceKm).toFixed(2));
    return result;
  }

  return false;
};

const processData = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(
    /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  );

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length === headers.length) {
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"') d = d.substring(1, d.length - 1);
          if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
        }
        if (headers[j]) {
          obj[headers[j]] = d;
        }
      }
      // remove the blank rows
      if (Object.values(obj).filter((x) => x).length > 0) {
        list.push(obj);
      }
    }
  }
  return list;
};

const DisplayFormikState = (props) => (
  <div style={{ margin: '1rem 0' }}>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

// generate random unique id

const uid = () => {
  return '_' + Math.random().toString(36).substring(2, 9);
};

const helperMessageArray = [
  'assistance needed',
  'booking request',
  'order request',
  'is my food ready',
  'waiter needed',
  'cheque please',
  'ingredients query',
  'incorrect order',
  'complaint ',
  'compliment ',
  'manager needed',
  'billing query',
  'price check',
  'i have been waiting too long',
];

const generateRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: (response) => {},
    },
    authentication
  );
};

const signPhone = async (phoneNumber) => {
  const appVerifier = window.recaptchaVerifier;
  let response, error;
  await Promise.resolve(
    await signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        response = true;
      })
      .catch((errors) => {
        error = errors;
      })
  );
  return [response, error];
};

const otpVerification = async (otpString) => {
  let confirmationResult = window.confirmationResult;
  let response, error;
  await Promise.resolve(
    confirmationResult
      .confirm(otpString)
      .then((result) => {
        const user = result.user;
        response = user;
      })
      .catch((errors) => {
        error = errors;
      })
  );
  return [response, error];
};

const signOutPhone = () => {
  signOut(authentication)
    .then(() => {})
    .catch((error) => {});
};

const convertToUtc = (date) => {
  if (date) {
    const newDate = new Date(date);

    const utcTime = newDate.getTime() - newDate.getTimezoneOffset() * 60000;
    const utcDate = new Date(utcTime);

    const utcDateString = utcDate.toISOString();
    return utcDateString;
  }
};
const convertToIst = (utcDate) => {
  // const dateString = "2023-04-25T04:30:00.548Z";
  if (utcDate) {
    const newDate = new Date(utcDate);
    newDate.toString();
    return newDate;
  }
};
const dateStringToDate = (date) => {
  let year = date?.getFullYear();
  let month = (date?.getMonth() + 1)?.toString()?.padStart(2, '0'); // add leading zeros if necessary
  let day = date?.getDate()?.toString()?.padStart(2, '0'); // add leading zeros if necessary
  let dateStr = `${year}-${month}-${day}`;
  return dateStr;
};
const dateStringToTime = (date) => {
  // Get the hours, minutes, and seconds values
  let hours = date?.getHours()?.toString()?.padStart(2, '0'); // add leading zeros if necessary
  let minutes = date?.getMinutes()?.toString()?.padStart(2, '0'); // add leading zeros if necessary
  // let seconds = date.getSeconds().toString().padStart(2, "0"); // add leading zeros if necessary
  let timeStr = `${hours}:${minutes}`;
  return timeStr;
};

function isWithinOneKilometer(buisnessLoc, userLoc) {
  const userLat = userLoc?.[0];
  const userLon = userLoc?.[1];
  const businessLat = buisnessLoc?.[0];
  const businessLon = buisnessLoc?.[1];
  // return;

  const earthRadius = 6371; // in km

  const dLat = toRadians(businessLat - userLat);
  const dLon = toRadians(businessLon - userLon);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(userLat)) *
      Math.cos(toRadians(businessLat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;

  return distance < 1;
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export {
  createTimestamp,
  formatDate,
  endorsedStatus,
  decodePolyline,
  Loadable,
  calcCrow,
  getDistanceInKm,
  decodeGroupMessage,
  DisplayFormikState,
  processData,
  uid,
  getFileType,
  helperMessageArray,
  generateRecaptcha,
  signPhone,
  otpVerification,
  signOutPhone,
  convertToUtc,
  convertToIst,
  dateStringToDate,
  dateStringToTime,
  isWithinOneKilometer,
};
