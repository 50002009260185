import React from 'react';

const ExchangeCircle = ({ size }) => {
  return (
    <svg
      width={size ? size : '24'}
      height={size ? size : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.39275 8.30198H17.9346M17.9346 8.30198L15.0873 5.45471M17.9346 8.30198L15.0873 11.1493M15.0873 15.4202H6.54547M6.54547 15.4202L9.39275 18.2674M6.54547 15.4202L9.39275 12.5729"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="11.25" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};

export default ExchangeCircle;
