import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import useStore, { authStore } from '../contexts/AuthProvider';
// import { UserRole } from 'src/utils/enum';
// import { useEffect } from 'react';
// import _ from 'lodash';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

const useCurrentRole = () => {
  const { role } = useStore();
  return role;
};

const SingleTimeBusinessCreateGuard = ({ children }) => {
  const hasBusiness = authStore((state) => state.hasBusiness);
  if (hasBusiness) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Business create limit exceed</AlertTitle>
          You can create business only one time
        </Alert>
      </Container>
    );
  }
  return <>{children}</>;
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles?.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}

export { SingleTimeBusinessCreateGuard };
