import React from 'react';
import { Link } from '@mui/material';
import LogoWithText from 'src/svgComponents/LogoWithText';

const Logo = () => {
  return (
    <div className="logo_container">
      <Link href="/home" className="logo_link">
        <LogoWithText />
      </Link>
    </div>
  );
};

export default Logo;
