import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  Rating,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import files, { helperMessageArray } from 'src/helpers/helpers';
import { USER_API_URL, INDIVIDUAL_API_URL, CHAT_API_URL } from 'src/api/axios';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EarthIcon from 'src/svgComponents/EarthIcon';
import { useLocation, useNavigate } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import LockIcon from 'src/svgComponents/LockIcon';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {
  BUSINESS_API_URL,
  EMPLOYEE_API_URL,
  SOCIAL_MEDIA_URL,
} from 'src/api/axios';
import AppTooltip from '../common/AppTooltip';
import ChatFilled from 'src/svgComponents/ChatFilled';
import BeemzLocationIcon from 'src/svgComponents/BeemzLocationIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import userPlacholder from '../../assets/images/user_placeholder.png';
import { authStore } from 'src/contexts/AuthProvider';
import { UserRole } from 'src/utils/enum';
import { PATH_AUTH, PATH_CHAT } from 'src/routes/paths';
import { useSnackbar } from 'notistack';

const MapFeedItem = (props) => {
  const { feedData, handleViewProfile, t } = props;
  const { businessId: profileId } = authStore((state) => state.user);
  const [imageError, setImageError] = useState(false);
  const role = authStore((state) => state.role);
  const setIsGuestContinue = authStore((state) => state.setIsGuestContinue);
  const [helpPopupOpen, setHelpPopupOpen] = useState(false);
  const [isPartOfGroup, setIsPartOfGroup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const navigate = useNavigate();
  const otherUserId = state?.otherUserBusinessId;
  const otherBusinessAddressId = state?.otherBusinessAddressId;
  // const otherUserId = state?.otherUserBusinessId;
  const axiosPrivate = useAxiosPrivate();

  // Adds redirection to see the user's locations on the google map.

  const handleDirection = () => {
    if (feedData?.permissions?.location?.notShared) {
      enqueueSnackbar(props.t('toast_message.give_location_permission'), {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
      });
    } else {
      const { coordinates } = feedData?.location;
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${coordinates[1]},${coordinates[0]}`
      );
    }
  };
  const { data: fetchChatCategory, refetch: fetcCat } = useQuery(
    'chatCatList',
    async ({ signal }) => {
      try {
        const response = await axiosPrivate.get(CHAT_API_URL.chatCategory, {
          signal,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching chat categories:', error);
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
  const { data: feedDataa, refetch: userProfilePageData } = useQuery(
    ['userProfilePageData', { otherUserId, profileId }],
    () => fetchUser({ otherUserId, profileId }),
    { refetchOnWindowFocus: false }
  );

  // // Accessing categoryId from userData safely
  // const chatCategoryId = feedData && feedData.category && feedData.category._id;
  // const filterCategory = fetchChatCategory?.filter((item) => {
  //   // Checking if item's ID matches the chat category ID
  //   const cat = item?._id === chatCategoryId;
  //   return cat;
  // });
  // Check if feedData exists and has a category property before accessing its _id

  // Accessing categoryId from userData safely
  const chatCategoryId = feedData?.category?._id; // Use optional chaining to safely access nested properties
  const filterCategory = fetchChatCategory?.filter((item) => {
    // Use optional chaining to safely access _id property of item
    return item?._id === chatCategoryId;
  });

  useEffect(() => {
    if (!_.isEmpty(feedData?.members) || !_.isEmpty(feedData?.admins)) {
      const groupMembers = [...feedData?.members, ...feedData?.admins];
      // Check if the current user (profileId) is part of the group
      const isUserPartOfGroup = groupMembers.some((k) => k._id === profileId);

      setIsPartOfGroup(isUserPartOfGroup);
    }
  }, [feedData?.members, feedData?.admins, profileId]);

  const HelpMessage = ({
    onClose,
    open,
    sendQuery,
    isEmployee,
    selectMessgeSub,
    personalMesBtn,
  }) => {
    return (
      <Dialog onClose={onClose} open={open} className="help_popup_wrapper">
        <DialogTitle className="px-0 pt-0 position-relative">
          <span className="app_text_20_semibold app_text_black app_text_transform">
            {selectMessgeSub}
          </span>
        </DialogTitle>
        <DialogContent sx={{ p: 0, mt: 1 }}>
          <List sx={{ p: 0, width: '%', '& li': { px: 0, py: 2 } }}>
            {filterCategory?.map((cat, index) =>
              cat?.child?.map((child, childIndex) => (
                <ListItemButton
                  key={childIndex}
                  onClick={() => sendQuery('STEP_2', child.name, isEmployee)}
                >
                  {child.name}
                </ListItemButton>
              ))
            )}
          </List>
        </DialogContent>
        {/* <DialogContent sx={{ p: 0, mt: 1 }}>
          <List sx={{ p: 0, width: "100%", "& li": { px: 0, py: 2 } }}>
            {helperMessageArray.map((i, index) => (
              <ListItemButton
                key={index}
                onClick={() => sendQuery("STEP_2", i, isEmployee)}
              >
                {i}
              </ListItemButton>
            ))}
          </List>
        </DialogContent> */}
      </Dialog>
    );
  };
  const handleLinkClick = (ev) => {
    if (role === UserRole.GUEST) {
      setIsGuestContinue(true);
      ev.preventDefault();
      return false;
    }
  };
  const helpPopupOnOpen = () => {
    setHelpPopupOpen(true);
  };

  const helpPopupOnClose = () => {
    setHelpPopupOpen(false);
  };

  const { mutateAsync: userFollow } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        SOCIAL_MEDIA_URL.userFollow,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: (res) => {
        userProfilePageData();
        // fetchPostsData();
      },
      onError: (error) => {},
    }
  );

  const sendQuerytoBusiness = (step, helpMessage, isEmployee = false) => {
    if (step === 'STEP_1') {
      helpPopupOnOpen(); //open model
    }
    if (step === 'STEP_2') {
      if (helpMessage === 'personal') {
        navigate(`${PATH_CHAT.root}/${feedData.userId}`);
      } else {
        const obj = { helpMessage };
        if (isEmployee) {
          obj.employeeId = feedData.userId;
          navigate(
            `${PATH_CHAT.root}/business/${feedData?.employee?.businessId}`,
            { state: obj }
          );
        } else {
          navigate(
            `${PATH_CHAT.root}/business/${feedData?.id}/${feedData.category._id}`,
            {
              state: obj,
            }
          );
        }
      }
    }
  };
  const googleChat = () => {
    enqueueSnackbar(props.t('toast_message.google_search_result'), {
      variant: 'error',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 3000,
    });
  };

  const googleAddress = () => {
    window.open(`https://www.google.com/maps/place/${feedData?.address}`);
  };

  const handleGuestLogin = () => {
    if (role === UserRole.GUEST) {
      navigate(PATH_AUTH.login);
    }
  };

  const handleGroupChat = () => {
    const groupChatUrl = `${PATH_CHAT.root}/group/${feedData?._id}`;

    if (isPartOfGroup) {
      // If the user is part of the group, navigate to the group's chat page
      navigate(groupChatUrl);
    } else {
      // If the user is not part of the group, navigate to the chat page with a state indicating they need to join
      navigate(groupChatUrl, { state: { isJoin: true } });
    }
  };

  // Assuming this is the data structure returned by the API
  /*
  {
    ...
    categoryId: "someCategoryId",
    ...
  }
  */
  // Update the fetchUser function to properly extract categoryId
  const fetchUser = async ({ otherUserId, profileId }, signal) => {
    if (otherUserId) {
      try {
        const response = await axiosPrivate.get(
          BUSINESS_API_URL.otherProfileNotParam + otherUserId,
          {
            signal,
            params: { addressId: otherBusinessAddressId },
          }
        );
        const userData = response.data;
        const categoryId = userData?.categoryId; // Extract categoryId from the response data
        return { ...userData, categoryId }; // Merge categoryId into the userData object
      } catch (error) {
        console.error(
          'Error fetching user data----------------------------------------------------:',
          error
        );
        return {}; // Return empty object if there's an error
      }
    }
  };

  return (
    <>
      {feedData?.isBusiness ? (
        <div
          className="map_feed_item_new_listitem mb-3"
          onDoubleClick={handleGuestLogin}
        >
          <Box
            className="map_feed_item_new_card"
            style={{ background: 'rgba(153, 243, 255, 0.35)' }}
          >
            <Box className="map_feed_item_new_img_wrapper">
              {!_.isEmpty(feedData?.image) ? (
                <img
                  src={files(feedData?.image, 'image')}
                  alt="user"
                  onError={() => setImageError(true)}
                />
              ) : (
                <img src={userPlacholder} alt="user" />
              )}
            </Box>
            <Box className="">
              <span className="app_text_18_semibold text_limit_200 d-block mb-1">
                {feedData?.name ?? ''}
              </span>
              <span className="app_text_14_500 app_text_gray text_limit_200 d-block mb-1">
                {feedData?.userStatus ?? ''}
              </span>
              {feedData?.email && (
                <span className="app_text_14_500 app_text_black_2 text_limit_200 d-block mb-1">
                  {`${t('comman_label.email')} : ${feedData?.email ?? ''}`}
                </span>
              )}
              <span className="app_text_14_500 app_text_black_2 text_limit_200 d-block mb-1">
                {feedData?.address ?? ''}
              </span>
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                  <FmdGoodIcon sx={{ height: '18px', width: '18px' }} />
                </ListItemIcon>
                <span className="app_text_14_semibold text_limit_200 d-block">{`${
                  feedData?.distance ? feedData?.distance : 0
                } ${t('comman_label.km_away')}`}</span>
              </span>
              <Box
                className="mt-1"
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <AppTooltip
                  title={props.t('tooltip_text.business_chat')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#00d1ee' }}
                    onClick={(ev) =>
                      role === UserRole.GUEST
                        ? handleLinkClick(ev)
                        : sendQuerytoBusiness('STEP_1', null)
                    }
                  >
                    {' '}
                    <ChatFilled color={'#dbfbff'} size={18} />
                  </Button>
                </AppTooltip>
                <AppTooltip
                  title={props.t('tooltip_text.direation')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#00d1ee' }}
                    onClick={handleDirection}
                  >
                    <BeemzLocationIcon color={'#dbfbff'} size={18} />
                  </Button>
                </AppTooltip>
                <AppTooltip
                  title={props.t('tooltip_text.view_profile')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#00d1ee' }}
                    onClick={(ev) => {
                      role === UserRole.GUEST
                        ? handleLinkClick(ev)
                        : handleViewProfile(ev, {
                            isBusiness: true,
                            addressId: feedData?.addressId,
                            dataId: feedData?.id,
                          });
                    }}
                  >
                    <VisibilityIcon style={{ color: '#dbfbff' }} />
                  </Button>
                </AppTooltip>
              </Box>
            </Box>
          </Box>
        </div>
      ) : feedData?.isGroup ? (
        <div
          className="map_feed_item_new_listitem mb-3"
          onDoubleClick={handleGuestLogin}
        >
          <Box
            className="map_feed_item_new_card"
            style={{ background: 'rgba(177, 198, 229, 0.35)' }}
          >
            <Box className="map_feed_item_new_img_wrapper">
              {!_.isEmpty(feedData?.image) ? (
                <img
                  src={files(feedData?.image, 'image')}
                  alt="user"
                  onError={() => setImageError(true)}
                />
              ) : (
                <img src={userPlacholder} alt="user" />
              )}
            </Box>
            <Box className="">
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                  {feedData?.private ? (
                    <LockIcon sx={{ height: '20px', width: '20px' }} />
                  ) : (
                    <EarthIcon sx={{ height: '20px', width: '20px' }} />
                  )}
                </ListItemIcon>
                <span className="app_text_18_semibold text_limit_200 d-block">
                  {feedData?.name ?? ''}
                </span>
              </span>
              <span className="app_text_14_500 app_text_gray text_limit_200 d-block mb-1">
                {feedData?.description ?? ''}
              </span>
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                  <FmdGoodIcon sx={{ height: '18px', width: '18px' }} />
                </ListItemIcon>
                <span className="app_text_14_semibold text_limit_200 d-block">{`${
                  feedData?.distance ? feedData?.distance : 0
                } ${t('comman_label.km_away')}`}</span>
              </span>
              <Box
                className="mt-1"
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <AppTooltip
                  title={props.t('tooltip_text.chat')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className="map_sidebar_card_btn_sm"
                    style={{ background: '#efe5fd' }}
                    onClick={handleGroupChat}
                  >
                    <ChatFilled color="#6500f2" size={18} />
                  </Button>
                </AppTooltip>
                <AppTooltip
                  title={props.t('tooltip_text.view_group')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#efe5fd' }}
                    onClick={(ev) => {
                      role === UserRole.GUEST
                        ? handleLinkClick(ev)
                        : handleViewProfile(ev, {
                            isGroup: true,
                            groupData: feedData,
                          });
                    }}
                  >
                    <VisibilityIcon style={{ color: '#6500f2' }} />
                  </Button>
                </AppTooltip>
              </Box>
            </Box>
          </Box>
        </div>
      ) : feedData?.location?.type === 'geo' ? (
        <div
          className="map_feed_item_new_listitem mb-3"
          onDoubleClick={handleGuestLogin}
        >
          <Box
            className="map_feed_item_new_card"
            style={{ background: 'rgba(217, 240, 222, 0.4)' }}
          >
            <Box className="map_feed_item_new_img_wrapper">
              {!_.isEmpty(feedData?.image) ? (
                <img
                  src={files(feedData?.image, 'image')}
                  alt="user"
                  onError={() => setImageError(true)}
                />
              ) : (
                <img src={userPlacholder} alt="user" />
              )}
            </Box>
            <Box className="">
              <span className="app_text_18_semibold text_limit_200 d-block mb-1">
                {feedData?.name ?? ''}
              </span>
              {feedData?.phoneData && (
                <span className="app_text_14_500 app_text_black_2 text_limit_200 d-block mb-1">
                  {`${props.t('home_module.mo_number')} : ${
                    feedData?.phoneData
                  }`}
                </span>
              )}
              <span className="app_text_14_500 app_text_black_2 text_limit_200 d-block mb-1">
                {feedData?.address ?? ''}
              </span>
              <Box
                className="mt-1"
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <AppTooltip
                  title={props.t('tooltip_text.chat')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#34a853' }}
                    onClick={googleChat}
                  >
                    <ChatFilled color={'#fff'} size={18} />
                  </Button>
                </AppTooltip>
                <AppTooltip
                  title={props.t('tooltip_text.direation')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#34a853' }}
                    onClick={handleDirection}
                  >
                    <BeemzLocationIcon color={'#fff'} size={18} />
                  </Button>
                </AppTooltip>
                <AppTooltip
                  title={props.t('tooltip_text.view_profile')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#34a853' }}
                    onClick={googleAddress}
                  >
                    <FmdGoodIcon style={{ color: '#fff', fontSize: '18px' }} />
                  </Button>
                </AppTooltip>
              </Box>
            </Box>
          </Box>
        </div>
      ) : !_.isEmpty(feedData?.employee) ? (
        <div
          className="map_feed_item_new_listitem mb-3"
          onDoubleClick={handleGuestLogin}
        >
          <Box
            className="map_feed_item_new_card"
            style={{ background: 'rgba(153, 206, 255, 0.35)' }}
          >
            <Box className="map_feed_item_new_img_wrapper">
              {!_.isEmpty(feedData?.profilePic) ? (
                <img
                  src={files(feedData?.profilePic, 'image')}
                  alt="user"
                  onError={() => setImageError(true)}
                />
              ) : (
                <img src={userPlacholder} alt="user" />
              )}
            </Box>
            <Box className="">
              <span className="app_text_18_semibold text_limit_200 d-block mb-1">
                {feedData?.fullName ?? ''}
              </span>
              {feedData?.permissions?.visibility?.status && (
                <span className="app_text_14_500 app_text_gray text_limit_200 d-block mb-1">
                  {feedData?.userStatus ?? ''}
                </span>
              )}
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <span className="app_text_14_500 app_text_black_2 me-1 app_text_transform">
                  {props.t('comman_label.trust_level')}
                </span>
                <Rating
                  size="small"
                  name="read-only"
                  max={5}
                  precision={0.5}
                  value={feedData?.averageTrust ?? 0}
                  readOnly
                />
              </span>
              {!_.isEmpty(feedData?.employee?.designation) &&
                feedData?.permissions?.visibility?.designation && (
                  <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                    <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                      <BusinessCenterIcon
                        sx={{ height: '18px', width: '18px' }}
                      />
                    </ListItemIcon>

                    <span className="app_text_14_500 text_limit_200 d-block">{`${feedData?.employee?.businessName}
                     (${feedData?.employee?.designation})`}</span>
                  </span>
                )}
              {(feedData?.permissions?.location?.withPublic ||
                (feedData?.permissions?.location?.withLinkedContact &&
                  feedData?.status === 3)) &&
                !feedData?.permissions?.location?.whileUsingApp &&
                !feedData?.permissions?.location?.notShared && (
                  <span className="app_text_14_500 app_text_black_2 text_limit_200 d-block mb-1">
                    {feedData?.address ?? ''}
                  </span>
                )}
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                  <FmdGoodIcon sx={{ height: '18px', width: '18px' }} />
                </ListItemIcon>
                <span className="app_text_14_semibold text_limit_200 d-block">{`${
                  feedData?.distance ? feedData?.distance : 0
                } ${t('comman_label.km_away')}`}</span>
              </span>
              <Box
                className="mt-1"
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {feedData?.permissions?.acceptMessage?.public ||
                (feedData?.permissions?.acceptMessage?.contact &&
                  feedData?.selfAsContact) ||
                feedData?.permissions?.location?.withPublic ? (
                  <AppTooltip
                    title={props.t('tooltip_text.chat')}
                    placement={'bottom'}
                  >
                    <Button
                      variant="contained"
                      className={`map_sidebar_card_btn_sm`}
                      style={{ background: '#007cee' }}
                      onClick={(ev) =>
                        role === UserRole.GUEST
                          ? handleLinkClick(ev)
                          : sendQuerytoBusiness('STEP_1', null, true)
                      }
                    >
                      <ChatFilled color={'#dbeeff'} size={18} />
                    </Button>
                  </AppTooltip>
                ) : (
                  <></>
                )}
                {(feedData?.permissions?.location?.withPublic ||
                  (feedData?.permissions?.location?.withLinkedContact &&
                    feedData?.status === 3)) &&
                !feedData?.permissions?.location?.whileUsingApp &&
                !feedData?.permissions?.location?.notShared &&
                feedData?.permissions?.visibility.enableNavigation ? (
                  <AppTooltip
                    title={props.t('tooltip_text.direation')}
                    placement={'bottom'}
                  >
                    <Button
                      variant="contained"
                      className={`map_sidebar_card_btn_sm`}
                      style={{ background: '#007cee' }}
                      onClick={handleDirection}
                    >
                      <BeemzLocationIcon color={'#dbeeff'} size={18} />
                    </Button>
                  </AppTooltip>
                ) : (
                  <></>
                )}
                <AppTooltip
                  title={props.t('tooltip_text.view_profile')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#007cee' }}
                    onClick={(ev) => {
                      role === UserRole.GUEST
                        ? handleLinkClick(ev)
                        : handleViewProfile(ev, {
                            isUser: true,
                            dataId: feedData?.userId,
                          });
                    }}
                  >
                    <VisibilityIcon style={{ color: '#dbeeff' }} />
                  </Button>
                </AppTooltip>
              </Box>
            </Box>
          </Box>
        </div>
      ) : (
        <div
          className="map_feed_item_new_listitem mb-3"
          onDoubleClick={handleGuestLogin}
        >
          <Box className="map_feed_item_new_card">
            <Box className="map_feed_item_new_img_wrapper">
              {!_.isEmpty(feedData?.profilePic) &&
              feedData?.permissions?.visibility?.picture ? (
                <img
                  src={files(feedData?.profilePic, 'image')}
                  alt="user"
                  onError={() => setImageError(true)}
                />
              ) : (
                <img src={userPlacholder} alt="user" />
              )}
            </Box>
            <Box className="">
              <span className="app_text_18_semibold text_limit_200 d-block mb-1">
                {feedData?.fullName ?? ''}
              </span>
              {feedData?.permissions?.visibility?.status && (
                <span className="app_text_14_500 app_text_gray text_limit_200 d-block mb-1">
                  {feedData?.userStatus ?? ''}
                </span>
              )}
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <span className="app_text_14_500 app_text_black_2 me-1 app_text_transform">
                  {props.t('comman_label.trust_level')}
                </span>
                <Rating
                  size="small"
                  name="read-only"
                  max={5}
                  precision={0.5}
                  value={feedData?.averageTrust ?? 0}
                  readOnly
                />
              </span>
              {(feedData?.permissions?.location?.withPublic ||
                (feedData?.permissions?.location?.withLinkedContact &&
                  feedData?.status === 3)) &&
                !feedData?.permissions?.location?.whileUsingApp &&
                !feedData?.permissions?.location?.notShared && (
                  <span className="app_text_14_500 app_text_black_2 text_limit_200 d-block mb-1">
                    {feedData?.address ?? ''}
                  </span>
                )}
              <span className="d-flex align-items-center app_text_black_2 d-block mb-1">
                <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
                  <FmdGoodIcon sx={{ height: '18px', width: '18px' }} />
                </ListItemIcon>
                <span className="app_text_14_semibold text_limit_200 d-block">{`${
                  feedData?.distance ? feedData?.distance : 0
                } ${t('comman_label.km_away')}`}</span>
              </span>
              <Box
                className="mt-1"
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {feedData?.permissions?.acceptMessage?.public ||
                (feedData?.permissions?.acceptMessage?.contact &&
                  feedData?.selfAsContact) ||
                feedData?.permissions?.location?.withPublic ? (
                  <AppTooltip
                    title={props.t('tooltip_text.chat')}
                    placement={'bottom'}
                  >
                    <Button
                      variant="contained"
                      className={`map_sidebar_card_btn_sm`}
                      style={{ background: '#efe5fd' }}
                      onClick={(ev) =>
                        role === UserRole.GUEST
                          ? handleLinkClick(ev)
                          : navigate(`${PATH_CHAT.root}/${feedData.userId}`)
                      }
                    >
                      <ChatFilled color={'#6500f2'} size={18} />
                    </Button>
                  </AppTooltip>
                ) : (
                  <></>
                )}
                {(feedData?.permissions?.location?.withPublic ||
                  (feedData?.permissions?.location?.withLinkedContact &&
                    feedData?.status === 3)) &&
                !feedData?.permissions?.location?.whileUsingApp &&
                !feedData?.permissions?.location?.notShared &&
                feedData?.permissions?.visibility.enableNavigation ? (
                  <AppTooltip
                    title={props.t('tooltip_text.direation')}
                    placement={'bottom'}
                  >
                    <Button
                      variant="contained"
                      className={`map_sidebar_card_btn_sm`}
                      style={{ background: '#efe5fd' }}
                      onClick={handleDirection}
                    >
                      <BeemzLocationIcon color={'#6500f2'} size={18} />
                    </Button>
                  </AppTooltip>
                ) : (
                  <></>
                )}
                <AppTooltip
                  title={props.t('tooltip_text.view_profile')}
                  placement={'bottom'}
                >
                  <Button
                    variant="contained"
                    className={`map_sidebar_card_btn_sm`}
                    style={{ background: '#efe5fd' }}
                    onClick={(ev) => {
                      role === UserRole.GUEST
                        ? handleLinkClick(ev)
                        : handleViewProfile(ev, {
                            isUser: true,
                            dataId: feedData?.userId,
                          });
                    }}
                  >
                    <VisibilityIcon style={{ color: '#6500f2' }} />
                  </Button>
                </AppTooltip>
              </Box>
            </Box>
          </Box>
        </div>
      )}
      <HelpMessage
        onClose={helpPopupOnClose}
        open={helpPopupOpen}
        sendQuery={sendQuerytoBusiness}
        isEmployee={!!feedData?.employee?.businessId}
        selectMessgeSub={props.t('popup_title.select_message_subject')}
        personalMesBtn={props.t('popup_btn.personal_message')}
      />
    </>
  );
};

export default withTranslation()(MapFeedItem);
