import React from 'react';

const WelcomeIcon = () => {
  return (
    <svg
      width="158"
      height="158"
      viewBox="0 0 158 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="79" cy="79" r="79" fill="#F2E9FF" />
      <path
        d="M11.8242 79.0326C11.8242 42.1868 41.6937 12.3174 78.5395 12.3174C115.385 12.3174 145.255 42.1868 145.255 79.0326C145.255 115.878 115.385 145.748 78.5395 145.748C41.6937 145.748 11.8242 115.878 11.8242 79.0326Z"
        fill="white"
      />
      <rect
        width="27.16"
        height="11.154"
        transform="matrix(0.867368 0.497667 -0.474875 0.880053 71.6914 55.7246)"
        fill="#BB86FC"
      />
      <rect
        width="28.9271"
        height="11.1824"
        transform="matrix(0.903801 -0.427954 0.408341 0.91283 63.2109 96.7334)"
        fill="#BB86FC"
      />
      <circle cx="57.4807" cy="103.614" r="16.4221" fill="#BB86FC" />
      <circle cx="102.957" cy="82.8557" r="16.4221" fill="#BB86FC" />
      <path
        d="M74.8452 47.8214C78.953 55.9075 75.7279 65.7926 67.6418 69.9004C59.5556 74.0081 49.6705 70.783 45.5628 62.6969C41.455 54.6108 44.6801 44.7257 52.7662 40.6179C60.8524 36.5101 70.7375 39.7352 74.8452 47.8214Z"
        fill="#BB86FC"
      />
    </svg>
  );
};

export default WelcomeIcon;
