import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from 'src/svgComponents/SearchIcon';
import PerentReqRow from './PerentReqRow';
import { withTranslation } from 'react-i18next';

const PerentRequestPopup = (props) => {
  const { onClose, open, PerentRequestData } = props;
  const [searchData, setSerchData] = useState('');
  const handleClose = () => {
    onClose();
  };
  const handleSearch = (e) => {
    setSerchData(e.target.value.trim());
  };
  return (
    <Dialog onClose={handleClose} open={open} className="request_popup_wrapper">
      <DialogTitle className="px-0 pt-0">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.parent_guardian_requests')}
        </span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {PerentRequestData && PerentRequestData.length > 0 ? (
        <>
          <Paper className="linking_popup_searchbox">
            <IconButton style={{ marginTop: '-2px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={props.t('comman_label.search')}
              onChange={handleSearch}
            />
          </Paper>
          <div className="mt-2">
            <span className="app_text_gray app_text_14">
              {props.t('popup_message.confirmation_parent_restriction')}
            </span>
          </div>
          <List className="linkList mt-3" sx={{ pt: 0 }}>
            {PerentRequestData.filter((user) =>
              user?.fullName.toLowerCase().includes(searchData.toLowerCase())
            ).map((user, index) => (
              <PerentReqRow
                key={index}
                userId={user._id}
                fullName={user.fullName}
                userName={user.userName}
                userPic={user?.image?.profilePic}
              />
            ))}
          </List>
        </>
      ) : (
        <span className="app_text_20_semibold app_text_transform">
          {props.t('popup_message.no_parent_requests')}
        </span>
      )}
    </Dialog>
  );
};

PerentRequestPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(PerentRequestPopup);
