import { Rating } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';

const TrustLevelReadOnly = ({ rating, maxStar, t }) => {
  return (
    <span className="d-flex align-items-center">
      <span className="app_text_12 app_text_gray me-1 app_text_transform">
        {t('comman_label.trust_level')}
      </span>
      <Rating
        size="small"
        name="read-only"
        max={maxStar ? maxStar : 5}
        precision={0.5}
        value={rating ? rating : 0}
        readOnly
      />
    </span>
  );
};

export default withTranslation()(TrustLevelReadOnly);
