import React from 'react';

const Intersted = ({ size, color }) => {
  return (
    <svg
      width={size ? size : '18px'}
      height={size ? size : '18px'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44373 8.59027C2.19866 8.59027 2 8.78897 2 9.03401V15.5513C2 15.7964 2.19869 15.9951 2.44373 15.9951H5.11716V8.59027H2.44373ZM16.9886 11.0406C16.9886 10.4773 16.6859 9.9834 16.2347 9.71287C16.4136 9.4549 16.5093 9.14836 16.509 8.83441C16.509 7.98149 15.8151 7.28756 14.9621 7.28756H11.6968C11.7968 6.83404 11.9337 6.15351 12.029 5.4411C12.277 3.58716 12.1075 2.55899 11.4956 2.2054C11.1141 1.985 10.7028 1.9403 10.3374 2.0794C10.0551 2.18692 9.6739 2.45126 9.45667 3.10004L8.59845 5.34714C8.16336 6.42166 6.83097 7.54974 6.00465 8.1735V16.1914C7.53611 16.728 9.1342 17 10.7646 17H14.3226C15.1755 17 15.8694 16.3061 15.8694 15.4532C15.8698 15.1652 15.7893 14.883 15.6371 14.6386C16.1527 14.3875 16.5089 13.8579 16.5089 13.2469C16.5093 12.9329 16.4136 12.6264 16.2346 12.3684C16.6859 12.0979 16.9886 11.6039 16.9886 11.0406Z"
        fill={color ?? '#fff'}
      />
    </svg>
  );
};

export default Intersted;
