import React from 'react';

const DeleteBusinessIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <g clipPath="url(#clip0_31400_24644)">
        <path
          d="M25.75 17.5H29.5V19H28V28.75C28 28.9489 27.921 29.1397 27.7803 29.2803C27.6397 29.421 27.4489 29.5 27.25 29.5H16.75C16.5511 29.5 16.3603 29.421 16.2197 29.2803C16.079 29.1397 16 28.9489 16 28.75V19H14.5V17.5H18.25V15.25C18.25 15.0511 18.329 14.8603 18.4697 14.7197C18.6103 14.579 18.8011 14.5 19 14.5H25C25.1989 14.5 25.3897 14.579 25.5303 14.7197C25.671 14.8603 25.75 15.0511 25.75 15.25V17.5ZM19.75 21.25V25.75H21.25V21.25H19.75ZM22.75 21.25V25.75H24.25V21.25H22.75ZM19.75 16V17.5H24.25V16H19.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31400_24644">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteBusinessIcon;
