const ChatIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size ? size : '24'}
      height={size ? size : '24'}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M10 3h4a8 8 0 1 1 0 16v3.5c-5-2-12-5-12-11.5a8 8 0 0 1 8-8zm2 14h2a6 6 0 1 0 0-12h-4a6 6 0 0 0-6 6c0 3.61 2.462 5.966 8 8.48V17z"
        fill="#fff"
      />
    </svg>
  );
};

export default ChatIcon;
