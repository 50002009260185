import React from 'react';

const TrackTraceIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <g clipPath="url(#clip0_29340_21082)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9999 13C22.2147 13 22.3888 13.1741 22.3888 13.3889V15.9894C25.3872 16.2014 27.7797 18.6071 27.9711 21.6112H30.6111C30.8259 21.6112 31 21.7853 31 22.0001C31 22.2149 30.8259 22.389 30.6111 22.389H27.971C27.7796 25.393 25.3871 27.7986 22.3888 28.0106V30.6111C22.3888 30.8259 22.2147 31 21.9999 31C21.7851 31 21.611 30.8259 21.611 30.6111V28.016C18.5732 27.8437 16.1374 25.4212 15.9442 22.389H13.3889C13.1741 22.389 13 22.2149 13 22.0001C13 21.7853 13.1741 21.6112 13.3889 21.6112H15.9441C16.1373 18.5789 18.5731 16.1563 21.611 15.984V13.3889C21.611 13.1741 21.7851 13 21.9999 13ZM21.9577 24.871C23.5432 24.871 24.8285 23.5857 24.8285 22.0002C24.8285 20.4148 23.5432 19.1295 21.9577 19.1295C20.3723 19.1295 19.087 20.4148 19.087 22.0002C19.087 23.5857 20.3723 24.871 21.9577 24.871Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21082">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrackTraceIcon;
