import React from 'react';

const LocationLogIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#FF5E4B" />
      <g clipPath="url(#clip0_29340_21077)">
        <path
          d="M14.5 16.75L19.75 14.5L24.25 16.75L28.9772 14.7243C29.0343 14.6998 29.0966 14.6899 29.1584 14.6954C29.2202 14.7009 29.2797 14.7217 29.3316 14.7559C29.3834 14.7901 29.4259 14.8367 29.4553 14.8913C29.4847 14.946 29.5001 15.0072 29.5 15.0693V27.25L24.25 29.5L19.75 27.25L15.0228 29.2758C14.9657 29.3002 14.9034 29.3101 14.8416 29.3046C14.7798 29.2991 14.7203 29.2783 14.6684 29.2441C14.6166 29.2099 14.5741 29.1633 14.5447 29.1087C14.5153 29.054 14.4999 28.9928 14.5 28.9307V16.75ZM24.25 27.823V18.382L24.2013 18.403L19.75 16.177V25.618L19.7987 25.597L24.25 27.823Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21077">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationLogIcon;
