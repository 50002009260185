import React from 'react';

const FastMarketingIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <path
        d="M29.4204 20.4733L22.4001 13.452C21.9861 13.039 21.3163 13.0439 20.9077 13.4515C20.4968 13.8635 20.4968 14.5325 20.9077 14.9439L27.929 21.9647C28.3403 22.3761 29.009 22.3761 29.4204 21.9647C29.8328 21.5523 29.8328 20.8857 29.4204 20.4733Z"
        fill="white"
      />
      <path
        d="M20.3868 15.9146L20.334 16.1794C19.9618 18.0412 19.0572 19.81 17.8337 21.2435L21.6534 25.0632C23.0868 23.8399 24.8311 22.9111 26.693 22.5384L26.9584 22.4856L20.3868 15.9146Z"
        fill="white"
      />
      <path
        d="M17.1335 22.0347L14.523 24.6446C13.9062 25.2614 13.9036 26.2628 14.523 26.8822L16.0144 28.3736C16.6312 28.9904 17.6326 28.9929 18.252 28.3736L20.8622 25.7634L17.1335 22.0347ZM17.5063 26.1365C17.3004 26.3425 16.9666 26.3425 16.7606 26.1365C16.5547 25.9305 16.5547 25.5968 16.7606 25.3908L17.5063 24.6451C17.7123 24.4391 18.0461 24.4391 18.252 24.6451C18.458 24.8511 18.458 25.1848 18.252 25.3908L17.5063 26.1365Z"
        fill="white"
      />
      <path
        d="M23.064 28.6544L23.7814 27.937C24.4008 27.3177 24.3988 26.3163 23.7814 25.7L23.2486 25.1667C22.9541 25.3646 22.6728 25.5795 22.3994 25.8089L23.0357 26.4462C23.2412 26.6506 23.2427 26.9833 23.0357 27.1913L22.3065 27.9205L21.2349 26.8823L19.7434 28.3737L22.136 30.6922C22.5443 31.1015 23.2147 31.1039 23.6269 30.6917C24.0384 30.2802 24.0384 29.6112 23.6269 29.1997L23.064 28.6544Z"
        fill="white"
      />
      <path
        d="M25.1945 13C24.903 13 24.6671 13.2359 24.6671 13.5273V14.582C24.6671 14.8735 24.903 15.1094 25.1945 15.1094C25.4859 15.1094 25.7218 14.8735 25.7218 14.582V13.5273C25.7218 13.2359 25.4859 13 25.1945 13Z"
        fill="white"
      />
      <path
        d="M29.4132 17.2188H28.3585C28.067 17.2188 27.8312 17.4546 27.8312 17.7461C27.8312 18.0376 28.067 18.2734 28.3585 18.2734H29.4132C29.7047 18.2734 29.9406 18.0376 29.9406 17.7461C29.9406 17.4546 29.7047 17.2188 29.4132 17.2188Z"
        fill="white"
      />
      <path
        d="M28.7314 14.2092C28.5254 14.0032 28.1916 14.0032 27.9857 14.2092L26.931 15.2639C26.725 15.4698 26.725 15.8036 26.931 16.0096C27.137 16.2155 27.4707 16.2156 27.6767 16.0096L28.7314 14.9549C28.9373 14.7489 28.9373 14.4152 28.7314 14.2092Z"
        fill="white"
      />
    </svg>
  );
};

export default FastMarketingIcon;
