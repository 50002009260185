import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Close from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import ExchangeCircle from 'src/svgComponents/ExchangeCircle';
import SettingCircle from 'src/svgComponents/SettingCircle';
import CheckCircle from 'src/svgComponents/CheckCircle';
import BellCircle from 'src/svgComponents/BellCircle';
import ChatIconWhite from 'src/svgComponents/ChatIconWhite';
import UserAvatar from '../common/UserAvatar';
import { useLocation, useNavigate } from 'react-router';
import { PATH_CHAT } from 'src/routes/paths';
import { UserRole } from 'src/utils/enum';
import { withTranslation } from 'react-i18next';

const ResponsiveMenu = (props) => {
  const {
    userLinkListCount,
    respoMenu,
    userPerentReqListCount,
    onRespoMenuClose,
    storeData,
    files,
    handleLogout,
    onclickSettingIcon,
    role,
    userDataFunc,
    businessListData,
    handleClickReqPopupOpen,
    handleClickRefReqPopupOpen,
    handleClickUserLinkListPopupOpen,
    handleTraceClick,
    newRequest,
    newRefRequest,
    handleClickPerentRequestPopupOpen,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Drawer anchor="right" open={respoMenu} onClose={() => onRespoMenuClose()}>
      <Box className="responsiveMenu_wrapper">
        <div className="respo_menu_header">
          <div className="d-flex">
            <UserAvatar
              diameter={50}
              imgSrc={files(storeData?.user?.profilePic, 'image')}
              alternateSrc={
                // Fixed the page breaking issue here
                storeData?.user?.displayName || storeData?.user?.userName
                  ? Array.from(
                      storeData?.user?.displayName || storeData?.user?.userName
                    )[0]
                  : null
              }
            />
            <div
              className={`d-flex flex-column ms-2 ${
                storeData?.user?.email ? '' : 'justify-content-center'
              }`}
            >
              <span
                className={`text-white ${
                  storeData?.user?.email ? 'app_text_14_500' : 'app_text_16_500'
                }`}
              >
                {storeData?.user?.displayName || storeData?.user?.userName}
              </span>
              <span className="app_text_14 text-white">
                {storeData?.user?.email}
              </span>
            </div>
          </div>
          <IconButton onClick={() => onRespoMenuClose()}>
            <Close style={{ fontSize: 30, color: '#fff' }} />
          </IconButton>
        </div>
        <div className="mt-4">
          {role !== UserRole.GUEST && (
            <Accordion
              className="respoMenu_accordian"
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ListItemIcon>
                  <ExchangeCircle size={30} />
                </ListItemIcon>
                <span className="app_text_16_500 text-white d-flex align-items-center app_text_transform">
                  {props.t('main_navbar_text.switch_account')}
                </span>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#3a2364a1' }}>
                <h4 className="app_text_14_500 text-white app_text_transform">
                  {props.t('main_navbar_text.personal_profile')}
                </h4>
                <Button
                  fullWidth
                  variant="text"
                  className="app_text_14_semibold d-flex align-items-center justify-content-between ps-2 pt-3 pb-2"
                  onClick={() => {
                    userDataFunc();
                    onRespoMenuClose();
                    setTimeout(handleChange('panel1'), 100);
                  }}
                >
                  <div className="app_text_14_semibold d-flex align-items-center justify-content-start">
                    <UserAvatar
                      diameter={30}
                      imgSrc={files(storeData?.user?.profilePic, 'image')}
                      alternateSrc={
                        storeData?.user?.displayName ||
                        storeData?.user?.userName
                      }
                    />
                    <h4 className="app_text_14_500 text-white mb-0 ms-2 text-capitalize text-start text_limit_120">
                      {storeData?.user?.displayName ||
                        storeData?.user?.userName}
                    </h4>
                  </div>
                  {!storeData?.businessId && (
                    <span>
                      <CheckCircleIcon
                        style={{ fontSize: 24, color: '#fff' }}
                      />
                    </span>
                  )}
                </Button>
                <Divider
                  className="my-2 mb-1"
                  style={{ borderColor: '#000' }}
                />
                <h4 className="app_text_14_500 text-white mt-3 app_text_transform app_text_transform">
                  {props.t('main_navbar_text.business_profile')}
                </h4>
                <List>
                  {businessListData && businessListData.length > 0 ? (
                    businessListData.map((business) => (
                      <ListItemButton
                        sx={{ px: 1, py: 2, justifyContent: 'space-between' }}
                        key={business._id}
                        onClick={() => {
                          userDataFunc(business);
                          onRespoMenuClose();
                          setTimeout(handleChange('panel1'), 100);
                        }}
                      >
                        <div className="app_text_14_semibold d-flex align-items-center justify-content-start">
                          <UserAvatar
                            diameter={30}
                            imgSrc={files(business.image, 'image')}
                            alternateSrc={Array.from(business.name)[0]}
                          />
                          <h4 className="app_text_14_500 text-white mb-0 ms-2 text-start text-capitalize text_limit_180">
                            {business.name}
                          </h4>
                        </div>
                        {storeData?.businessId === business._id && (
                          <span>
                            <CheckCircleIcon
                              style={{ fontSize: 24, color: '#fff' }}
                            />
                          </span>
                        )}
                      </ListItemButton>
                    ))
                  ) : (
                    <li className="app_text_12_semibold text-white py-2 d-flex justify-content-center app_text_transform">
                      {props.t('main_navbar_text.no_account')}
                    </li>
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
          {/* {role !== UserRole.GUEST && <Accordion expanded={expanded === 'panel2'} className="respoMenu_accordian" onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <ListItemIcon><CheckCircle size={30} /></ListItemIcon>
                            <span className='app_text_16_500 text-white d-flex align-items-center app_text_transform'>{props.t('main_navbar_text.requests')}</span>
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#3a2364a1' }}>
                            <ul className='request_popover_content_list'>
                            {role === UserRole.USER &&
                                <li><Button disableRipple onClick={() => {
                                    handleClickPerentRequestPopupOpen();
                                    onRespoMenuClose();
                                    setTimeout(handleChange('panel1'), 10)
                                }}
                                    fullWidth className={`${userPerentReqListCount > 0 ? 'app_text_14_semibold newReq' : 'app_text_14'} app_btn_lg respoMenu_req_btn`}>{props.t('main_navbar_text.parent_guardian_requests')}<span className='ms-2'>{`${userPerentReqListCount > 0 ? '(' + newRequest + ')' : ''}`}</span></Button></li>}
                                <li><Button disableRipple onClick={() => {
                                    handleClickRefReqPopupOpen();
                                    onRespoMenuClose();
                                    setTimeout(handleChange('panel1'), 10)
                                }} fullWidth className={`${newRefRequest > 0 ? 'app_text_14_semibold newReq' : 'app_text_14'} app_btn_lg respoMenu_req_btn`}>{props.t('main_navbar_text.reference_requests')}<span className='ms-2'>{`${newRefRequest > 0 ? '(' + newRefRequest + ')' : ''}`}</span></Button></li>
                                {role === UserRole.USER &&
                                <li><Button disableRipple onClick={() => {
                                    handleClickReqPopupOpen();
                                    onRespoMenuClose();
                                    setTimeout(handleChange('panel1'), 10)
                                }}
                                    fullWidth className={`${newRequest > 0 ? 'app_text_14_semibold newReq' : 'app_text_14'} app_btn_lg respoMenu_req_btn`}>{props.t('main_navbar_text.friend_requests')}<span className='ms-2'>{`${newRequest > 0 ? '(' + newRequest + ')' : ''}`}</span></Button></li>}
                                <li><Button disableRipple onClick={() => {
                                    handleClickUserLinkListPopupOpen();
                                    onRespoMenuClose();
                                    setTimeout(handleChange('panel1'), 10)
                                }} fullWidth className={`${userLinkListCount > 0 ? 'app_text_14_semibold newReq' : 'app_text_14'} app_btn_lg respoMenu_req_btn`}>{props.t('main_navbar_text.linked_list')}<span className='ms-2'>{`${userLinkListCount > 0 ? '(' + userLinkListCount + ')' : ''}`}</span></Button></li>
                                <li><Button disableRipple onClick={() => {
                                    handleTraceClick();
                                    onRespoMenuClose();
                                    setTimeout(handleChange('panel1'), 10)
                                }} fullWidth className={`app_text_12 app_btn_lg respoMenu_req_btn`}>{props.t('main_navbar_text.track_trace')}</Button></li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>} */}
          {/* {role !== UserRole.GUEST && <Accordion expanded={expanded === 'panel3'} className="respoMenu_accordian" onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header" */}
          {/* > */}
          {/* <ListItemIcon><BellCircle size={30} /></ListItemIcon> */}
          {/* <span className='app_text_16_500 text-white d-flex align-items-center app_text_transform'>{props.t('main_navbar_text.notifications')}</span> */}
          {/* </AccordionSummary>
                        <AccordionDetails>

                        </AccordionDetails>
                    </Accordion>} */}
          {/* {role !== UserRole.GUEST && <Accordion expanded={expanded === 'panel4'} className="respoMenu_accordian" onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <ListItemIcon><SettingCircle size={30} /></ListItemIcon>
                            <span className='app_text_16_500 text-white d-flex align-items-center app_text_transform'>{props.t('main_navbar_text.settings')}</span>

                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: '#3a2364a1' }}>
                            <Typography className="app_text_transform">{props.t('main_navbar_text.dummy_message_responsive_menu')}</Typography>
                        </AccordionDetails>
                    </Accordion>} */}
          <List className="respoMenu_list">
            {/* {role !== UserRole.GUEST &&
                            <ListItemButton onClick={() => {
                                onclickSettingIcon();
                                onRespoMenuClose();
                            }}>
                                <ListItemIcon><SettingCircle size={30} /></ListItemIcon>
                                <span className='app_text_16_500 text-white d-flex align-items-center app_text_transform'>{props.t('main_navbar_text.settings')}</span>
                            </ListItemButton>
                        } */}
            {/* {(role !== UserRole.GUEST && !pathname.includes('chat')) && <ListItemButton onClick={() => {
                            navigate(PATH_CHAT.root);
                            onRespoMenuClose();
                        }}>
                            <ListItemIcon><ChatIconWhite size={30} /></ListItemIcon>
                            <span className='app_text_16_500 text-white d-flex align-items-center app_text_transform'>{props.t('main_navbar_text.chat')}</span>
                        </ListItemButton>} */}
            <ListItemButton
              onClick={() => {
                handleLogout();
                onRespoMenuClose();
              }}
            >
              <ListItemIcon>
                <LogoutOutlinedIcon style={{ color: '#fff', fontSize: 30 }} />
              </ListItemIcon>
              <span className="app_text_16_500 text-white d-flex align-items-center app_text_transform">
                {props.t('main_navbar_text.logout')}
              </span>
            </ListItemButton>
          </List>
        </div>
      </Box>
    </Drawer>
  );
};

export default withTranslation()(ResponsiveMenu);
