import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { BUSINESS_API_URL } from 'src/api/axios';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import UserAvatar from 'src/components/common/UserAvatar';
import SearchIcon from '@mui/icons-material/Search';
// import DeleteIcon from "src/svgComponents/DeleteIcon";
// import Designation from "src/svgComponents/Designation";
// import files from "src/helpers/helpers";
import { authStore } from 'src/contexts/AuthProvider';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import files from 'src/helpers/helpers';
import LoadingScreen from 'src/components/LoadingScreen';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AppTooltip from 'src/components/common/AppTooltip';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import EmpLeaveReqFilterPopup from 'src/components/user/EmpLeaveReqFilterPopup';
import TrashBin from 'src/svgComponents/sidebarIcons_updated/TrashBin';
import DeleteLeaveReqPopup from 'src/components/user/DeleteLeaveReqPopup';

const BusinessEmpAvailability = (props) => {
  const { businessId } = authStore((state) => state.user);
  const axiosPrivate = useAxiosPrivate();
  const [ids, setIds] = useState();
  const [loding, setLoding] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [filterPopup, setFilterPopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [applayFilterBadge, setApplayFilterBadge] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [empLeaveRqsData, setEmpLeaveRqsData] = useState([]);
  const [empData, setEmpData] = useState(null);
  const { data: empAvailability, refetch } = useQuery(
    'empAvailability',
    async ({ signal }) => {
      return await axiosPrivate
        .get(
          BUSINESS_API_URL.empAvailability,
          { params: { businessId: businessId } },
          { signal }
        )
        .then((res) => res.data);
    },
    { refetchOnWindowFocus: false }
  );

  const empLeaveApproveStatus = async (data, status) => {
    setIds((preState) => ({ _id: data._id, employeeId: data.employeeId }));
    const statusObj = {
      status: status,
    };
    setTimeout(async () => {
      await empLeaveStatus(statusObj);
    }, 100);
  };

  const { mutateAsync: empLeaveStatus } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        BUSINESS_API_URL.empLeaveRequestStatus +
          ids?._id +
          '/' +
          ids?.employeeId,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: (success) => {
        enqueueSnackbar(success.data.message, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
        refetch();
        setOpenModal(false);
      },
    }
  );

  const empLeaveRejectStatus = (data) => {
    setIds((preState) => ({ _id: data._id, employeeId: data.employeeId }));
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const completedSchema = Yup.object().shape({
    reason: Yup.mixed().required(props.t('validation.reason_req')),
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: completedSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      const unavaiableReqObj = {
        cancleReason: values.reason,
        status: 2,
      };

      await empLeaveStatus(unavaiableReqObj);
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  // const Buttons = ({ employeeStatus, empData }) => {
  //    switch (employeeStatus) {
  //       case 0:
  //          return (
  //             <>
  //                <Button
  //                   className="text-capitalize app_text_14_semibold text-white app_bg_primary" variant="contained" onClick={() => empLeaveApproveStatus(empData, 1)}
  //                >
  //                   {props.t('btn_text.accept')}
  //                </Button>
  //                <Button
  //                   className="app_border_primary text-capitalize app_text_14_semibold app_text_primary app_bg_primary_light" variant="outlined" onClick={() => empLeaveRejectStatus(empData, 2)}
  //                >
  //                   {props.t('btn_text.reject')}
  //                </Button>
  //             </>
  //          );
  //       case 1:
  //          return (
  //             <Chip className='app_row_btn app_text_14_semibold app_text_transform' sx={{ minWidth: "110px !important", background: "#00CF73", color: "#fff" }} label="accept" />
  //          );
  //       case 2:
  //          return (
  //             <Chip className='app_row_btn app_text_14_semibold app_text_transform' sx={{ minWidth: "110px !important", background: "#dc4a5d", color: "#fff" }} label="reject" />
  //          );
  //       default:
  //          return <></>;
  //    }
  // };
  const filterPopupClose = () => {
    setFilterPopup(false);
    // setFilterData()
  };
  const filterDataClick = async (data) => {
    setFilterData(data);
  };

  const reset = () => {
    setEmpLeaveRqsData(empAvailability);
    filterPopupClose();
    setFilterData();
  };

  useEffect(() => {
    if (filterData) {
      const filterLeaveRqs = empAvailability?.filter((emp) => {
        emp?.dates.sort();
        const firstDate = emp?.dates[0];
        const lastDate = emp?.dates[emp?.dates?.length - 1];
        return (
          emp.employeeId === filterData?.emp ||
          emp?.businessBranchId === filterData?.branch ||
          emp?.requestStatus === filterData?.leaveStatus ||
          (filterData?.requestDate >= firstDate &&
            filterData?.requestDate <= lastDate)
        );
      });
      setEmpLeaveRqsData(filterLeaveRqs);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const handleDeleteReq = (empData) => {
    setEmpData(empData);
    setOpenDeletePopup(true);
  };
  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  useEffect(() => {
    if (empAvailability?.length > 0 && _.isEmpty(filterData)) {
      setEmpLeaveRqsData(empAvailability);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empAvailability]);
  useEffect(() => {
    setTimeout(() => {
      setLoding(false);
    }, 2000);
  }, [empAvailability]);

  return (
    <>
      {loding ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-6">
                <Box className="col-sm-6">
                  <Paper className="social_search_box mb-3">
                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1,
                        fontFamily: 'Google Sans',
                        lineHeight: 1,
                      }}
                      placeholder="Search employee..."
                      inputProps={{
                        'aria-label': 'Search employee...',
                      }}
                      onChange={(e) => setSearchValue(e.target.value.trim())}
                      // onKeyDown={(ev) => (ev.keyCode === 13 ? searchBtn() : null)}
                    />
                    <AppTooltip
                      title={props.t('tooltip_text.search')}
                      placement={'bottom'}
                    >
                      {/* onClick={() => searchBtn() */}
                      <IconButton sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </AppTooltip>
                    <Divider
                      sx={{ height: 28, m: 0.5, borderColor: '#000' }}
                      orientation="vertical"
                    />
                    <AppTooltip
                      title={props.t('tooltip_text.filter')}
                      placement={'bottom'}
                    >
                      <IconButton
                        onClick={() => setFilterPopup(true)}
                        sx={{ p: '10px' }}
                        aria-label="directions"
                      >
                        {applayFilterBadge ? (
                          <Badge
                            color="error"
                            variant="dot"
                            overlap="circular"
                            invisible={false}
                          >
                            <FilterAltOutlinedIcon />
                          </Badge>
                        ) : (
                          <FilterAltOutlinedIcon />
                        )}
                      </IconButton>
                    </AppTooltip>
                  </Paper>
                </Box>
                <div className="employee_card_wrapper">
                  <div className="row gy-4">
                    {/* linkedUsers.filter(user => user.name.toLowerCase().includes(searchData.toLowerCase())).map((user, index) => ( */}
                    {
                      !_.isEmpty(empLeaveRqsData) ? (
                        empLeaveRqsData
                          .filter((emp) =>
                            emp.employeeName
                              .toLowerCase()
                              .includes(searchValue?.toLowerCase())
                          )
                          .map((empData, i) => {
                            return (
                              <>
                                <div key={i} className="col-12">
                                  <Card className="position-relative h-100 d-flex flex-column app_card_shadow">
                                    <CardContent sx={{ flex: 1 }}>
                                      {/* <Box className='' sx={{ width: "100%", minHeight: "auto", backgroundColor: "#fff", borderRadius: "10px", boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.05)", border: "1px solid #E7F0FF", padding: "15px" }}> */}
                                      <Box
                                        className=""
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'self-start',
                                          gap: '12px',
                                        }}
                                      >
                                        <Box>
                                          <UserAvatar
                                            diameter={40}
                                            alternateSrc={'Lorem ipsum'}
                                            imgSrc={
                                              empData?.employeeImage
                                                ? files(
                                                    empData?.employeeImage,
                                                    'image'
                                                  )
                                                : ''
                                            }
                                          />
                                        </Box>
                                        <Box sx={{ flex: '1' }}>
                                          <Stack
                                            direction={{
                                              xts: 'row',
                                              xs: 'column',
                                            }}
                                            justifyContent={'space-between'}
                                            spacing={{ xts: 0, xs: 1 }}
                                          >
                                            <Typography
                                              component={'span'}
                                              className="app_text_20_500 app_text_transform"
                                            >
                                              {empData.employeeName}
                                            </Typography>
                                            {empData?.requestStatus === 1 ? (
                                              <Typography
                                                component={'span'}
                                                variant="body1"
                                                fontWeight={500}
                                                className="status_info accepted"
                                                width={'fit-content'}
                                              >
                                                accepted
                                              </Typography>
                                            ) : empData?.requestStatus === 2 ? (
                                              <Typography
                                                component={'span'}
                                                variant="body1"
                                                fontWeight={500}
                                                className="status_info reject"
                                                width={'fit-content'}
                                              >
                                                rejected
                                              </Typography>
                                            ) : empData?.requestStatus === 3 ? (
                                              <Typography
                                                component={'span'}
                                                variant="body1"
                                                fontWeight={500}
                                                className="status_info cancelled"
                                                width={'fit-content'}
                                              >
                                                cancelled
                                              </Typography>
                                            ) : (
                                              <></>
                                            )}
                                          </Stack>
                                          <Typography
                                            component={'span'}
                                            className="app_text_16"
                                            display={'block'}
                                            py={0.5}
                                          >
                                            {empData?.businessName +
                                              ` - ` +
                                              empData?.businessBranchName}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Typography
                                              component={'span'}
                                              marginRight={'4px'}
                                            >
                                              <BusinessCenterIcon
                                                color={'#000000'}
                                              />
                                            </Typography>
                                            <Typography
                                              component={'span'}
                                              className="app_text_16"
                                            >
                                              {empData?.designation}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                            py={0.5}
                                          >
                                            <Typography
                                              component={'span'}
                                              className="app_text_16"
                                            >
                                              leave reason : {empData.reason}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                            display={'block'}
                                          >
                                            <Typography
                                              component={'span'}
                                              className="app_text_16"
                                            >
                                              reject reason :{' '}
                                              {empData.cancleReason || '-'}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <Typography
                                              component={'span'}
                                              className="app_text_16_semibold app_text_primary app_text_transform my-2"
                                            >
                                              {moment(empData.startDate).format(
                                                'MM-DD-YYYY'
                                              )}{' '}
                                              to{' '}
                                              {moment(empData.endDate).format(
                                                'MM-DD-YYYY'
                                              )}
                                            </Typography>
                                            {empData?.requestStatus === 1 ? (
                                              <Button
                                                className="app_bg_red_light"
                                                sx={{
                                                  minWidth: 'unset !important',
                                                }}
                                                onClick={() =>
                                                  handleDeleteReq(empData)
                                                }
                                              >
                                                <TrashBin />
                                              </Button>
                                            ) : (
                                              <></>
                                            )}
                                          </Box>
                                          {empData?.requestStatus === 0 ? (
                                            <>
                                              <Box className="d-flex align-items-center gap-2">
                                                <Button
                                                  className="linkList_btn app_text_transform app_text_14_semibold text-white app_bg_primary"
                                                  onClick={empLeaveApproveStatus(
                                                    empData,
                                                    1
                                                  )}
                                                  sx={{
                                                    padding:
                                                      '8px 24px !important',
                                                  }}
                                                >
                                                  accept
                                                </Button>
                                                <Button
                                                  className="linkList_btn app_border_primary app_text_transform app_text_14_semibold app_text_primary app_bg_primary_light"
                                                  sx={{
                                                    padding:
                                                      '8px 24px !important',
                                                  }}
                                                  onClick={empLeaveRejectStatus(
                                                    empData,
                                                    2
                                                  )}
                                                >
                                                  reject
                                                </Button>
                                              </Box>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {/* <CardActions className="pt-1 pb-3 px-3">
                                                               <Stack direction={"row"} spacing={2}>
                                                                  <Buttons employeeStatus={empData.requestStatus} empData={empData} />
                                                               </Stack>
                                                            </CardActions> */}
                                        </Box>
                                      </Box>
                                      {/* </Box> */}
                                    </CardContent>
                                  </Card>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        // <Box sx={{ position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Typography
                          className="app_text_gray text-nowrap app_text_20_bold app_text_transform"
                          sx={{ textAlign: 'center' }}
                        >
                          {props.t('employee_request_module.no_employee_req')}
                        </Typography>
                      )
                      // </Box>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            onClose={handleClose}
            open={openModal}
            className="request_popup_wrapper"
          >
            <DialogTitle className="px-0 pt-0 text-center">
              <span className="app_text_20_500 app_text_transform">
                {' '}
                {props.t('popup_title.reject_request')}{' '}
              </span>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 12,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="row row-cols-1 g-4 mt-1">
                  <div className="col">
                    <TextField
                      className="app_text_transform"
                      id="reason"
                      label={props.t('text_filed.reject_request_reason')}
                      varient="outlined"
                      {...getFieldProps('reason')}
                      error={Boolean(touched.reason && errors.reason)}
                      helperText={touched.reason && errors.reason}
                      fullWidth
                    />
                  </div>
                  <div className="col">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="my-3 app_text_transform text-white app_bg_primary app_text_16_semibold app_btn_lg"
                    >
                      {props.t('btn_text.submit')}
                    </Button>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Dialog>

          <EmpLeaveReqFilterPopup
            open={filterPopup}
            onClose={filterPopupClose}
            // applayFilterBadge={setApplayFilterBadge}
            reset={reset}
            filters={filterDataClick}
            empData={empAvailability}
          />
          <DeleteLeaveReqPopup
            openPopup={openDeletePopup}
            onclose={handleCloseDeletePopup}
            empData={empData}
          />
        </>
      )}
    </>
  );
};

export default withTranslation()(BusinessEmpAvailability);
