import { useEffect, useState } from 'react';

// Function to broadcast user activity to other tabs
const broadcastActivity = () => {
  localStorage.setItem('userActivity', Date.now());
  // Trigger a storage event to notify other tabs
  localStorage.removeItem('userActivity');
};

export const UserActivityMonitor = ({
  inactivityDuration = 60 * 1000 * 5, // Default inactivity duration of 5 minutes
  onInactive,
  onActive,
}) => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isVisible, setIsVisible] = useState(true); // Track whether the page is visible
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    // Handle visibility change events (e.g., when the tab is switched)
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    // Handle storage events triggered by activity broadcast
    const handleStorageChange = (event) => {
      if (event.storageArea === localStorage && event.key === 'userActivity') {
        setLastActivity(Date.now()); // Reset activity timer on activity broadcast
      }
    };

    // Handle user activity events (e.g., mouse move, keypress)
    const handleActivity = () => {
      if (isVisible) {
        broadcastActivity(); // Broadcast activity to other tabs
        setLastActivity(Date.now()); // Update the last activity timestamp
        if (isOffline) {
          setIsOffline(false); // Reset offline status if activity is detected
          onActive(); // Call the onActive callback if user comes back online
        }
      }
    };

    // List of events to listen for user activity
    const events = ['mousemove', 'keypress', 'click'];
    events.forEach((event) => window.addEventListener(event, handleActivity));
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('storage', handleStorageChange);

    // Check for inactivity based on the last activity timestamp
    const checkInactivity = () => {
      if (Date.now() - lastActivity > inactivityDuration && !isOffline) {
        setIsOffline(true);
        onInactive(); // Call the function only once when the user goes offline
      }
    };

    // Set up an interval to check for inactivity every second
    const intervalId = setInterval(checkInactivity, 1000);

    // Cleanup function to remove event listeners and clear interval
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(intervalId);
    };
  }, [lastActivity, inactivityDuration, onInactive, isVisible, isOffline]);

  return null;
};
