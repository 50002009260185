import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { withTranslation } from 'react-i18next';

const ActiveAccountSendRequestPopup = (props) => {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose();
  };

  const cancelRequest = (mailto) => {
    window.location.href = mailto;
    onClose();
  };

  return (
    <Dialog
      className="app_popup_delete_account_cancel"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle className="d-flex flex-column align-items-center justify-content-center mt-3">
        <h4 className="app_text_16 app_text_black app_text_transform app_text_primary_2 text-center mt-4">
          {props.t('popup_title.delete_req_cancel')}
        </h4>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <p className="app_text_16_500 app_text_black_3 text-center my-3">
          {props.t('popup_message.delete_account_cancel_message')}{' '}
        </p>
        <Button
          variant="contained"
          onClick={() => {
            cancelRequest(
              `mailto:support@beemxyz.com?subject=${props.t(
                'popup_message.mail_box_subject'
              )}`
            );
          }}
          fullWidth
          className="theme_button"
          sx={{ my: 3, py: 4, minHeight: 48 }}
        >
          {props.t('popup_btn.send_request')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(ActiveAccountSendRequestPopup);
