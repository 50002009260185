import {
  Box,
  Dialog,
  Divider,
  List,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import React from 'react';
import UserAvatar from '../common/UserAvatar';
import { withTranslation } from 'react-i18next';
import files from 'src/helpers/helpers';
import '@lottiefiles/lottie-player';
import { Button } from '@material-ui/core';
import { useState } from 'react';

const EventInvationListPopup = (props) => {
  const { open, onClose, eventInvationList, eventTitle } = props;

  let rsvpCount = [];

  eventInvationList
    ?.filter((eventData) => eventData.status == 3)
    .map((rsvpItem) => {
      rsvpCount.push(rsvpItem);
    });
  let guestCount = [];

  eventInvationList
    ?.filter((eventData) => eventData.status == 4)
    .map((guest) => {
      guestCount.push(guest);
    });

  let declinedCount = [];
  eventInvationList
    ?.filter((eventData) => eventData.status == 2)
    .map((declined) => {
      declinedCount.push(declined);
    });

  const EnvitedButton = ({ event, rejectReason }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const openPopover = Boolean(anchorEl);

    switch (event) {
      case 1:
        return (
          <>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                component={'p'}
                className="app_text_20 app_text_14_semibold app_text_tarnsform app_text_primary"
              >
                accepted
              </Typography>
            </Box>
          </>
        );
      case 3:
        return (
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component={'p'}
              className="app_text_20 app_text_14_semibold app_text_tarnsform text-black"
            >
              accepted
            </Typography>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component={'p'}
              className="app_text_20 app_text_14_semibold app_text_tarnsform app_text_orange"
            >
              intrested
            </Typography>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component={'p'}
              className="app_text_20 app_text_14_semibold app_text_tarnsform app_text_red"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              declined
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={openPopover}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>{rejectReason}</Typography>
            </Popover>
          </Box>
        );

      default:
        return <></>;
    }
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '400px',
          width: '100%',
          height: '500px',
          backgroundColor: '#fff',
          '&.MuiDialog-paper': {
            borderRadius: '5px',
          },
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px 20px',
            backgroundColor: '#6e3fc6',
          }}
        >
          <Typography
            component={'p'}
            className="app_text_14_semibold"
            sx={{ color: '#fff' }}
          >
            {eventTitle}
          </Typography>
          <Typography
            component={'p'}
            className="app_text_14_500"
            sx={{ color: '#fff' }}
          >
            {eventInvationList?.length} {props.t('popup_title.user')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', height: '435px', overflowY: 'auto' }}>
          <Box sx={{ margin: '10px' }}>
            <Typography
              component={'p'}
              className="app_text_14"
              sx={{ color: '#000000' }}
            >
              all({eventInvationList?.length}), accepted({rsvpCount?.length}),
              interested({guestCount?.length}), declined({declinedCount?.length}
              )
            </Typography>
          </Box>
          <Divider sx={{ border: '1px solid #9ea1a4' }} />
          {eventInvationList?.length > 0 ? (
            <List sx={{ padding: '20px' }}>
              {eventInvationList.map((user, index) => {
                return (
                  <ListItem
                    sx={{ padding: '0px', marginBottom: '15px' }}
                    key={index}
                  >
                    <UserAvatar
                      diameter={50}
                      alternateSrc={user?.fullName}
                      imgSrc={
                        user?.permissions?.visibility?.picture
                          ? files(user?.image, 'image')
                          : ''
                      }
                    />
                    <Box sx={{ marginLeft: '15px', flex: '1' }}>
                      <Typography className="app_text_transform app_text_14_500">
                        {user?.fullName}
                      </Typography>
                      {user?.userName ? (
                        <Typography className="app_text_transform app_text_14">
                          {`@${user?.userName}`}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <div className="d-flex flex-nowrap">
                      <div>
                        <EnvitedButton
                          event={user?.status}
                          rejectReason={user?.rejectReason}
                        />
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default withTranslation()(EventInvationListPopup);
