import React from 'react';

const HomeIcon = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.459 0.660469C12.3259 0.556713 12.162 0.500366 11.9932 0.500366C11.8245 0.500366 11.6606 0.556713 11.5275 0.660469L0.75 9.06422L1.68225 10.2432L3 9.21572V18.5C3.00079 18.8976 3.15908 19.2786 3.44022 19.5598C3.72135 19.8409 4.10242 19.9992 4.5 20H19.5C19.8976 19.9992 20.2787 19.8409 20.5598 19.5598C20.8409 19.2786 20.9992 18.8976 21 18.5V9.22247L22.3177 10.25L23.25 9.07097L12.459 0.660469ZM13.5 18.5H10.5V12.5H13.5V18.5ZM15 18.5V12.5C14.9996 12.1023 14.8414 11.721 14.5602 11.4397C14.279 11.1585 13.8977 11.0004 13.5 11H10.5C10.1023 11.0004 9.721 11.1585 9.43978 11.4397C9.15856 11.721 9.0004 12.1023 9 12.5V18.5H4.5V8.04647L12 2.20397L19.5 8.05397V18.5H15Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default HomeIcon;
