import React from 'react';

const UserCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4045 15.357C8.827 14.857 11.1835 14.9 14.6105 15.3745C14.8587 15.4105 15.0855 15.535 15.249 15.7252C15.4125 15.9153 15.5016 16.1582 15.5 16.409C15.5 16.649 15.4175 16.882 15.2685 17.064C15.0088 17.3813 14.7428 17.6934 14.4705 18H15.791C15.874 17.901 15.9575 17.8 16.042 17.6975C16.3386 17.3338 16.5004 16.8788 16.5 16.4095C16.5 15.397 15.761 14.5245 14.7475 14.3845C11.2395 13.899 8.7875 13.8525 5.26 14.368C4.236 14.5175 3.5 15.4035 3.5 16.423C3.5 16.8755 3.6475 17.323 3.927 17.6855C4.0095 17.7925 4.091 17.8975 4.172 18.0005H5.4605C5.20721 17.6973 4.96016 17.3889 4.7195 17.0755C4.57648 16.8879 4.49933 16.6584 4.5 16.4225C4.5 15.884 4.887 15.4325 5.4045 15.357ZM10 10.5C10.394 10.5 10.7841 10.4224 11.1481 10.2716C11.512 10.1209 11.8427 9.8999 12.1213 9.62132C12.3999 9.34274 12.6209 9.01203 12.7716 8.64805C12.9224 8.28407 13 7.89397 13 7.5C13 7.10603 12.9224 6.71593 12.7716 6.35195C12.6209 5.98797 12.3999 5.65726 12.1213 5.37868C11.8427 5.1001 11.512 4.87913 11.1481 4.72836C10.7841 4.5776 10.394 4.5 10 4.5C9.20435 4.5 8.44129 4.81607 7.87868 5.37868C7.31607 5.94129 7 6.70435 7 7.5C7 8.29565 7.31607 9.05871 7.87868 9.62132C8.44129 10.1839 9.20435 10.5 10 10.5ZM10 11.5C11.0609 11.5 12.0783 11.0786 12.8284 10.3284C13.5786 9.57828 14 8.56087 14 7.5C14 6.43913 13.5786 5.42172 12.8284 4.67157C12.0783 3.92143 11.0609 3.5 10 3.5C8.93913 3.5 7.92172 3.92143 7.17157 4.67157C6.42143 5.42172 6 6.43913 6 7.5C6 8.56087 6.42143 9.57828 7.17157 10.3284C7.92172 11.0786 8.93913 11.5 10 11.5Z"
        fill="#ffffff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19C14.9705 19 19 14.9705 19 10C19 5.0295 14.9705 1 10 1C5.0295 1 1 5.0295 1 10C1 14.9705 5.0295 19 10 19ZM10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default UserCircle;
