import React from 'react';

const Designation = ({ size }) => {
  return (
    <svg
      width={size ? size : '24px'}
      height={size ? size : '24px'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_33340_29220)">
        <rect width="24" height="24" fill="white" />
        <path
          d="M16.6158 4V2C16.6158 1.46957 16.4213 0.960859 16.0751 0.585786C15.7288 0.210714 15.2593 0 14.7696 0H9.23118C8.74155 0 8.27197 0.210714 7.92575 0.585786C7.57953 0.960859 7.38503 1.46957 7.38503 2V4H2.11426C2.11334 6.86782 3.14629 9.62265 4.99299 11.6774C6.83969 13.7322 9.35411 14.9244 12.0004 15C14.6402 14.9142 17.1453 13.7175 18.9841 11.6638C20.823 9.61012 21.8508 6.8611 21.8496 4H16.6158ZM9.23118 4V2H14.7696V4H9.23118Z"
          fill="#6200EE"
        />
        <path
          d="M5.53846 14.7698V23.9998H18.4615V14.7698C16.5731 16.2208 14.3152 17.0002 12 17.0002C9.68483 17.0002 7.42688 16.2208 5.53846 14.7698V14.7698ZM0 5.99984V21.9998C0 22.5303 0.194505 23.039 0.540726 23.4141C0.886947 23.7891 1.35652 23.9998 1.84615 23.9998H3.69231V13.0698C1.7122 10.8552 0.515976 7.95529 0.313846 4.87984C0.110982 5.2122 0.00192076 5.6014 0 5.99984ZM23.6585 4.83984C23.4666 7.91799 22.2803 10.8246 20.3077 13.0498V23.9998H22.1538C22.6435 23.9998 23.1131 23.7891 23.4593 23.4141C23.8055 23.039 24 22.5303 24 21.9998V5.99984C24.0002 5.58412 23.8808 5.17866 23.6585 4.83984Z"
          fill="#6200EE"
        />
      </g>
      <defs>
        <clipPath id="clip0_33340_29220">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Designation;
