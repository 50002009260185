import DoneIcon from '@mui/icons-material/Done';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  Popover,
  styled,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import DeleteIcon from 'src/svgComponents/DeleteIcon';
import AppTooltip from '../common/AppTooltip';
import UserAvatar from '../common/UserAvatar';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { axiosPrivate, INDIVIDUAL_API_URL } from 'src/api/axios';
import { useNavigate } from 'react-router';
import { PATH_CHAT, PATH_DASHBOARD, PATH_USER } from 'src/routes/paths';

// Styled badge component
const StyledBadge = styled(Badge)(() => ({
  '&.MuiBadge-root .MuiBadge-badge': {
    backgroundColor: 'red',
    padding: 0,
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

const NotificationBell = ({
  t,
  notificationCount,
  notificationList,
  ...props
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const { mutateAsync: readAllNotification } = useMutation(async () => {
    return await axiosPrivate.put(INDIVIDUAL_API_URL.readAllNotification);
  });
  const { mutateAsync: notificationDelete } = useMutation(async () => {
    return await axiosPrivate.put(INDIVIDUAL_API_URL.notificationDelete);
  });
  const { mutateAsync: ReadNotification } = useMutation(async (id) => {
    return await axiosPrivate.put(
      INDIVIDUAL_API_URL.readNotification.replace(':id', id)
    );
  });
  const navigate = useNavigate();

  // Total number of unread notifications
  const unreadNotifications = notificationCount?.reduce(
    (acc, item) => acc + item.count,
    0
  );
  const notificationPopupOpen = Boolean(notificationAnchorEl);

  // Handle notification popover
  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  // Handle delete dialog
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Handle read all notifications functionality
  const handleReadAllClick = async () => {
    try {
      await readAllNotification();
      window.location.reload();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  // Handle delete all notifications functionality
  const handleDeleteClick = async (queryClient, refetch) => {
    try {
      await notificationDelete();
      await readAllNotification();
      queryClient.invalidateQueries('notificationList');
      refetch();
    } catch (error) {
      console.error('Error deleting notifications:', error);
    }
  };

  // Delete all notifications
  const confirmDelete = async () => {
    handleDeleteDialogClose();
    await handleDeleteClick();
    window.location.reload();
    await readAllNotification();
  };

  const setReadNotification = async (id, item) => {
    await ReadNotification(id);
    if (item.data.postId) {
      // If the notification is related to a post, navigate to the feed detail page for that post
      navigate(PATH_DASHBOARD.general.feedDetail + '/' + item.data.postId);
    } else if (item.data.eventId) {
      // If the notification is related to an event, navigate to the event detail page
      navigate(PATH_DASHBOARD.general.eventdetail, {
        state: { eventId: item.data.eventId },
      });
    } else if (item.data.link) {
      // If the notification contains a link, navigate to the home page and show a trace box
      navigate('/home', { state: { showTraceBox: true, activePart: 2 } });
    } else if (item.data.businessId && item.data.recipient) {
      // If the notification is related to a business profile, navigate to the user's business profile page
      navigate(PATH_USER.businessProfile, {
        state: { otherUserBusinessId: item.data.businessId },
      });
    } else if (item.data.businessId && item.data.stringify) {
      // If the notification is related to a business chat, determine the conversation type and navigate accordingly
      let data = JSON.parse(item.data.stringify);
      if (data.convType === 2) {
        // Navigate to the business chat
        navigate(`${PATH_CHAT.root}/business/${item.data.businessId}`);
      } else {
        // Navigate to the general chat
        navigate(PATH_CHAT.root);
      }
    } else if (item.data.traceUserId) {
      navigate('/home', { state: { showTraceBox: true, activePart: 1 } });
    } else if (item.data.senderId && item.data.stringify) {
      let data = JSON.parse(item.data.stringify);
      if (data.convType === 1) {
        // Navigate to the individual chat with the sender
        navigate(`${PATH_CHAT.root}/${item.data.senderId}`);
      } else if (data.convType === 3) {
        // Navigate to the group chat
        navigate(`${PATH_CHAT.root}/group/${data?.groupId}`);
      } else {
        // Navigate to the chat page
        navigate(PATH_CHAT.root);
      }
    } else {
      // await ReadNotification(id);
    }
  };

  return (
    <>
      <AppTooltip title={t('tooltip_text.notification')} placement={'bottom'}>
        <Button
          disableRipple
          className="main_nav_config_btn"
          onClick={handleNotificationClick}
          {...props}
        >
          <StyledBadge
            badgeContent={unreadNotifications}
            color="error"
            overlap="circular"
          >
            <NotificationsIcon style={{ color: '#fff' }} />
          </StyledBadge>
        </Button>
      </AppTooltip>

      {/* Notifications Popover */}
      <Popover
        open={notificationPopupOpen}
        anchorEl={notificationAnchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginTop: '20px' }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h4 class="app_text_16_semibold app_text_black mb-0 p-3 pb-2 app_text_transformno">
            notifications
          </h4>
          {notificationCount &&
          notificationList &&
          (notificationCount.length > 0 ||
            (notificationList.data && notificationList.data.length > 0)) ? (
            <div className="d-flex align-items-center">
              <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'delete notifications?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    are you sure you want to delete all notifications? this
                    action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteDialogClose} color="primary">
                    cancel
                  </Button>
                  <Button onClick={confirmDelete} color="primary" autoFocus>
                    confirm
                  </Button>
                </DialogActions>
              </Dialog>
              <AppTooltip
                title={t('tooltip_text.read_all_notification')}
                placement={'bottom'}
              >
                <Button
                  disableRipple
                  className="px-2 w-auto not-btn"
                  onClick={(e) => handleReadAllClick(e)}
                >
                  <DoneIcon />{' '}
                </Button>
              </AppTooltip>
              <AppTooltip
                title={t('tooltip_text.delete_notification')}
                placement={'bottom'}
              >
                <Button
                  disableRipple
                  className="px-2 w-auto not-btn"
                  onClick={(e) => handleDeleteDialogOpen(e)}
                >
                  <DeleteIcon />{' '}
                </Button>
              </AppTooltip>
            </div>
          ) : null}
        </div>

        <div className="notification_popover_content px-0">
          {notificationList ? (
            notificationList.data
              .filter(
                (item) =>
                  item.data.type != 2 &&
                  item.data.type != 3 &&
                  item.data.type != 4 &&
                  item.data.type != 112 &&
                  item.data.type != 113
              )
              .map((item, i) => (
                <ListItem
                  className="track_req_list_item"
                  key={i}
                  onClick={() => setReadNotification(item.notificationId, item)}
                >
                  <div className="d-flex align-items-center">
                    {item.data.businessId ? (
                      <UserAvatar
                        diameter={40}
                        imgSrc={
                          'https://api.beemxyz.com/uploads/images/' +
                          item.businessInfo.image
                        }
                        alternateSrc={item.businessInfo.name}
                      />
                    ) : item.data.eventId ? (
                      <div>
                        <UserAvatar
                          diameter={40}
                          imgSrc={
                            'https://api.beemxyz.com/uploads/images/' +
                            item.eventInfo.image
                          }
                          alternateSrc={item.senderInfo.name}
                        />
                      </div>
                    ) : (
                      <UserAvatar
                        diameter={40}
                        imgSrc={
                          'https://api.beemxyz.com/uploads/images/' +
                          item.senderInfo.image
                        }
                        alternateSrc={item.senderInfo.name}
                      />
                    )}
                    <div className="d-flex flex-column ms-2">
                      {item.isRead ? (
                        <>
                          <h4 className="app_text_14_500 app_text_gray  mb-1">
                            {item?.notification?.title}
                          </h4>
                        </>
                      ) : (
                        <>
                          <h4 className="app_text_14_500 app_text_black  mb-1">
                            {item?.notification?.title}
                          </h4>
                        </>
                      )}
                      <span className="app_text_12_fw500 app_text_gray">
                        {item?.notification?.body}
                      </span>
                    </div>
                  </div>
                </ListItem>
              ))
          ) : (
            <></>
          )}
        </div>
      </Popover>
    </>
  );
};

export default withTranslation()(NotificationBell);
