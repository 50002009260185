import React from 'react';
import { Avatar, Button, ListItem, ListItemAvatar } from '@mui/material';
import files from 'src/helpers/helpers';
import UserAvatar from '../common/UserAvatar';
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { BUSINESS_API_URL, USER_API_URL } from 'src/api/axios';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_USER } from 'src/routes/paths';
const LinkListRow = (props) => {
  const {
    userId,
    userName,
    name,
    businessName,
    businessId,
    permissions,
    businessPic,
    loginUserId,
    userPic,
    isLinked,
  } = props;
  const LinkReq = ({ isLinked, userId, isBusiness }) => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();
    const { mutateAsync: friendRequest } = useMutation(
      async (data) => {
        return await axiosPrivate.post(USER_API_URL.friendRequest + data);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['linkedList']);
        },
      }
    );
    const { mutateAsync: businessFriendRequest } = useMutation(
      async (data) => {
        return await axiosPrivate.post(
          BUSINESS_API_URL.friendRequestBusiness + data
        );
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['linkedList']);
        },
      }
    );
    const linkRequest = async (userId) => {
      if (isBusiness) {
        await businessFriendRequest(userId);
      } else {
        await friendRequest(userId);
      }
    };
    switch (isLinked) {
      case 2:
      case 1:
        return (
          <Button
            className="ms-auto linkList_btn app_border_primary app_text_transform app_text_12_semibold app_text_primary app_bg_primary_light"
            disabled
            variant="outlined"
          >
            {props.t('popup_btn.requested')}
          </Button>
        );

      case 4:
      case 0:
        return (
          <Button
            className="ms-auto linkList_btn app_border_primary app_text_transform app_text_14_semibold text-white app_bg_primary"
            onClick={() => linkRequest(userId)}
            variant="contained"
          >
            {props.t('popup_btn.link')}
          </Button>
        );

      case 3:
        return (
          <Button
            className="ms-auto linkList_btn app_border_primary app_text_transform app_text_14_semibold app_text_primary app_bg_primary_light"
            disabled
            variant="outlined"
          >
            {props.t('popup_btn.linked')}
          </Button>
        );
      default:
        return '';
    }
  };
  //navigate to profile
  const navigate = useNavigate();
  const handleNavigationToProfile = () => {
    props.onClose();
    navigate(PATH_USER.userProfile, { state: { otherUserId: userId } });
  };
  return (
    <ListItem className="px-0 py-3">
      <ListItemAvatar
        className="linkList_avatar_container"
        onClick={handleNavigationToProfile}
      >
        <Avatar>
          {permissions?.visibility?.picture ? (
            <UserAvatar
              diameter={40}
              imgSrc={files(businessPic ? businessPic : userPic, 'image')}
              alternateSrc={Array.from(name || businessName)[0]}
            />
          ) : (
            <UserAvatar
              diameter={40}
              imgSrc={''}
              alternateSrc={Array.from(name || businessName)[0]}
            />
          )}
        </Avatar>
      </ListItemAvatar>
      <div className="d-flex flex-column" onClick={handleNavigationToProfile}>
        <h4 className="app_text_14_500">{businessName || name}</h4>
        <div className="d-flex">
          {(businessName ? name : userName) && (
            <span className="app_text_gray app_text_12">{`@${
              businessName ? name : userName
            }`}</span>
          )}
          {/* {distance && <span className='ms-2 app_text_gray app_text_12 d-flex align-items-center'><FmdGoodIcon style={{ fontSize: 12, marginRight: 3 }} />{`${distance} away`}</span>} */}
        </div>
      </div>
      {loginUserId === userId ? (
        <Button
          className="ms-auto linkList_btn app_border_primary app_text_transform app_text_14_semibold text-white app_bg_primary"
          variant="contained"
        >
          {props.t('popup_btn.you')}
        </Button>
      ) : (
        <LinkReq
          isLinked={isLinked}
          isBusiness={businessId ? true : false}
          userId={businessId ? businessId : userId}
        />
      )}
    </ListItem>
  );
};

export default withTranslation()(LinkListRow);
