import React from 'react';

const TrashBin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_29340_20856)">
        <path
          d="M14.166 5.00008H18.3327V6.66675H16.666V17.5001C16.666 17.7211 16.5782 17.9331 16.4219 18.0893C16.2657 18.2456 16.0537 18.3334 15.8327 18.3334H4.16602C3.945 18.3334 3.73304 18.2456 3.57676 18.0893C3.42048 17.9331 3.33268 17.7211 3.33268 17.5001V6.66675H1.66602V5.00008H5.83268V2.50008C5.83268 2.27907 5.92048 2.06711 6.07676 1.91083C6.23304 1.75455 6.445 1.66675 6.66602 1.66675H13.3327C13.5537 1.66675 13.7657 1.75455 13.9219 1.91083C14.0782 2.06711 14.166 2.27907 14.166 2.50008V5.00008ZM7.49935 9.16675V14.1667H9.16602V9.16675H7.49935ZM10.8327 9.16675V14.1667H12.4993V9.16675H10.8327ZM7.49935 3.33341V5.00008H12.4993V3.33341H7.49935Z"
          fill="#FD4A4C"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_29340_20856">
          <rect width="20" height="20" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashBin;
