import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from 'src/svgComponents/SearchIcon';
import LinkListRow from './LinkListRow';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { USER_API_URL } from 'src/api/axios';
import { useQuery } from 'react-query';
import { withTranslation } from 'react-i18next';
import useStore from 'src/contexts/AuthProvider';

const LinkingPopup = (props) => {
  const { onClose, open, newLinkId, business } = props;
  const loginUserId = useStore((state) => state.user._id);
  const [searchData, setSerchData] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const handleClose = () => {
    onClose();
  };

  const handleSearch = (e) => {
    setSerchData(e.target.value.trim());
  };

  const { data: newLinkRequest, refetch: linkedRequestList } = useQuery(
    ['linkedList', newLinkId, business],
    async ({ signal }) => {
      if (newLinkId) {
        if (business) {
          return await axiosPrivate
            .get(USER_API_URL.linkedRequest + newLinkId + '/' + 2, { signal })
            .then((res) => res.data);
        } else {
          return await axiosPrivate
            .get(USER_API_URL.linkedRequest + newLinkId + '/' + 1, { signal })
            .then((res) => res.data);
        }
      }
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (newLinkId) {
      linkedRequestList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLinkId]);

  return (
    <Dialog onClose={handleClose} open={open} className="linking_popup_wrapper">
      <DialogTitle className="px-0 pt-0">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.linked_list')}
        </span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {newLinkRequest && newLinkRequest.length > 0 ? (
        <>
          <Paper className="linking_popup_searchbox">
            <IconButton style={{ marginTop: '-2px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={props.t('comman_label.search')}
              onChange={handleSearch}
            />
          </Paper>
          <h4 className="app_text_gray app_text_12_fw500 py-3 ps-1 app_text_transform">
            {props.t('popup_message.linked_to_contacts')}
          </h4>
          <List className="linkList" sx={{ pt: 0 }}>
            {newLinkRequest
              .filter((user) => user?.businessId !== newLinkId)
              .filter((user) =>
                user.name.toLowerCase().includes(searchData.toLowerCase())
              )
              .map((user, index) => (
                <LinkListRow
                  key={index}
                  userId={user.id}
                  userName={user.userName}
                  name={user.name}
                  businessName={user.businessName}
                  permissions={user.permissions}
                  userStatus={user.userStatus}
                  userPic={user.image}
                  businessPic={user.businessImage}
                  isLinked={user.friendStatus}
                  businessId={user.businessId}
                  loginUserId={loginUserId}
                  onClose={handleClose}
                />
              ))}
          </List>
        </>
      ) : (
        <span className="app_text_20_semibold app_text_transform">
          {props.t('popup_message.no_link_user')}
        </span>
      )}
    </Dialog>
  );
};

LinkingPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(LinkingPopup);
