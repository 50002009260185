import React from 'react';

const EmpLeaveIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#9852FF" />
      <g clipPath="url(#clip0_32186_29117)">
        <path
          d="M13.9151 20.0169V27.7966C13.9151 29.5661 15.349 31 17.1185 31H26.8812C28.6507 31 30.0846 29.5661 30.0846 27.7966V20.0169H13.9151ZM25.9049 23.4644L21.6032 27.7356C21.4507 27.8881 21.2676 27.9492 21.0541 27.9492C20.8405 27.9492 20.6575 27.8577 20.5049 27.7356L18.0642 25.3254C17.7592 25.0203 17.7592 24.5322 18.0642 24.2576C18.3693 23.9525 18.8575 23.9525 19.1321 24.2576L21.0236 26.1491L24.7761 22.3966L24.8066 22.4271C25.1117 22.1525 25.5693 22.1525 25.8744 22.4271C26.1795 22.6712 26.21 23.1593 25.9049 23.4644ZM26.8812 15.1356H26.5761V13.7627C26.5761 13.3356 26.2405 13 25.8134 13C25.3863 13 25.0507 13.3356 25.0507 13.7627V15.1356H18.949V13.7627C18.949 13.3356 18.6134 13 18.1862 13C17.7591 13 17.4235 13.3356 17.4235 13.7627V15.1356H17.1184C15.349 15.1356 13.915 16.5695 13.915 18.339V18.4915H30.0845V18.339C30.0846 16.5695 28.6507 15.1356 26.8812 15.1356Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_32186_29117">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmpLeaveIcon;
