import React from 'react';

const BusinessAddressesIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#9852FF" />
      <path d="M19 17.5H20.5V19H19V17.5Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.25 18.25H28.75C28.9489 18.25 29.1397 18.329 29.2803 18.4697C29.421 18.6103 29.5 18.8011 29.5 19V28C29.5 28.1989 29.421 28.3897 29.2803 28.5303C29.1397 28.671 28.9489 28.75 28.75 28.75H15.25C15.0511 28.75 14.8603 28.671 14.7197 28.5303C14.579 28.3897 14.5 28.1989 14.5 28V16C14.5 15.8011 14.579 15.6103 14.7197 15.4697C14.8603 15.329 15.0511 15.25 15.25 15.25H21.25C21.4489 15.25 21.6397 15.329 21.7803 15.4697C21.921 15.6103 22 15.8011 22 16V21.25H23.5V19C23.5 18.8011 23.579 18.6103 23.7197 18.4697C23.8603 18.329 24.0511 18.25 24.25 18.25ZM16.0001 25H17.5001V23.5H16.0001V25ZM16.0001 22H17.5001V20.5H16.0001V22ZM16.0001 19H17.5001V17.5H16.0001V19ZM19.0001 25H20.5001V23.5H19.0001V25ZM19.0001 22H20.5001V20.5H19.0001V22ZM25.7501 25H27.2501V23.5H25.7501V25ZM25.7501 22H27.2501V20.5H25.7501V22Z"
        fill="white"
      />
    </svg>
  );
};

export default BusinessAddressesIcon;
