import React from 'react';

const AppSettingIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#F152FF" />
      <g clipPath="url(#clip0_29340_21097)">
        <path
          d="M18.342 23.074C18.5917 22.7876 18.3883 22.3403 18.0083 22.3403H16.8705C16.8631 22.2278 16.8589 22.1143 16.8589 21.9999C16.8589 19.1651 19.1652 16.8588 22 16.8588C23.3623 16.8588 24.6023 17.3916 25.5232 18.2595L27.1276 16.3826C25.7232 15.0978 23.9157 14.394 22 14.394C19.9684 14.394 18.0584 15.1852 16.6219 16.6217C15.1853 18.0583 14.3942 19.9683 14.3942 21.9999C14.3942 22.1138 14.397 22.2272 14.402 22.3403H13.4436C13.0636 22.3403 12.8601 22.7875 13.1099 23.074L15.2718 25.5539L15.7259 26.0747L17.3563 24.2046L18.342 23.074Z"
          fill="white"
        />
        <path
          d="M30.8901 21.6272L29.2776 19.7776L28.2741 18.6265L26.8413 20.2699L25.6581 21.6272C25.4084 21.9136 25.6118 22.3609 25.9918 22.3609H27.1282C26.9423 25.0279 24.7134 27.141 22 27.141C20.8156 27.141 19.7235 26.7381 18.8533 26.0625L17.2488 27.9396C18.5929 29.019 20.2513 29.6057 22.0001 29.6057C24.0316 29.6057 25.9416 28.8146 27.3782 27.378C28.7293 26.0269 29.5093 24.2569 29.5973 22.3609H30.5564C30.9364 22.3609 31.1398 21.9136 30.8901 21.6272Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21097">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppSettingIcon;
