import React, { useState } from 'react';
import WelcomeIcon from 'src/svgComponents/WelcomeIcon';
import UserImagePlaceholder from '../user/UserImagePlaceholder';

const UserAvatar = ({ diameter, imgSrc, alternateSrc, ...other }) => {
  const [imageError, setImageError] = useState(false);

  let Avatar = () =>
    !imageError && imgSrc ? (
      <img src={imgSrc} alt="user avatar" onError={() => setImageError(true)} />
    ) : alternateSrc ? (
      <UserImagePlaceholder
        diameter={diameter}
        firstChar={alternateSrc.charAt(0)}
      />
    ) : (
      <WelcomeIcon />
    );

  return (
    <div
      className="app_user_avatar"
      style={{
        width: `${diameter}px`,
        height: `${diameter}px`,
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Avatar />
    </div>
  );
};

export default UserAvatar;
