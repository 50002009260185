import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import {
  BUSINESS_API_URL,
  USER_API_URL,
} from 'src/api/axios';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { useSnackbar } from 'notistack';
import { authStore } from 'src/contexts/AuthProvider';
import { UserRole } from 'src/utils/enum';
import { withTranslation } from 'react-i18next';

const UnlinkUserPopup = (props) => {
  const { onClose, open, unlinkUserApiObj, unnlikCancleFun } = props;
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const handClose = () => {
    onClose();
  };
  const role = authStore((state) => state.role);
  const { enqueueSnackbar } = useSnackbar();

  const unlinkUser = async () => {
    const unlinkObj = {
      unlinks: unlinkUserApiObj,
    };
    if (role === UserRole.USER) {
      await unlinkFriendRequest(unlinkObj);
    } else if (role === UserRole.BUSINESS) {
      await businessUnlinkFriendRequest(unlinkObj);
    } else {
      enqueueSnackbar(props.t('toast_message.try_again'), {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
      });
    }
  };

  const { mutateAsync: unlinkFriendRequest } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        USER_API_URL.unlinkUser,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar(props.t('toast_message.user_unlinked_success'), {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
        queryClient.invalidateQueries(['userLinkListAll']);
        onClose();
        unnlikCancleFun();
      },
      onError: (error) => {
        enqueueSnackbar(props.t('toast_message.try_again'), {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
      },
    }
  );

  const { mutateAsync: businessUnlinkFriendRequest } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        BUSINESS_API_URL.businessUnlinkUser,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar(props.t('toast_message.user_unlinked_success'), {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
        queryClient.invalidateQueries(['userLinkListAll']);
        onClose();
        unnlikCancleFun();
      },
      onError: (error) => {
        enqueueSnackbar(props.t('toast_message.try_again'), {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
      },
    }
  );

  return (
    <Dialog onClose={handClose} open={open} maxWidth="xs">
      <DialogTitle className="text-center app_text_18_semibold app_text_transform">
        {props.t('popup_title.unlink_user')}
      </DialogTitle>
      <p
        style={{ textAlign: 'center' }}
        className="app_text_16 app_text_gray px-4 app_text_transform"
      >
        {props.t('popup_message.unlink_selected_user')}
      </p>
      <div className="d-flex align-items-center p-3 justify-content-center">
        <Button
          onClick={handClose}
          variant="outlined"
          className="app_bg_primary_light app_btn_lg app_text_16_semibold app_text_transform app_text_primary app_border_primary"
        >
          {props.t('popup_btn.cancel')}
        </Button>
        <Button
          onClick={unlinkUser}
          variant="outlined"
          className="app_bg_primary app_text_16_semibold app_btn_lg text-white app_text_transform ms-2"
        >
          {props.t('popup_btn.unlink')}
        </Button>
      </div>
    </Dialog>
  );
};
UnlinkUserPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default withTranslation()(UnlinkUserPopup);
