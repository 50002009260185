import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import FilterIcon from '@mui/icons-material/Filter';
import { withTranslation } from 'react-i18next';

const MapCategories = (props) => {
  const {
    categoryList,
    selectedMapOption,
    mapCatUpdateFunc,
    userDataLength,
    userEventLength,
    userPostLength,
    mapShowEventfunc,
    mapCloseEventfunc,
  } = props;

  return (
    <Box className="map_category_wrapper">
      <div className="home_map_options_wrapper">
        <Box
          sx={{
            maxWidth: { xs: 400, md: 500, lg: 800 },
            bgcolor: 'transparent',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tabs
            sx={{
              alignItems: 'center',
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
                padding: '8px 0',
              },
              '& .MuiTabs-scroller': { display: 'flex' },
              '& .MuiTabScrollButton-root': {
                bgcolor: '#fff',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ sx: { display: 'none' } }}
          >
            <Tab
              className={`home_map_btn app_text_14_semibold ${
                selectedMapOption === 'all' ? 'mapActive' : ''
              }`}
              onClick={() => mapCatUpdateFunc('all')}
              icon={
                <DoneAllIcon
                  color={selectedMapOption === 'all' ? '#fff' : '#626B76'}
                />
              }
              iconPosition="start"
              label={props.t('home_module.map_cat_all')}
            />
            {/* {userDataLength && ( */}
            <Tab
              className={`home_map_btn app_text_14_semibold ${
                selectedMapOption === 'people' ? 'mapActive' : ''
              }`}
              onClick={() => mapCatUpdateFunc('people')}
              icon={
                <PersonIcon
                  color={selectedMapOption === 'people' ? '#fff' : '#626B76'}
                />
              }
              iconPosition="start"
              label={props.t('home_module.map_cat_people')}
            />
            {/* )} */}
            {/* {userDataLength && ( */}
            <Tab
              className={`home_map_btn app_text_14_semibold ${
                selectedMapOption === 'group' ? 'mapActive' : ''
              }`}
              onClick={() => mapCatUpdateFunc('group')}
              icon={
                <GroupIcon
                  color={selectedMapOption === 'group' ? '#fff' : '#626B76'}
                />
              }
              iconPosition="start"
              label={props.t('home_module.map_cat_group')}
            />
            {/* )} */}
            {categoryList &&
              categoryList.map((cate, index) => {
                return (
                  <Tab
                    key={`${Math.random()
                      .toString(36)
                      .substring(2, 9)}_catBtn${index}`}
                    className={`home_map_btn app_text_14_semibold ${
                      selectedMapOption === cate._id ? 'mapActive' : ''
                    }`}
                    onClick={() => mapCatUpdateFunc(cate._id)}
                    icon={
                      <DomainIcon
                        color={
                          selectedMapOption === cate._id ? '#fff' : '#626B76'
                        }
                      />
                    }
                    iconPosition="start"
                    label={cate.name}
                  />
                );
              })}
            {/* {userEventLength ? */}
            <Tab
              className={`home_map_btn app_text_14_semibold ${
                selectedMapOption === 'event' ? 'mapActive' : ''
              }`}
              onClick={() => mapCatUpdateFunc('event')}
              icon={
                <EventAvailableOutlinedIcon
                  color={selectedMapOption === 'event' ? '#fff' : '#626B76'}
                />
              }
              iconPosition="start"
              label={props.t('home_module.map_cat_events')}
            />
            {/* : <></>} */}
            {/* {userPostLength > 0 ? */}
            <Tab
              className={`home_map_btn app_text_14_semibold ${
                selectedMapOption === 'posts' ? 'mapActive' : ''
              }`}
              onClick={() => mapCatUpdateFunc('posts')}
              icon={
                <FilterIcon
                  color={selectedMapOption === 'posts' ? '#fff' : '#626B76'}
                />
              }
              iconPosition="start"
              label={props.t('home_module.map_cat_posts')}
            />
            {/* : <></>} */}
          </Tabs>
        </Box>
      </div>
    </Box>
  );
};

export default withTranslation()(MapCategories);
