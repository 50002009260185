import React from 'react';

const EventsIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#FF70AD" />
      <g clipPath="url(#clip0_29340_21085)">
        <path
          d="M22.5467 15.4767L23.6532 16.0818L24.2583 17.1883C24.2922 17.2503 24.3572 17.2888 24.4278 17.2888C24.4985 17.2888 24.5635 17.2503 24.5974 17.1883L25.2025 16.0818L26.309 15.4767C26.371 15.4428 26.4095 15.3778 26.4095 15.3071C26.4095 15.2365 26.371 15.1715 26.309 15.1376L25.2025 14.5324L24.5974 13.426C24.5635 13.364 24.4985 13.3254 24.4278 13.3254C24.3572 13.3254 24.2922 13.364 24.2583 13.426L23.6532 14.5324L22.5467 15.1376C22.4847 15.1715 22.4462 15.2365 22.4462 15.3072C22.4462 15.3778 22.4847 15.4428 22.5467 15.4767Z"
          fill="white"
        />
        <path
          d="M21.7324 18.1113C22.0235 18.1113 22.2594 17.8754 22.2594 17.5844C22.2594 17.2933 22.0235 17.0574 21.7324 17.0574C21.4414 17.0574 21.2054 17.2933 21.2054 17.5844C21.2054 17.8754 21.4414 18.1113 21.7324 18.1113Z"
          fill="white"
        />
        <path
          d="M26.8014 23.7523C26.5956 23.9581 26.5956 24.2917 26.8014 24.4975C27.0072 24.7033 27.3409 24.7033 27.5467 24.4975C27.7525 24.2917 27.7525 23.9581 27.5467 23.7523C27.3409 23.5464 27.0072 23.5464 26.8014 23.7523Z"
          fill="white"
        />
        <path
          d="M26.9904 18.3418C27.2814 18.3418 27.5173 18.1059 27.5173 17.8148C27.5173 17.5238 27.2814 17.2878 26.9904 17.2878C26.6993 17.2878 26.4634 17.5238 26.4634 17.8148C26.4634 18.1059 26.6993 18.3418 26.9904 18.3418Z"
          fill="white"
        />
        <path
          d="M18.6058 16.2385C18.6642 16.2593 18.7238 16.2691 18.7823 16.2691C18.9991 16.2691 19.2021 16.1344 19.2789 15.9185L19.8328 14.3607C19.9303 14.0865 19.787 13.7851 19.5128 13.6876C19.2385 13.59 18.9372 13.7333 18.8397 14.0076L18.2859 15.5654C18.1883 15.8396 18.3316 16.141 18.6058 16.2385Z"
          fill="white"
        />
        <path
          d="M30.6017 18.2685C30.3195 18.1975 30.033 18.3685 29.9619 18.6507L29.5971 20.0977C29.3082 19.9225 29.0303 19.7281 28.7673 19.5171C28.5581 19.3493 28.2749 19.31 28.0281 19.4147C27.7818 19.5192 27.6135 19.7497 27.5888 20.0162C27.5508 20.4271 27.521 20.8428 27.4997 21.2574C27.2186 21.1137 26.9393 20.9598 26.6662 20.8093C26.4647 20.6982 26.2563 20.5833 26.0487 20.4733C25.8229 20.3537 25.557 20.3604 25.3373 20.4914C25.1174 20.6225 24.9849 20.8535 24.9828 21.1092C24.9789 21.5844 24.9784 22.0667 24.9815 22.5427C24.9834 22.8326 25.2189 23.0663 25.5084 23.0663H25.5119C25.8029 23.0644 26.0374 22.8269 26.0355 22.5359C26.0336 22.2466 26.033 21.9551 26.0338 21.6642C26.0753 21.687 26.1165 21.7097 26.1575 21.7323C26.5901 21.9707 27.0373 22.2173 27.5019 22.4304C27.7236 22.5321 27.9799 22.5164 28.1874 22.3883C28.3943 22.2606 28.5228 22.0394 28.5313 21.7965C28.544 21.4322 28.5637 21.0652 28.5901 20.6996C28.8727 20.8961 29.1671 21.0761 29.47 21.2377C29.6455 21.3314 29.8429 21.3478 30.0257 21.2839C30.2293 21.2129 30.3959 21.0481 30.4948 20.8199C30.5062 20.7938 30.5154 20.7668 30.5223 20.7391L30.9839 18.9083C31.055 18.6261 30.8839 18.3397 30.6017 18.2685V18.2685Z"
          fill="white"
        />
        <path
          d="M29.4009 16.1835C30.2784 16.1835 30.9897 15.4721 30.9897 14.5947C30.9897 13.7172 30.2784 13.0059 29.4009 13.0059C28.5235 13.0059 27.8121 13.7172 27.8121 14.5947C27.8121 15.4721 28.5235 16.1835 29.4009 16.1835Z"
          fill="white"
        />
        <path
          d="M22.8365 20.1208C22.9 20.1208 22.9646 20.1093 23.0274 20.0849L24.5684 19.4858C24.8397 19.3804 24.9741 19.075 24.8686 18.8037C24.7632 18.5325 24.4578 18.398 24.1865 18.5035L22.6455 19.1025C22.3742 19.208 22.2398 19.5134 22.3453 19.7847C22.4263 19.9932 22.6255 20.1208 22.8365 20.1208Z"
          fill="white"
        />
        <path
          d="M14.6533 26.7913L18.7421 28.6735L21.3622 27.5267L15.5926 24.6528L14.6533 26.7913Z"
          fill="white"
        />
        <path
          d="M16.0282 23.661L22.3451 26.8075L16.9958 21.4583L16.0282 23.661Z"
          fill="white"
        />
        <path
          d="M18.7185 18.3402C18.4899 18.1116 18.1903 17.9973 17.8908 17.9973C17.5912 17.9973 17.2916 18.1116 17.0631 18.3402C16.6059 18.7973 16.6059 19.5384 17.0631 19.9956L24.1064 27.0389C24.335 27.2675 24.6346 27.3818 24.9341 27.3818C25.2337 27.3818 25.5333 27.2675 25.7618 27.0389C26.219 26.5818 26.219 25.8407 25.7618 25.3835L18.7185 18.3402Z"
          fill="white"
        />
        <path
          d="M13.0319 30.4826C12.8985 30.7863 13.2075 31.0958 13.5114 30.9627L17.4158 29.2539L14.2181 27.782L13.0319 30.4826Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21085">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EventsIcon;
