import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { EMPLOYEE_API_URL } from 'src/api/axios';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import UserAvatar from 'src/components/common/UserAvatar';
import Designation from 'src/svgComponents/Designation';
import files from 'src/helpers/helpers';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingScreen from 'src/components/LoadingScreen';

const EmpLeaveRequest = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [loding, setLoding] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState();

  const { data: employeeLeaveData, refetch } = useQuery(
    'employeeReqList',
    async ({ signal }) => {
      return await axiosPrivate
        .get(EMPLOYEE_API_URL.empLeaveRequest, { signal })
        .then((res) => res.data);
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setTimeout(() => {
      setLoding(false);
    }, 2000);
  }, [employeeLeaveData]);

  // eslint-disable-next-line no-unused-vars
  const empLeaveDelete = (data) => {
    setId(data._id);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const completedSchema = Yup.object().shape({
    reason: Yup.mixed().required(props.t('validation.reason_req')),
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: completedSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      const unavaiableReqObj = {
        cancleReason: values.reason,
      };
      await empLeaveReject(unavaiableReqObj);
      setSubmitting(false);
    },
  });

  const { mutateAsync: empLeaveReject } = useMutation(
    async (data) => {
      return await axiosPrivate.post(
        EMPLOYEE_API_URL.empLeaveDelete + id,
        JSON.stringify(data)
      );
    },
    {
      onSuccess: (success) => {
        enqueueSnackbar(success.data.message, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 2000,
        });
        refetch();
        setOpenModal(false);
        resetForm();
      },
    }
  );

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <>
      {loding ? (
        <LoadingScreen />
      ) : (
        <Box
          sx={{ width: '100%', height: '100%' }}
          className="page_content_wrapper"
        >
          <Box
            className="row h-100"
            sx={{
              display: 'flex',
              justifyContent: employeeLeaveData?.length > 0 ? null : 'center',
              alignItems: employeeLeaveData?.length > 0 ? null : 'center',
            }}
          >
            <Box className="col-xl-10 col-12">
              <Box className="row gy-4">
                {
                  !_.isEmpty(employeeLeaveData) ? (
                    employeeLeaveData?.map((leaveData, i) => {
                      return (
                        <>
                          <Box className="col-sm-6 col-12" key={i}>
                            <Box
                              className=""
                              sx={{
                                width: '100%',
                                minHeight: 'auto',
                                backgroundColor: '#fff',
                                borderRadius: '10px',
                                boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.05)',
                                border: '1px solid #E7F0FF',
                                padding: '15px',
                              }}
                            >
                              <Box
                                className=""
                                sx={{
                                  display: 'flex',
                                  alignItems: 'self-start',
                                  gap: '12px',
                                }}
                              >
                                <Box sx={{}}>
                                  <UserAvatar
                                    diameter={40}
                                    alternateSrc={'Lorem ipsum'}
                                    imgSrc={
                                      leaveData?.businessImage
                                        ? files(
                                            leaveData?.businessImage,
                                            'image'
                                          )
                                        : ''
                                    }
                                  />
                                </Box>
                                <Box sx={{ flex: '1' }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Typography
                                      component={'span'}
                                      className="app_text_18_500 app_text_transform"
                                    >
                                      {leaveData.businessName}
                                    </Typography>
                                    {leaveData.requestStatus === 0 ? (
                                      <Chip
                                        className="app_row_btn app_text_14_semibold app_text_transform"
                                        sx={{
                                          minWidth: '110px !important',
                                          background: '#FFCB45',
                                          color: '#fff',
                                          padding: '6px 14px !important',
                                          height: 'unset !important',
                                        }}
                                        label="pending"
                                      />
                                    ) : leaveData.requestStatus === 1 ? (
                                      <Chip
                                        className="app_row_btn app_text_14_semibold app_text_transform"
                                        sx={{
                                          minWidth: '110px !important',
                                          background: '#00CF73',
                                          color: '#fff',
                                          padding: '6px 14px !important',
                                          height: 'unset !important',
                                        }}
                                        label="accepted"
                                      />
                                    ) : leaveData.requestStatus === 2 ? (
                                      <Chip
                                        className="app_row_btn app_text_14_semibold app_text_transform"
                                        sx={{
                                          minWidth: '110px !important',
                                          background: '#dc4a5d',
                                          color: '#fff',
                                          padding: '6px 14px !important',
                                          height: 'unset !important',
                                        }}
                                        label="rejected"
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </Box>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={0.5}
                                    className="app_text_14 "
                                  >
                                    <Designation size={16} />{' '}
                                    <Typography component="span">
                                      {' '}
                                      {leaveData.reason}
                                    </Typography>
                                  </Stack>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Typography
                                      component={'span'}
                                      className="app_text_14_semibold app_text_primary app_text_transform my-2"
                                    >
                                      {moment(leaveData.startDate).format(
                                        'MM-DD-YYYY'
                                      )}{' '}
                                      to{' '}
                                      {moment(leaveData.endDate).format(
                                        'MM-DD-YYYY'
                                      )}
                                    </Typography>
                                    {/* <Button sx={{ maxWidth: "0px" }} onClick={() => empLeaveDelete(leaveData)}>
                                                         { leaveData.requestStatus !== 2 ? <DeleteIcon /> : '' }
                                                      </Button> */}
                                    <Button
                                      className="app_bg_red_light"
                                      sx={{ minWidth: 'unset !important' }}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="red"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_29340_20856)">
                                          <path
                                            d="M14.166 5.00008H18.3327V6.66675H16.666V17.5001C16.666 17.7211 16.5782 17.9331 16.4219 18.0893C16.2657 18.2456 16.0537 18.3334 15.8327 18.3334H4.16602C3.945 18.3334 3.73304 18.2456 3.57676 18.0893C3.42048 17.9331 3.33268 17.7211 3.33268 17.5001V6.66675H1.66602V5.00008H5.83268V2.50008C5.83268 2.27907 5.92048 2.06711 6.07676 1.91083C6.23304 1.75455 6.445 1.66675 6.66602 1.66675H13.3327C13.5537 1.66675 13.7657 1.75455 13.9219 1.91083C14.0782 2.06711 14.166 2.27907 14.166 2.50008V5.00008ZM7.49935 9.16675V14.1667H9.16602V9.16675H7.49935ZM10.8327 9.16675V14.1667H12.4993V9.16675H10.8327ZM7.49935 3.33341V5.00008H12.4993V3.33341H7.49935Z"
                                            fill="#FD4A4C"
                                          ></path>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_29340_20856">
                                            <rect
                                              width="20"
                                              height="20"
                                              fill="white"
                                            ></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })
                  ) : (
                    // <Box sx={{ position: 'absolute', top: '100%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Typography
                      className="app_text_gray text-nowrap app_text_20_bold app_text_transform"
                      sx={{ textAlign: 'center' }}
                    >
                      {props.t('employee_request_module.no_employee_req')}
                    </Typography>
                  )
                  // </Box>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Dialog
        onClose={handleClose}
        open={openModal}
        className="request_popup_wrapper"
      >
        <DialogTitle className="px-0 pt-0 text-center">
          <span className="app_text_20_500 app_text_transform">
            {' '}
            {props.t('popup_title.delete_request')}{' '}
          </span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="row row-cols-1 g-4 mt-1">
              <div className="col">
                <TextField
                  className="app_text_transform"
                  id="reason"
                  label={props.t('text_filed.reject_request_reason')}
                  varient="outlined"
                  {...getFieldProps('reason')}
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                  fullWidth
                />
              </div>
              <div className="col">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="my-3 app_text_transform text-white app_bg_primary app_text_16_semibold app_btn_lg"
                >
                  {props.t('btn_text.submit')}
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
};

export default withTranslation()(EmpLeaveRequest);
