import React from 'react';

const RequestsIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#404EFF" />
      <g clipPath="url(#clip0_31368_24645)">
        <path
          d="M19.9998 21.5C22.1998 21.5 23.9998 19.7 23.9998 17.5C23.9998 15.3 22.1998 13.5 19.9998 13.5C17.7998 13.5 15.9998 15.3 15.9998 17.5C15.9998 19.7 17.7998 21.5 19.9998 21.5ZM31.6998 19.1C31.2998 18.7 30.6998 18.7 30.2998 19.1L28.2998 21.1L27.6998 20.5C27.2998 20.1 26.6998 20.1 26.2998 20.5C25.8998 20.9 25.8998 21.5 26.2998 21.9L27.5998 23.2C27.9998 23.6 28.5998 23.6 28.9998 23.2L31.6998 20.5C32.0998 20.1 32.0998 19.5 31.6998 19.1ZM19.9998 23C16.2998 23 13.0998 25.7 12.5998 29.4C12.4998 29.9 12.8998 30.5 13.4998 30.5H13.5998H26.3998C26.9998 30.5 27.3998 30.1 27.3998 29.5V29.4C26.8998 25.7 23.6998 23 19.9998 23Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31368_24645">
          <rect
            width="20"
            height="18"
            fill="white"
            transform="translate(12 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RequestsIcon;
