import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

const EmpLeaveReqFilterPopup = (props) => {
  const { onClose, open, empData, filters, reset } = props;
  const [requestDate, setRequestDate] = useState(null);
  const [leaveStatus, setLeaveStatus] = useState(4);
  const [emp, setEmp] = useState();
  const [branch, setBranch] = useState();
  const queryClient = useQueryClient();

  const uniqueEmpData = [];

  empData?.map((obj) => {
    const index = uniqueEmpData.findIndex(
      (item) => item.businessBranchId === obj.businessBranchId
    );
    if (index === -1) {
      uniqueEmpData.push(obj);
    }
  });

  const onChangeDate = (newValue) => {
    let newDate = new Date(newValue);
    const date = moment(newDate).format('YYYY-MM-DD');
    setRequestDate(date);
  };
  const onChangeLeaveStatus = (e) => {
    setLeaveStatus(e.target.value);
  };

  const onChangeEmployee = (e) => {
    setEmp(e.target.value);
  };
  const onChangeBranch = (e) => {
    setBranch(e.target.value);
  };
  const applay = () => {
    const filterObj = {
      requestDate: requestDate,
      leaveStatus: leaveStatus || '',
      emp: emp || '',
      branch: branch || '',
    };
    filters(filterObj);
    onClose();
  };
  // const reset = () => {
  //     queryClient.invalidateQueries(["empAvailability"]);
  //     setRequestDate(null)
  //     setLeaveStatus()
  //     setBranch()
  //     setEmp()
  //     filters({})
  //     onClose()
  // }

  return (
    <Dialog onClose={onClose} open={open} className="map_filter_box_popup">
      <DialogTitle
        sx={{
          px: 2,
          py: 3,
          boxShadow:
            '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
        }}
        className="d-flex align-items-center w-100"
      >
        <Button onClick={onClose} className="app_null_btn">
          <ArrowBackIcon style={{ color: '#2d3748' }} />
        </Button>
        <span className="app_text_18_semibold app_text_black w-100 ms-4 app_text_transform">
          {props.t('popup_title.filters')}
        </span>
        <div className="d-flex align-items-center">
          <Button
            className="theme_button app_text_transform"
            onClick={applay}
            variant="contained"
          >
            {props.t('popup_btn.apply')}
          </Button>
          <Button
            className="theme_button ms-3 app_text_transform"
            onClick={reset}
            variant="outlined"
          >
            {props.t('popup_btn.reset')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <Box sx={{ px: 2, pt: 2 }}>
          <Box className="pb-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl sx={{ width: '100%' }}>
                <DatePicker
                  label={props.t('text_filed.dob_text_label')}
                  value={requestDate}
                  // maxDate={dayjs(moment().format("YYYY-MM-DD"))}
                  onChange={(newValue) => {
                    onChangeDate(newValue);
                    // setRequestDate(newValue);
                  }}
                  // disabled={Boolean(parentObject?.isVerified)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </LocalizationProvider>
          </Box>
          <Box className="py-3">
            <FormControl fullWidth>
              <InputLabel id="leave status">select leave status</InputLabel>
              <Select
                sx={{ height: 53 }}
                labelId="leave status"
                id="demo-simple-select"
                label="leave status"
                value={leaveStatus}
                onChange={(e) => onChangeLeaveStatus(e)}
                // onChange={selectBuisnessScheduleType}
              >
                <MenuItem value={0}>pending</MenuItem>
                <MenuItem value={1}>accepted</MenuItem>
                <MenuItem value={2}>rejected</MenuItem>
                <MenuItem value={3}>cancled</MenuItem>
                <MenuItem value={4}>all</MenuItem>
              </Select>
              {/* <FormHelperText error className="app_text_transform">
                              {touched.business_schedule && errors.business_schedule}
                            </FormHelperText> */}
            </FormControl>
          </Box>

          <Box className="py-3">
            <FormControl fullWidth>
              <InputLabel id="employee">select employee</InputLabel>
              <Select
                sx={{ height: 53 }}
                labelId="employee"
                id="demo-simple-select"
                label="employee"
                value={emp}
                // onChange={onChangeBuisnessType}
                onChange={(e) => onChangeEmployee(e)}
              >
                {empData?.map((emp) => (
                  <MenuItem value={emp?.employeeId}>
                    {emp?.employeeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="pt-3">
            <FormControl fullWidth>
              <InputLabel id="business loc">
                select business location
              </InputLabel>
              <Select
                sx={{ height: 53 }}
                labelId="business loc"
                id="demo-simple-select"
                label="select business location"
                value={branch}
                // onChange={onChangeBuisnessType}
                onChange={(e) => onChangeBranch(e)}
              >
                {uniqueEmpData?.map((emp) => (
                  <MenuItem value={emp?.businessBranchId}>
                    {emp?.businessName + ` - ` + emp?.businessBranchName}
                  </MenuItem>
                ))}
              </Select>
              {/* <FormHelperText error className="app_text_transform">
                              {touched.business_schedule && errors.business_schedule}
                            </FormHelperText> */}
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(EmpLeaveReqFilterPopup);
