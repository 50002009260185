import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  Stack,
} from '@mui/material';
import React from 'react';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import UserAvatarWithNameIdTrust from '../common/UserAvatarWithNameIdTrust';
import LocationPinCircleBase from 'src/svgComponents/LocationPinCircleBase';
import EditPencilCircle from 'src/svgComponents/EditPencilCircle';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ChatFilled from 'src/svgComponents/ChatFilled';
import BeemzLocationIcon from 'src/svgComponents/BeemzLocationIcon';
import AppTooltip from '../common/AppTooltip';
import files, { getDistanceInKm } from 'src/helpers/helpers';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { useMutation, useQueryClient } from 'react-query';
import { USER_API_URL } from 'src/api/axios';
import { useNavigate } from 'react-router';
import { PATH_CHAT, PATH_USER } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

const MapSearchUserCard = (props) => {
  const {
    fullName,
    userName,
    trusting,
    onClose,
    loginUserPermission,
    location,
    profilePic,
    status,
    userId,
    contact,
    distance,
    userStatus,
    userPermissionsVisibility,
    userPermissionsLocation,
    userPermissionsChat,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const direction = () => {
    if (loginUserPermission?.location?.notShared) {
      enqueueSnackbar(props.t('toast_message.give_location_permission'), {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 2000,
      });
    } else {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${location?.coordinates[1]},${location?.coordinates[0]}`
      );
    }
  };

  const distanceKm = getDistanceInKm(
    -26.204444,
    28.045556,
    location?.coordinates[1] ?? 0.0,
    location?.coordinates[0] ?? 0.0
  );

  const navigate = useNavigate();

  const FriendButton = ({ userId, status }) => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    const { mutateAsync: friendRequest } = useMutation(
      async (data) => {
        return await axiosPrivate.post(USER_API_URL.friendRequest + data);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['userGlobalSerchData']);
        },
      }
    );

    const addFriend = async (data) => {
      await friendRequest(data);
    };

    switch (status) {
      case 2:
      case 1:
        return (
          <AppTooltip
            title={props.t('tooltip_text.pending_request')}
            placement={'bottom'}
          >
            <Button
              variant="contained"
              className="map_sidebar_card_btn user_map"
            >
              <WatchLaterIcon style={{ color: '#4a4c4e', fontSize: '18px' }} />
            </Button>
          </AppTooltip>
        );

      case 4:
      case 0:
        return (
          <AppTooltip title={props.t('tooltip_text.link')} placement={'bottom'}>
            <Button
              variant="contained"
              className="map_sidebar_card_btn user_map"
              onClick={() => addFriend(userId)}
            >
              <PersonAddAltRoundedIcon
                style={{ color: '#4a4c4e', fontSize: '18px' }}
              />
            </Button>
          </AppTooltip>
        );

      case 3:
        return (
          <AppTooltip
            title={props.t('tooltip_text.friend')}
            placement={'bottom'}
          >
            <Button
              variant="contained"
              className="map_sidebar_card_btn user_map"
            >
              <HowToRegIcon style={{ color: '#4a4c4e', fontSize: '18px' }} />
            </Button>
          </AppTooltip>
        );
      default:
        return '';
    }
  };

  const chatClick = (userId) => {
    navigate(`${PATH_CHAT.root}/${userId}`);
    onClose();
  };

  return (
    <ListItem className="map_serchUser_listItem mt-3">
      <Card className="app_card">
        <CardContent className="pb-2">
          <UserAvatarWithNameIdTrust
            imgSrc={
              userPermissionsVisibility?.picture && profilePic
                ? files(profilePic, 'image')
                : ''
            }
            avatarSize={60}
            userFullName={fullName}
            userId={userName}
            trustRating={trusting}
          />
          <List className="mt-3" dense>
            <ListItem className="px-0">
              <ListItemIcon className="list_item_icon_modify me-2">
                <LocationPinCircleBase w={16} h={16} />
              </ListItemIcon>
              <span className="app_text_12_fw500 app_text_gray">{`${distanceKm} ${props.t(
                'my_contacts_module.km_text_label'
              )}`}</span>
            </ListItem>
            {userPermissionsVisibility.status && userStatus && (
              <ListItem className="px-0">
                <ListItemIcon className="list_item_icon_modify me-2">
                  <EditPencilCircle w={14} h={14} />
                </ListItemIcon>
                <span className="app_text_12_fw500 app_text_gray">
                  {userStatus}
                </span>
              </ListItem>
            )}
          </List>
        </CardContent>
        <CardActions className="pt-1 pb-3 px-3">
          <Stack direction={'row'} spacing={2}>
            <FriendButton userId={userId} status={status} />
            {userPermissionsChat?.public ||
            (userPermissionsChat?.contact && contact) ? (
              <AppTooltip
                title={props.t('tooltip_text.chat')}
                placement={'bottom'}
              >
                <Button
                  variant="contained"
                  className="map_sidebar_card_btn user_map"
                  onClick={() => chatClick(userId)}
                >
                  <ChatFilled color={'#4a4c4e'} size={18} />
                </Button>
              </AppTooltip>
            ) : (
              <></>
            )}
            {(userPermissionsLocation?.withPublic ||
              (userPermissionsLocation?.withLinkedContact && status === 3)) &&
            !userPermissionsLocation?.whileUsingApp &&
            !userPermissionsLocation?.notShared ? (
              <AppTooltip
                title={props.t('tooltip_text.user_location')}
                placement={'bottom'}
              >
                <Button
                  variant="contained"
                  className="map_sidebar_card_btn user_map"
                  onClick={direction}
                >
                  <BeemzLocationIcon color={'#4a4c4e'} size={18} />
                </Button>
              </AppTooltip>
            ) : (
              <></>
            )}
          </Stack>
        </CardActions>
      </Card>
    </ListItem>
  );
};

export default withTranslation()(MapSearchUserCard);
