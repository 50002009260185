import React from 'react';

const Permissions = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#FF5E4B" />
      <g clipPath="url(#clip0_29340_21079)">
        <path
          d="M18.25 18.25V15.25C18.25 15.0511 18.329 14.8603 18.4697 14.7197C18.6103 14.579 18.8011 14.5 19 14.5H28.75C28.9489 14.5 29.1397 14.579 29.2803 14.7197C29.421 14.8603 29.5 15.0511 29.5 15.25V25C29.5 25.1989 29.421 25.3897 29.2803 25.5303C29.1397 25.671 28.9489 25.75 28.75 25.75H25.75V28.7448C25.75 29.1618 25.4133 29.5 24.9948 29.5H15.2553C15.156 29.5001 15.0578 29.4806 14.9661 29.4427C14.8744 29.4048 14.7911 29.3492 14.721 29.279C14.6508 29.2089 14.5952 29.1256 14.5573 29.0339C14.5194 28.9422 14.4999 28.844 14.5 28.7448L14.5023 19.0052C14.5023 18.5882 14.839 18.25 15.2575 18.25H18.25ZM19.75 18.25H24.9948C25.4118 18.25 25.75 18.5867 25.75 19.0052V24.25H28V16H19.75V18.25ZM19.3773 26.5L23.6193 22.2573L22.5588 21.1967L19.3773 24.379L17.785 22.7875L16.7245 23.848L19.3773 26.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21079">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Permissions;
