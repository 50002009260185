import React from 'react';

const ChatIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="15" fill="#00CF73" />
      <g clipPath="url(#clip0_29340_21081)">
        <path
          d="M26.5 13.75H17.5C16.5058 13.7512 15.5527 14.1467 14.8497 14.8497C14.1467 15.5527 13.7512 16.5058 13.75 17.5V23.5C13.7511 24.3642 14.0501 25.2015 14.5965 25.8709C15.143 26.5404 15.9036 27.0009 16.75 27.175V29.5C16.75 29.6358 16.7868 29.769 16.8566 29.8855C16.9264 30.002 17.0265 30.0973 17.1462 30.1614C17.2659 30.2255 17.4008 30.2558 17.5364 30.2492C17.672 30.2426 17.8033 30.1994 17.9162 30.124L22.225 27.25H26.5C27.4942 27.2488 28.4473 26.8533 29.1503 26.1503C29.8533 25.4473 30.2488 24.4942 30.25 23.5V17.5C30.2488 16.5058 29.8533 15.5527 29.1503 14.8497C28.4473 14.1467 27.4942 13.7512 26.5 13.75ZM25 22.75H19C18.8011 22.75 18.6103 22.671 18.4697 22.5303C18.329 22.3897 18.25 22.1989 18.25 22C18.25 21.8011 18.329 21.6103 18.4697 21.4697C18.6103 21.329 18.8011 21.25 19 21.25H25C25.1989 21.25 25.3897 21.329 25.5303 21.4697C25.671 21.6103 25.75 21.8011 25.75 22C25.75 22.1989 25.671 22.3897 25.5303 22.5303C25.3897 22.671 25.1989 22.75 25 22.75ZM26.5 19.75H17.5C17.3011 19.75 17.1103 19.671 16.9697 19.5303C16.829 19.3897 16.75 19.1989 16.75 19C16.75 18.8011 16.829 18.6103 16.9697 18.4697C17.1103 18.329 17.3011 18.25 17.5 18.25H26.5C26.6989 18.25 26.8897 18.329 27.0303 18.4697C27.171 18.6103 27.25 18.8011 27.25 19C27.25 19.1989 27.171 19.3897 27.0303 19.5303C26.8897 19.671 26.6989 19.75 26.5 19.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29340_21081">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatIcon;
