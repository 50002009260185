import React, { useContext } from 'react';
import useStore from 'src/contexts/AuthProvider';
import { SocketContext } from 'src/contexts/socketProvide';
import { UserActivityMonitor } from './UserActivityMonitor';

const UserActivitySocketManager = () => {
  const { isAuthenticated } = useStore();
  const { setUserInactiveStatus } = useContext(SocketContext);

  if (!isAuthenticated) return null;

  return (
    <UserActivityMonitor
      inactivityDuration={60 * 1000} // 1 Minute
      onInactive={() => setUserInactiveStatus(true)}
      onActive={() => setUserInactiveStatus(false)}
    />
  );
};

export default UserActivitySocketManager;
