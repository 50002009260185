import { SnackbarProvider } from 'notistack';
import ChatSnackbar from 'src/components/common/ChatSnackbar';

const Provider = ({ children }) => {
  return (
    <SnackbarProvider
      Components={{
        chatSnackbar: ChatSnackbar,
      }}
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Provider;
