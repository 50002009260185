// Function to scroll an element to its bottom
export const scrollToBottom = (element) => {
  if (!element) return;

  element.scrollTop = element.scrollHeight;
};

// Utility function to check if value is JSON formatted or not
export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// Utility function to convert any string(passed 'text') into the length of passed length 'fixedLength' and make 3 dots in the middle of the string
export const dottedText = (text, fixedLength) => {
  const maxLength = fixedLength - 3; // 3 dots take up space

  if (!text) return;
  if (text.length <= fixedLength) {
    return <span>{text}</span>;
  }

  const startLength = Math.floor(maxLength / 2);
  const endLength = maxLength - startLength;

  const startText = text.slice(0, startLength);
  const endText = text.slice(-endLength);

  return `${startText}...${endText}`;
};
